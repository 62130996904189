  // AppCompetitorsSearchBar.js
  import React from 'react';
  import { useSelector, useDispatch } from 'react-redux'
    import {useState} from 'react';
  import AppCompetitorsSearchSuggestionList from './AppCompetitorsSearchSuggestionList'
  import {
    fetchSearchSuggestions //// search thunk,
    } from '../../../../store'
  
  
  
  const AppCompetitorsSearchBar = ({handleSuggestionClicked, currentCompetitors}) => {
    //to keep track of local state of suggestion list
     const [isSuggestionListOpen, setSuggestionListOpen] = useState(false);
     const [searchSuggestions, setSearchSuggestions] = useState([])
     const [searchTerm, setSearchTerm] = useState("")
    const dispatch = useDispatch();
  
  
     
    const handleSearchTermChange = async (event) => { 
      const newTerm = event.target.value;
        setSuggestionListOpen(true);  
        setSearchTerm(newTerm)
         // Dispatch the thunk and get the result
        const result = await dispatch(fetchSearchSuggestions(newTerm));
        //console.log(result.payload.data)
       
       
        //From PROD API
        if (result && result.payload) {
            
            const { searchWebAppsAdmin, searchiOSAppsAdmin} = result.payload.data;
            const combinedArray = [...searchWebAppsAdmin, ...searchiOSAppsAdmin];
             //console.log(combinedArray)

            // Extract IDs of categories already present in currentCompetitors
            const currentCompetitorsIds = new Set(currentCompetitors.map(cat => cat.id));

            // Filter out categories that are already in currentCompetitors
            const filteredSuggestions = combinedArray.filter(cat => !currentCompetitorsIds.has(cat.id));
            
            // Set the search suggestions
            setSearchSuggestions(filteredSuggestions);
        }
  
       
      };
  
      const handleBlur = (event) => {
      //console.log("blur")
      setSuggestionListOpen(false);
      //console.log(isSuggestionListOpen)
    };
  
    const handleOnClick = ()=> {
        console.log("handleonClick")
    }
  
  
    return (
    <div>     
      <div id="search" className="ui search search-bar-container">
           <div className="ui icon input">
             <input className=" prompt search-bar" 
             type="text" 
             style = {{color: "black"}} 
             placeholder="Search Competitors..."  
             value={searchTerm}
             //value={event.target.value}
             onChange={handleSearchTermChange}
             onBlur={handleBlur}
             />
                <i className="search icon"></i>
            
          </div>
         
           {isSuggestionListOpen  && searchSuggestions.length > 0 ? <AppCompetitorsSearchSuggestionList handleSuggestionClicked={handleSuggestionClicked} setSearchTerm={setSearchTerm} suggestions = {searchSuggestions}/>: null}
     
        </div> 
     </div>  
    );
  };
  
  
  export default AppCompetitorsSearchBar
  
  
  
  
  
  
  
  
  