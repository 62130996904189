// SimpleLoader.js
import React from 'react';






const SimpleLoader = () => {
  return (
  //asdas
   <div className="ui active  loader">
    <div className="ui loader"></div>
  </div> 
  );
};


export default SimpleLoader







