// AppDescriptionHTMLTextAreaField.js
import React from 'react';
import { useState, useEffect } from 'react';
import { updateiOSAppByID } from '../../../store'
import { useSelector, useDispatch } from 'react-redux';

const AppDescriptionHTMLTextAreaField = ({value, label, appType, appId, gql}) => {


const [text, setText] = useState(value);
  const [isEditing, setIsEditing] = useState(false);
  const [isTry, setIsTry] = useState(false);
  // State to keep track of original data
  const [originalData, setOriginalData] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    setText(value)
      setOriginalData(value); // Save the original data for comparison
  },[]);



  const handleSave = (e) => {
    e.preventDefault()

     const iOsApp = {
      description: text
    };
    dispatch(updateiOSAppByID({ appId, iOsApp }))

    //console.log(name)
    setText(name);
    setIsEditing(false); // Exit editing mode
  };

  const handleCancel = (e) => {
    e.preventDefault()
    setText(value); // Reset the value to the original
    setIsEditing(false); // Exit editing mode
    setIsTry(false);
  };

   const toggleTry = () => {
    setIsTry(!isTry); // Toggle the isTry state
  };
  
// Function to check if there are any changes
const hasChanges = () => {
  return (
    text !== originalData
  );
};


if (isEditing) {

    if(isTry){
      return (<div>
          <h3>
          {label} 
          <button type="button" className="ui right floated button" onClick={handleCancel}>Cancel</button>
            <button type="button" disabled={!hasChanges()} className="ui right floated button" onClick={handleSave}>Save</button>
          </h3>
          <p dangerouslySetInnerHTML={{ __html: text }} />
           <div className="ui toggle checkbox">
                <input type="checkbox"
                checked={isTry}
                 onChange={toggleTry} />
                <label>Test in HTML</label>
              </div>
      </div>)
    }

    return (
      <div className="ui form">
        <div className="field">
          <h3>
            {label} 
            <button type="button" className="ui right floated button" onClick={handleCancel}>Cancel</button>
            <button type="button" disabled={!hasChanges()} className="ui right floated button" onClick={handleSave}>Save</button>
          </h3>
          <textarea
                rows="4" 
                value={text}
                onChange={(e) => setText(e.target.value)}
              />
              <div className="ui toggle checkbox">
                <input type="checkbox"
                checked={isTry}
                 onChange={toggleTry} />
                <label>Test in HTML</label>
              </div>
        </div>
      </div>
    );
  }  
    return (
      <div>
          <h3>
           {label} 
           <button  type="button" className="ui right floated button" onClick={() => setIsEditing(true)}>Edit</button>
          </h3>
          <p dangerouslySetInnerHTML={{ __html: value }} />
      </div>
    );

};


export default AppDescriptionHTMLTextAreaField





