  //UserflowFilterSlice.js

import { createSlice } from '@reduxjs/toolkit'
import {fetchAllUserFlowNamesByAppId } from '../thunks/userFlowThunk'
import { createSelector } from 'reselect';


const UserflowFilterSlice = createSlice({
  name: 'userflowfilter',
  initialState: {
    appId: '',
    searchTerm: '',
    isLoading: false,
    userFlows: [], // This will be populated from the API
    filteredUserFlows: [], // This will hold the filtered set based on the searchTerm
    filters: {}, // Object to track the state of each filter
    isAnyFlowChecked: false // Boolean attribute
    
  },
  reducers: {
    changeSearchTerm: (state, action) => {
      state.searchTerm = action.payload
      if (action.payload.trim() === '') {
          // If the search term is empty, show all userFlows
          state.filteredUserFlows = state.userFlows;
      } else {
        // Otherwise, filter the userFlows based on the search term
        state.filteredUserFlows = state.userFlows.filter(flow =>
          flow.name.toLowerCase().includes(action.payload.toLowerCase())
        );
      }
    },
   toggleFilter: (state, action) => {
        //console.log("toggleFilter", action.payload)
        const userFlowId = action.payload;
        state.filters[userFlowId] = !state.filters[userFlowId];

        // Update isAnyFlowChecked
        state.isAnyFlowChecked = Object.values(state.filters).some(isChecked => isChecked);
    },
   uncheckAllFilters: (state) => {
     state.userFlows.forEach(userFlow => {
                state.filters[userFlow.id] = false;
            });
      //state.filters = {} //simpler version
      state.isAnyFlowChecked = false;

   }, 

  },
  extraReducers(builder){
    builder.addCase(fetchAllUserFlowNamesByAppId.fulfilled, (state, action) => {
        //console.log(action)
        //console.log(action.meta)
       state.userFlows = action.payload.data.userFlowByAppId.userflow
       state.filteredUserFlows = action.payload.data.userFlowByAppId.userflow
       state.appId = action.meta.arg
       state.filters = {}
       state.isAnyFlowChecked = false
       state.isLoading = false; // Set isLoading to false once the data is fetched
    });
    builder.addCase(fetchAllUserFlowNamesByAppId.pending, (state) => {
              state.isLoading = true;
    });
    
  }
})

// Selector to count the number of checked userflows
export const selectCheckedCount = (state) => {
    return Object.values(state.userflowsfilter.filters).filter(isChecked => isChecked).length;
};

// Selector to filter checked userflows
// export const selectFilteredUserFlows = (state) => {
//     const { userFlows, filters } = state.userflowsfilter;
//     return userFlows.filter(userFlow => filters[userFlow.id]);
// };

// Base selectors
const getUserFlows = state => state.userflowsfilter.userFlows;
const getFilters = state => state.userflowsfilter.filters;

// Memoized selector to filter checked userflows
export const selectFilteredUserFlows = createSelector(
  [getUserFlows, getFilters],
  (userFlows, filters) => userFlows.filter(userFlow => filters[userFlow.id])
);



// Action creators are generated for each case reducer function
export const { toggleFilter, uncheckAllFilters, changeSearchTerm } = UserflowFilterSlice.actions

export const userflowfilterReducer = UserflowFilterSlice.reducer;