// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const searchApi = createApi({
	reducerPath: 'searchApi',
	baseQuery: fetchBaseQuery({ baseUrl: 'http://localhost:4000/'}),
	endpoints: (builder) => ({
		searchSuggestions: builder.query({
			query: (name) => {
				return {
					url: "/",
					method: 'POST',
					body:{
						query: `{
							greeting (name: "${name}")
						}`
					}
				}
			},
		}),
	}),
	
});


export const { useSearchSuggestionsQuery } = searchApi;
export { searchApi}