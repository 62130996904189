import { createAsyncThunk } from '@reduxjs/toolkit';
const KEYS = require('../../config/keys')

const fetchGreetingFromGraphQL = createAsyncThunk('greetingFromGraphQL/fetch', async(term) =>{
	return fetch(KEYS.GRAPHQL_URL, {
	//const response = fetch('http://localhost:4000/', {
    method: 'POST',
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      //query: query,
      //variables: variables
    	query: `{
							greeting (name: "${term}")
						}`	
    })
  }).then(res => res.json())
//console.log(response)
})

const fetchSearchSuggestions = createAsyncThunk('searchSuggestions/fetch', async(term) =>{
  //console.log("process.env.GRAPHQL_URL", KEYS.GRAPHQL_URL)
	return fetch(KEYS.GRAPHQL_URL, {
	//const response = fetch('http://localhost:4000/', {
    method: 'POST',
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      //query: query,
      //variables: variables
    	query: `{
							searchWebAppsAdmin(searchTerm:"${term}"){
								name
                id
                __typename
                href
							}

              searchiOSAppsAdmin(searchTerm: "${term}"){
                name
                id
              __typename
              href
              }
  
						}`	
    })
  }).then(res => res.json())
console.log("response")
  
})




export {fetchSearchSuggestions, fetchGreetingFromGraphQL}