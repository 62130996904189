// AppNameField.js
import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateiOSAppByID } from '../../../store'


const AppNameField = ({value, appType, appId}) => {

  const [name, setName] = useState(value);
  const [isEditing, setIsEditing] = useState(false);
  const [originalData, setOriginalData] = useState(null);
  const dispatch = useDispatch();

   useEffect(() => {
      setName(value)
      setOriginalData(value); // Save the original data for comparison
  }, []);

  const handleSave = (e) => {
    e.preventDefault()

    const iOsApp = {
      name: name
    };
    dispatch(updateiOSAppByID({ appId, iOsApp }))
   // console.log(name)
    setName(name);
    setIsEditing(false); // Exit editing mode
  };

  const handleCancel = () => {
    setName(value); // Reset the value to the original
    setIsEditing(false); // Exit editing mode
  };

  // Function to check if there are any changes
  const hasChanges = () => {
    return (
      name !== originalData
    );
  };

if (isEditing) {
    return (
      <div className="ui form">
        <div className="field">
          <h3>
            Name
            <button className="ui right floated button" onClick={handleCancel}>Cancel</button>
            <button disabled={!hasChanges()}  className="ui right floated button" onClick={handleSave}>Save</button>
          </h3>
          <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
        </div>
        
      </div>
    );
  }


  return (
   <div>
          <h3>
            Name
           <button className="ui right floated button" onClick={() => setIsEditing(true)}>Edit</button>
          </h3>
          <p>
            {value}
          </p>
  </div>
  );
};


export default AppNameField







