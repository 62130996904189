// InlineLoader.js
import React from 'react';






const InlineLoader = () => {
  return (
  //asdas
    <div className="ui active inline loader"></div> 
  );
};


export default InlineLoader







