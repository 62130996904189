//MissionTextComponent.js

import React from 'react';

const MissionTextComponent = ({ data }) => {
    //console.log("MissionTextComponent prod", data)
  return (
    <div >
      <p dangerouslySetInnerHTML={{ __html: data }} />
    </div>
  );
}

export default MissionTextComponent;