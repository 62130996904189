    // ProductInsightHorizontalSection.js
import React from 'react';
import {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom';

import { fetchProductInsightForWebApp , fetchWebCompetitorsByAppId} from '../../../store'

import ProductTextComponent from '../../ProductInsightSubComponents/ProductTextComponent';
import UserSegmentTextComponent from '../../ProductInsightSubComponents/UserSegmentTextComponent';
import ValueTextComponent from '../../ProductInsightSubComponents/ValueTextComponent';
import MonetizationTextComponent from '../../ProductInsightSubComponents/MonetizationTextComponent';
import CompetitorTextComponent from '../../ProductInsightSubComponents/CompetitorTextComponent';
import MissionTextComponent from '../../ProductInsightSubComponents/MissionTextComponent';
import CompetitorsListComponent from '../../ProductInsightSubComponents/CompetitorsListComponent';
 

const ProductInsightHorizontalSection = (prop) => {

var webapp = prop.webApp 
const dispatch = useDispatch();

const params = useParams();
const idfromURL = params.id
    //console.log("FlowsGridWebApp idfromURL", idfromURL)

const [getActive, setActive] = useState("productText");



 useEffect(()=> {
    //console.log("useEffect")   

    dispatch(fetchProductInsightForWebApp(idfromURL))
    dispatch(fetchWebCompetitorsByAppId(idfromURL))
 
    },
    [idfromURL]); //this helps reload the page component when url changes from /web/apps/2/flows to /web/apps/1/flows
 //note: you still need to above if condition

const prodIn = useSelector((state) => {
    //console.log(state.webapps.selectedWebApp)
        return state.webapps.productInsight
    })  
//console.log(" prodIn", prodIn)

var prod = prodIn


 const handleOnClick = (r) => {   
    const id = r.target.id;
       //console.log("ASDASD",id);
        setActive(id)   
    };

  //console.log(prod) 
  //console.log(webapp) 

   // Configuration mapping active selection to component and data
   const config = {
    productText: {
        component: ProductTextComponent,
        data: () => prod[getActive]
    },
    userSegmentText: {
        component: UserSegmentTextComponent,
        data: () => prod[getActive]
    },
    valueText: {
        component: ValueTextComponent,
        data: () => prod[getActive]
    },
    monetizationText: {
        component: MonetizationTextComponent,
        data: () => prod[getActive]
    },
    competitorText: {
        component: CompetitorTextComponent,
        data: () => prod[getActive]
    },
    missionText: {
        component: MissionTextComponent,
        data: () => prod[getActive]
    },
    competitors: {
        component: CompetitorsListComponent,
        data: () => prod[getActive]
    },

    // Add more configurations as needed
};

// Determine which component to render and get its data
const activeConfig = config[getActive];
const ActiveComponent = activeConfig ? activeConfig.component : null;
const activeData = activeConfig ? activeConfig.data() : null;

  
  return (
            <div className="ui product-insight-container">   
            <div className="product-insight-horizontal-scrollable-list-container"> 
                <div className="ui link horizontal list">

                    {
                        prod.productText === '' ? null : 
                         <a 
                        className={getActive === "productText" ? "active item" : " item" }
                          onClick={handleOnClick}
                    >
                        <h2 
                        id="productText"
                        className={getActive === "productText" ? "underlined" : null }    
                        >Product</h2>
                     </a>


                    }

                      {
                    
                        prod.userSegmentText === '' ? null : 

                        <a
                            className={getActive === "userSegmentText" ? "active item" : "item" }
                            onClick={handleOnClick}
                        >
                            <h2 
                                 className={getActive === "userSegmentText" ? "underlined" : null }
                                    id="userSegmentText">
                                Users
                            </h2>
                        </a>

                        
                    }
                  

                    {
                        prod.valueText === '' ? null : 

                        <a
                            className={getActive === "valueText" ? "active item" : "item" }
                            onClick={handleOnClick}
                        >
                            <h2 
                                className={getActive === "valueText" ? "underlined" : null }
                                id="valueText">
                                    Value
                            </h2>
                        </a>

                    }
                     

                    {
                        prod.monetizationText === '' ? null : 
                        
                       <a
                     className={getActive === "monetizationText" ? "active item" : "item" }
                     onClick={handleOnClick}
                     >
                        <h2 
                        className={getActive === "monetizationText" ? "underlined" : null }
                        id="monetizationText">
                            Monetization
                        </h2>
                     </a>



                    } 
                    
                     

                    {
                         prod.competitorText === '' ? null : 

                         <a
                             className={getActive === "competitorText" ? "active item" : "item" }
                             onClick={handleOnClick}
                             >
                                <h2 
                                className={getActive === "competitorText" ? "underlined" : null }
                                id="competitorText">
                                    Competition
                                </h2>
                             </a>

                     
                     }

                    {
                        !prod.competitors || prod.competitors.length === 0 ? null :
                            
                            <a
                                className={getActive === "competitors" ? "active item" : "item"}
                                onClick={handleOnClick}
                            >
                                <h2
                                    className={getActive === "competitors" ? "underlined" : null}
                                    id="competitors">
                                    Competitors
                                </h2>
                            </a>
                    }


                     {
                         prod.missionText === '' ? null : 

                         <a
                             className={getActive === "missionText" ? "active item" : "item" }
                             onClick={handleOnClick}
                             >
                                <h2 
                                className={getActive === "missionText" ? "underlined" : null }
                                id="missionText">
                                    Mission
                                </h2>
                             </a>

                     
                     }
                             
                </div>
            </div> 
             <div>
                <p></p>
                {ActiveComponent ? <ActiveComponent data={activeData} /> : null}
            </div>

    </div >    

  );
};


export default ProductInsightHorizontalSection




