// TopMenuForAdmin.js
import React from 'react';
import { NavLink } from 'react-router-dom';
//NavLink is similar to the Link component 
//but it allows you to add styling attributes to 
//the rendered element when it matches the current URL



const TopMenuForAdmin = () => {

   // Function to determine the class name based on the active state
  const getClassName = ({ isActive }) => isActive ? 'active item' : 'item';

  return (
  <div >     
      <div className="ui secondary pointing menu">
         <NavLink className={getClassName} to="employees" >
               Employees
         </NavLink>
         <NavLink className={getClassName} to="app-categories">
              App Categories
         </NavLink>
         <NavLink className={getClassName} to="password">
           Password
         </NavLink>
      </div>
    </div>  
  );
};


export default TopMenuForAdmin





