import { createAsyncThunk } from '@reduxjs/toolkit';
const KEYS = require('../../config/keys')

const fetchInitialWebApps = createAsyncThunk('fetchInitialWebApps/fetch', async(term) =>{
  //console.log("fetchInitialWebApps")
  //console.log(KEYS.WEBAPPS_PUBLISH_STATUS)

	return fetch(KEYS.GRAPHQL_URL, {
	//const response = fetch(KEYS.GRAPHQL_URL, {
    method: 'POST',
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      //query: query,
      variables: {
        webappsPublishStatus: KEYS.WEBAPPS_PUBLISH_STATUS
      },
    	query: `query ($webappsPublishStatus: String) {
                webapps(publishStatus: $webappsPublishStatus) {
                  __typename
                  name
                  id
                  description
                  logo_url
                  initial_image_url
                  categories {
                    id
                    name
                  }
                }
              }`	
    })
  }).then(res => res.json())
    console.log(response)
})


const fetchWebAppById = createAsyncThunk('fetchWebAppById/fetch', async(term) =>{
  //console.log("fetchWebAppById")
  return fetch(KEYS.GRAPHQL_URL, {
  //const response = fetch(KEYS.GRAPHQL_URL, {
    method: 'POST',
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      //query: query,
      //variables: variables
      query: `{
              appById (appId: "${term}") {
                ... on Web {
                      __typename
                      name
                      id
                      description
                      logo_url
                      initial_image_url
                      categories {
                        id
                        name
                      }
                       productInsight {
                                      productText
                                      valueText
                                      shouldDisplay
                                      monetizationText
                                      competitorText
                                      userSegmentText
                                      missionText
                                    }  
                        }
                  }
            }`  
    })
  }).then(res => res.json())
    //console.log(response)
})

//As of now just using it to log the app name users are searching from the search bar
//goal is to identify popular apps. 
const fetchWebAppByName = createAsyncThunk('fetchWebAppByName/fetch', async(terms) =>{
   //NOTE: TERMS here is an obj
   //just for this api as it evloves special chars in terms
   const encodedTerm = encodeURIComponent(terms.name);
  //console.log("fetchWebAppByName encodedTerm", encodedTerm) 
  //console.log("fetchWebAppByName type", terms) 
  return fetch(KEYS.GRAPHQL_URL, {
  //const response = fetch(KEYS.GRAPHQL_URL, {
    method: 'POST',
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      //query: query,
      //variables: variables
      query: `{
              searchAppByName (appName: "${encodedTerm}", appType: "${terms.__typename}") 
                
            }`  
    })
  }).then(res => res.json())
   // console.log(res)
})


const fetchProductInsightForWebApp = createAsyncThunk('fetchProductInsightForWebApp/fetch', async(term) =>{
  //console.log("fetchProductInsightForWebApp")
  return fetch(KEYS.GRAPHQL_URL, {
    method: 'POST',
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      //query: query,
      variables: {
        appId: term
      },
      query: `query ProductInsightAppById($appId: ID!) {
                                              productInsightAppById(appId: $appId) {
                                              shouldDisplay
                                              productText
                                              userSegmentText
                                              valueText
                                              monetizationText
                                              competitorText
                                              missionText
                                            }
                                          }`  
    })
  }).then(res => res.json())
    console.log(response)
})


const updateWebAppById = createAsyncThunk('updateWebAppById/update', async({ appId, webApp }) => {
  //console.log("updateWebAppById")
  //console.log(appId)
  //console.log(webApp)

  // Determine which fields are being updated
    const updatedFields = Object.keys(webApp);

    // Construct the GraphQL mutation response fields dynamically based on the updated fields
    const responseFields = updatedFields.map(field => `
      ${field}
    `).join(' ');

    //console.log(responseFields)

  return fetch(KEYS.GRAPHQL_URL, {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
          variables: {
          appId,
          webApp,
        },
          query: `
          mutation UpdateWebAppByID($appId: ID!, $webApp: updateWebApp) {
            updateWebAppByID(appId: $appId, webApp: $webApp) {
              ${responseFields}
          }
        }
        `
      })
  }).then(res => res.json())
})






const updateProductInsightByAppId = createAsyncThunk('updateProductInsightByAppId/update', async({ appId, newInsight }) => {
  //console.log("updateProductInsightByAppId")
  //console.log(appId)
  //console.log(newInsight)

  // Determine which fields are being updated
    const updatedFields = Object.keys(newInsight);

    // Construct the GraphQL mutation response fields dynamically based on the updated fields
    const responseFields = updatedFields.map(field => `
      ${field}
    `).join(' ');

    //console.log(responseFields)

  return fetch(KEYS.GRAPHQL_URL, {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
          variables: {
          appId,
          newInsight,
        },
          query: `
          mutation UpdateProductInsightByAppId($appId: ID!, $newInsight: updateProductInsight!) {
            updateProductInsightByAppId(appId: $appId, newInsight: $newInsight) {
              ${responseFields}
          }
        }
        `
      })
  }).then(res => res.json())
})


const fetchAllDetailsOfAppToEditById = createAsyncThunk('fetchAllDetailsOfAppToEditById/fetch', async({appId}) =>{
  //console.log("fetchAllDetailsOfAppToEditById", appId)
  return fetch(KEYS.GRAPHQL_URL, {
  //const response = fetch(KEYS.GRAPHQL_URL, {
    method: 'POST',
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      variables: {
          appId,
        },
      query: `query AppById($appId: ID!) {
                appById(appId: $appId) {
                ... on Web {
                        id
                        name
                        description
                        __typename
                        href
                        publishStatus
                        logo_url
                        initial_image_url
                        createdAt
                        searchTerms
                        categories {
                        id
                        name
                        }
                        monetizations {
                          id
                          name
                          priceRange
                        }
                        competitors{
                                      ... on iOS {
                                          __typename
                                            id
                                            name
                                            href
                                          }
                                      ... on Web {
                                          __typename
                                            id
                                            name
                                            href
                                          }
                                        }
                       productInsight {
                                      productText
                                      valueText
                                      shouldDisplay
                                      monetizationText
                                      competitorText
                                      userSegmentText
                                      missionText
                                    }  
                        }
                 ... on iOS {
                           id
                          name
                          description
                          __typename
                          appStoreUrl
                          href
                          publishStatus
                          logo_url
                          initial_image_url
                          createdAt
                          searchTerms
                          categories {
                            id
                            name
                          }
                          monetizations {
                            id
                            name
                            priceRange
                          }
                          competitors{
                                      ... on iOS {
                                            __typename
                                            id
                                            name
                                            href
                                          }
                                      ... on Web {
                                            __typename
                                            id
                                            name
                                            href
                                          }
                                        }
                          productInsight {
                                      productText
                                      valueText
                                      shouldDisplay
                                      monetizationText
                                      competitorText
                                      userSegmentText
                                      missionText
                                    } 
                        }        
                  }
            }`  
    })
  }).then(res => res.json())
    //console.log(response)
})


const createWebApp = createAsyncThunk('createWebApp/create', async({ webApp }) => {
  //console.log("createWebApp")
  //console.log(webApp)

  return fetch(KEYS.GRAPHQL_URL, {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
          variables: {
          webApp,
        },
          query: `
          mutation createWebApp($webApp: webAppInput) {
            createWebApp(webApp: $webApp) {
              __typename
              id
              name
          }
        }
        `
      })
  }).then(res => res.json())
})

const fetchWebCompetitorsByAppId = createAsyncThunk('fetchWebCompetitorsByAppId/fetch', async(term) =>{
  //console.log("fetchWebCompetitorsByAppId")
  return fetch(KEYS.GRAPHQL_URL, {
    method: 'POST',
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      //query: query,
      variables: {
        appId: term
      },
      query: `query AppById($appId: ID!) {
                                    appById(appId: $appId) {
                                      ... on Web {
                                        id
                                        competitors{
                                          ... on iOS {
                                            id
                                            name
                                            href
                                          }
                                          ... on Web {
                                            id
                                            name
                                            href
                                          }
                                        }
                                      }
                                      ... on iOS {
                                        id
                                        competitors{
                                          ... on iOS {
                                            id
                                            name
                                            href
                                          }
                                          ... on Web {
                                            id
                                            name
                                            href
                                          }
                                        }
                                      }
                                    }
                                  }
          `  
    })
  }).then(res => res.json())
    console.log(response)
})


export { fetchInitialWebApps, 
        fetchWebAppById, 
        fetchWebAppByName, 
        fetchProductInsightForWebApp, 
        updateWebAppById, 
        updateProductInsightByAppId, 
        fetchAllDetailsOfAppToEditById,
        createWebApp,
        fetchWebCompetitorsByAppId
      }
