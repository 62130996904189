// AppCompetitorsField.js
import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AppCompetitorsSearchBar from './AppCompetitorsSearchBar'
import { updateAppCompetitorsiOSAppByID } from '../../../../store'


const AppCompetitorsField = ({ value = [], appType, appId, label }) => {

  const [currentCompetitors, setCurrentCompetitors] = useState(value);
  const [newTermsArr, setNewTermsArr] = useState([]);
  const [isEditing, setIsEditing] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setCurrentCompetitors(value);
  }, [value]);

  const handleSave = (e) => {
    e.preventDefault()
    const iOsApp = {
      competitors: currentCompetitors.map(category => category.id)
    };
    //console.log(iOsApp)
    dispatch(updateAppCompetitorsiOSAppByID({ appId, iOsApp }))
    setIsEditing(false); // Exit editing mode
  };

  const handleCancel = () => {
    setCurrentCompetitors(value);
    setIsEditing(false); // Exit editing mode
  };

  // Function to check if there are any changes
  const hasChanges = () => {
    // If the lengths differ, return true (changes detected)
    // if (currentCompetitors.length !== value.length) {
    //   return true;
    // }
    // Use currentCompetitors instead of currentCategories
    const currentIds = new Set(currentCompetitors.map(competitor => competitor.id));
    const valueIds = new Set(value.map(item => item.id));

    // Check if every id in currentIds is in valueIds and vice versa
    const hasSameIds = [...currentIds].every(id => valueIds.has(id)) && [...valueIds].every(id => currentIds.has(id));

    // Return true if there are changes (i.e., not all IDs match)
    return !hasSameIds;

  };

  //handleAddCategory
  const handleSuggestionClicked = (category) => {
    //console.log("handleSuggestionClicked", category)
    if (!currentCompetitors.find(cat => cat.id === category.id)) {
      setCurrentCompetitors([...currentCompetitors, category]);
    }
  };

  const handleDeleteCategory = (id) => {
    setCurrentCompetitors(currentCompetitors.filter(cat => cat.id !== id));
  };




  if (isEditing) {
    return (
      <div className="ui form">
        <div className="field">
          <h3>
            {label}
            <button className="ui right floated button" onClick={handleCancel}>Cancel</button>
            <button disabled={!hasChanges()} className="ui right floated button" onClick={handleSave}>Save</button>
          </h3>
          <p>
            {currentCompetitors.map(cat => (
              <a key={cat.id} className="ui label">
                {cat.name + " (" + cat.__typename + ")"}
                <i className="delete icon" onClick={() => handleDeleteCategory(cat.id)}></i>
              </a>
            ))}
          </p>
          <AppCompetitorsSearchBar handleSuggestionClicked={handleSuggestionClicked} currentCompetitors={currentCompetitors} />
        </div>

      </div>
    );
  }


  return (
    <div>
      <h3>
        {label}
        <button className="ui right floated button" onClick={() => setIsEditing(true)}>Edit</button>
      </h3>
      <p>
        {currentCompetitors.map(cat => cat.name).join(', ')}
      </p>
    </div>
  );
};


export default AppCompetitorsField







