  //SearchSlice.js

import { createSlice } from '@reduxjs/toolkit'
import { fetchSearchSuggestions, fetchGreetingFromGraphQL } from '../thunks/searchThunk'

const SearchSlice = createSlice({
  name: 'search',
  initialState: {
    searchTerm: '',
    searchSuggestions: [], //array of obj [{name: "Asaa"}]
    searchedApp:'' //app name that a user wants to search e.g. "Meta" etc
  },
  reducers: {
    changeSearchTerm: (state, action) => {

      state.searchTerm = action.payload
      //state.searchSuggestions.push(action.payload) //temp
    },
    selectSearchSuggestion: (state, action) => {
      state.searchTerm = action.payload,
      state.searchSuggestions =[] //reset the suggestion array so that suggestion list closes
    },
    updateSearchedApp: (state, action) => {
      state.searchedApp = action.payload
    }

  },
  extraReducers(builder){
    //coming from graphQL - greetings API
    builder.addCase(fetchGreetingFromGraphQL.fulfilled, (state, action) => {
        //console.log(action.payload.data.greeting)
       state.searchSuggestions.push(action.payload.data.greeting) //updating the suggestionlist
    });
    //coming from graphQL
    builder.addCase(fetchSearchSuggestions.fulfilled, (state, action) => {
        //console.log(action.payload.data)
       state.searchSuggestions =action.payload.data.searchWebAppsAdmin.concat(action.payload.data.searchiOSAppsAdmin) //updating the suggestionlist
    });

  }
})

// Action creators are generated for each case reducer function
export const { changeSearchTerm, selectSearchSuggestion } = SearchSlice.actions

export const searchReducer = SearchSlice.reducer;