const transactionFeePriceRangeList = [
    "",
    "0% - 0.99%",
    "1% - 3.99%",
    "4% - 6.99%",
    "7% - 9.99%",
    "10% or more",
]


module.exports = { transactionFeePriceRangeList };