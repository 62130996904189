// IOSFlowDetailsLandingPage;.js
import React from 'react';
import { useEffect } from 'react'
 import { useSelector, useDispatch } from 'react-redux'

//to read the parameter passed from url via react routers
 //e.g. getting name in /web/apps/:name 
 import { useParams, Link, useNavigate } from 'react-router-dom';
import { fetchIOSUserFlowById } from '../../store'

import InlineLoader from '../Loaders/InlineLoader'
import SimpleLoader from '../Loaders/SimpleLoader'
import IOSFlowDetailsHeader from './IOSFlowDetailsHeader'
import { isProduction } from '../../utils/environment';

const IOSFlowDetailsLandingPage = () => {


const dispatch = useDispatch();

//getting parameter from page url (flow id)
const params = useParams();
const flowId = params.id
//console.log(flowId)

const iOSApp = useSelector((state) => {
  //console.log(state.iosapps.selectediOSApp)
  return state.selectedApp.app
})
//console.log("iOSApp", iOSApp)

const selectedFlow = useSelector((state) => {
     //console.log("selectedFlow", state.iosuserflows.selectedFlow)
      return state.iosuserflows.selectedFlow
   })  
//console.log("selectedFlow", selectedFlow)


useEffect(()=> {
    //To make sure a page is scrolled to the top on component load 
    window.scrollTo(0, 0);
    //console.log("IOSFlowDetailsLandingPage useEffect")
        //console.log(Object.keys(userFlowsByAppId).length)
        
        //this is to handle page refresh
        if(Object.keys(selectedFlow).length === 0 )
        {
         console.log("FETCHING USER FLOWS BY ID")
            //NOTE: this will also update iOSApp state (selectediOSApp)        
           dispatch(fetchIOSUserFlowById(flowId )) 
         }
    },[]);



//const screens = ['1', '2', '3', '4']



//console.log(flow)

  //console.log(screens)
  return (
  //asdas

    <div className="ui top-section">

    {
       (Object.keys(selectedFlow).length === 0 && Object.keys(iOSApp).length === 0) ?

           (
            <SimpleLoader/>
            )
           :
           (
            <div>
            <IOSFlowDetailsHeader iOSApp = {iOSApp} flow= {selectedFlow}/>
                <div className="iosapp-flows-horizontal-scrollable-list-container">
           <ul className="ios-list">
           {    
              selectedFlow.screens.screen.map((screen, i) => (
              //selectedFlow.flow.screens.screen.map((screen, i) => (

                    <li key={i}>
            
                        <img className="flow-details-page-webapp-images" src={screen.image.image_url}/> 
                        
                                 
                    <p>{screen.description}</p>
                    
                     <p>{isProduction ? null: "screen.id: "+screen.id}</p>
                     <p>{isProduction ? null: "image.id: "+screen.image.id}</p>
                     
                    </li>
                    
                  ))
            }
             </ul> 
            </div> 
            <p>{isProduction ? null: "userflow.id: "+selectedFlow.id}</p>
            </div>


            )

    }

  
       </div>     
        

   
  );
};


export default IOSFlowDetailsLandingPage;









