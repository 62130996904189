// WebEditScreens.js
import React from 'react';
import { Link } from 'react-router-dom'
import {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom';
import { addNewScreens, setIsUserFlowUpdating } from '../../store/slices/UserFlowSlice'
import { fetchUserFlowById, 
        fetchGreetingFromUploadImageServer, uploadWebImages,
        addScreensObjsToUserflow,  } from '../../store'
import WebEditScreensHeader from './WebEditScreensHeader'
import SimpleLoader from '../Loaders/SimpleLoader'
import WebEditScreenListItem from './WebEditScreenListItem'
import formatFileSize from '../../utils/formatFileSize'
import { v4 as uuidv4 } from 'uuid';
import { formatFlowName } from '../../utils/FlowNameFormatter';
import { nanoid } from 'nanoid';



const WebEditScreens = () => {

const params = useParams();
const flowidfromURL = params.flowid    

const dispatch = useDispatch();

 useEffect(()=> {
    // Call testImageUploadServer immediately on page load
    testImageUploadServer();
   //To make sure a page is scrolled to the top on component load 
    window.scrollTo(0, 0);

if(Object.keys(selectedFlow).length === 0 )
        {
         console.log("FETCHING USER FLOWS BY ID")
            //NOTE: this will also update iOSApp state (selectediOSApp)        
           dispatch(fetchUserFlowById(flowidfromURL )) 
         }
         
    },[]);


const [getMenuOpen, setMenuOpen] = useState(false);
const [isUploadServerUp, setIsUploadServerUp] = useState(false);


//console.log("is menu open", getMenuOpen)

const webApp = useSelector((state) => {
  //console.log(state.selectedApp.app)
  return state.selectedApp.app
})
//console.log("webApp", webApp)

const selectedFlow = useSelector((state) => {
     //console.log("selectedFlow", state.userflows.selectedFlow)
      return state.userflows.selectedFlow
}) 

const pendingScreenUpdates = useSelector((state) => {
     //console.log("pendingScreenUpdates", state.userflows.pendingScreenUpdates)
      return state.userflows.pendingScreenUpdates
}) 

const isUserflowUpdating = useSelector((state) => {
     //console.log("isUserflowUpdating", state.userflows.isUserflowUpdating)
      return state.userflows.isUserflowUpdating
}) 


//console.log("pendingScreenUpdates", pendingScreenUpdates)


 const isDataLoaded = Object.keys(selectedFlow).length > 0 && Object.keys(webApp).length > 0 && !isUserflowUpdating; 

const handleOnClick = (r) => {   
    const id = r.target.id;
       //console.log("ASDASD",id);
        setMenuOpen(!getMenuOpen)   
    };

const handleImageChange = (e) => {
    //console.log("handleImageChange")

    const files = Array.from(e.target.files);
    //console.log(files)

     // Filter out non-image files based on MIME type
    const imageFiles = files.filter(file => file.type.startsWith('image/'));

      // Sort image files by last modified date
    const sortedImageFiles = imageFiles.sort((a, b) => a.lastModified - b.lastModified);

     // Sort image files by last creation date
    // const sortedImageFiles = imageFiles.sort((a, b) => a.creationDate - b.creationDate);

     //console.log(imageFiles.map(file => file.lastModified));

    const formattedName = formatFlowName(selectedFlow.name, webApp.name, webApp.__typename);
    //console.log("formattedName", formattedName)
    // Create screen objects from sorted image file
    //const screens = Promise.all(imageFiles.map(async (file, index) => {
    const screens = sortedImageFiles.map((file, index) => {
        const imageUrl = URL.createObjectURL(file);
        const fileSize = formatFileSize(file.size);
        //code to rename a file
        //file =  { ...file, name: `renamed-${file.name}` };
        //console.log(file.name)
        
        return {
          lid: uuidv4(), // Local unique ID generated using uuid  
          name: '', // Name field, could use file.name if desired
          description: '', // Description field, keep as empty or set a default value
          //position: index+1, // Use index as position for simplicity
          position: selectedFlow.screens.screen.length + index + 1, // Use index as position for simplicity
          originalFileSize: fileSize,
          filename: file.name,
          shouldUploadImage: true, 
          prefixImagefileNametoUseWhenUploading: formattedName,
          isNew: true, // Flag to indicate this is a new item
          event_types:[],
          image: {
            id: '', // Assuming a unique ID will be generated later or is not needed here
            image_url: imageUrl, // Temporary URL for the image
          },
        };
      });


   // console.log(screens)
      //Dispatch an action to add these screens to your Redux store
    dispatch(addNewScreens(screens));

     
}

const handleAddNewScreens =  async () => {
    //console.log("asdasdasd")
    //to start showing the loading screen
    //dispatch(setIsUserFlowUpdating(true))
    var allNewScreensWithImages = selectedFlow.screens.screen.filter(screen => screen.isNew)
    //console.log("allNewScreensWithImages",allNewScreensWithImages)
    var newScreensWithImagesToUpload = allNewScreensWithImages.filter(screen =>screen.shouldUploadImage)
    //console.log("newScreensWithImagesToUpload",newScreensWithImagesToUpload)
    if(newScreensWithImagesToUpload.length > 0){
        const formData = new FormData();

        for (const [index, screen] of newScreensWithImagesToUpload.entries()) {
        const response = await fetch(screen.image.image_url);
        if (!response.ok) throw new Error('Network response was not ok');

        const blob = await response.blob();
        const uniqueId = nanoid(5);
        const fileNameToUse = screen.prefixImagefileNametoUseWhenUploading + "_" + uniqueId;
        //const fileNameToUse = screen.prefixImagefileNametoUseWhenUploading + "_" + screen.position;

        //console.log(screen.prefixImagefileNametoUseWhenUploading)
        //console.log(screen)
        //console.log(fileNameToUse)
        const file = new File([blob], fileNameToUse, { type: blob.type });
        formData.append(`images`, file);
        //formData.append(`clientId`, screen.lid);
         formData.append(`images[${index}].clientId`, screen.lid); 
      }
        //console.log(formData)
        try {
            const response = await dispatch(uploadWebImages(formData)).unwrap();
            //console.log("uploadWebImages response", response);

                    response.forEach((responseItem) => {

                           allNewScreensWithImages = allNewScreensWithImages.map((screen) => {
                                        if (screen.lid === responseItem.lid) {
                                            return {
                                                ...screen,
                                                image: {
                                                    ...screen.image,
                                                    image_url: responseItem.image_url,
                                                },
                                            };
                                        }
                                             return screen;
                                    });
                        });     

            } catch (error) {
            console.log("error uploading IOS images", error);
            }
    }

    //console.log(allNewScreensWithImages)

    const transformedArrayForGQLMutation = allNewScreensWithImages.map((item) => ({
          name: item.name,
          description: item.description,
          image_url: item.image.image_url,
          event_types: item.event_types, // Set the event_type to a specific value or derive it from the original item if applicable
          position: item.position,
    }));
   // console.log("WEB transformedArrayForGQLMutation", transformedArrayForGQLMutation)

    //adding new screens to userflow
    dispatch(addScreensObjsToUserflow({flowId : selectedFlow.id, screens: transformedArrayForGQLMutation})) 
}

const testImageUploadServer = async () => {
  //console.log("testImageUploadServer")
  setIsUploadServerUp(false)
 await dispatch(fetchGreetingFromUploadImageServer()).unwrap().then((response) => {
      //console.log("is upload server up?",response)
      setIsUploadServerUp(true)
     }).catch((error) => {
      setIsUploadServerUp(false)
      console.log("error", error)
  });
}


  return ( 
    <div className="ui top-section"> 

        {
            !isDataLoaded ? (
                <SimpleLoader />
            ) : ( 

                <>
                <WebEditScreensHeader app={webApp} flow={selectedFlow} addNewScreensHandler={handleAddNewScreens} pendingScreenUpdates={pendingScreenUpdates}/>
                Add New screens: <input type="file" multiple onChange={handleImageChange} />
                <button className={!isUploadServerUp ? "ui loading button": "ui green button"} 
                  onClick={testImageUploadServer}>
                    {isUploadServerUp ? "Image Upload Server is Up" : "Checking Image Upload Server"}
                  </button>
                <div className="webapp-flows-horizontal-scrollable-list-container">
                <ul className="web-list">

                   {    
                      selectedFlow.screens.screen.map((screen, i) => (
                            <li key={i}>
                                
                                <WebEditScreenListItem index={i} screen={screen} flow={selectedFlow} pendingScreenUpdates={pendingScreenUpdates} app={webApp}/>      
                            </li>
                          ))}
                        </ul> 
                        </div>
                      </>
                    )}
         
        </div>    
       
  );
};


export default WebEditScreens

