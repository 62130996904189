//UserAuthSlice.js

import { createSlice } from '@reduxjs/toolkit'
import { empLogInWithEmailAndPassword, validateToken, validateEmployeeTokenAndReturnEmployee } from '../thunks/authThunk'


const UserAuthSlice = createSlice({
	name: 'auth',
	initialState: {
		isLoading: false,
		isAuthenticated: false,
		isAuthLoading: true, // only used in App.js to prevent flicker any page before token validation
		employee: null,
		errorMessage: '',
    	hasError: false, 
  		scopes: [],
	},
	reducers: {
		logout: (state, action)=> {
			//console.log("inside logout action")
			 // Remove the persisted token from localStorage
        	localStorage.removeItem('token');
			state.isAuthenticated = false
			state.isAuthLoading = false
			state.errorMessage = ""
			state.hasError = false
		}
	},
	extraReducers(builder){
			
	  builder.addCase(empLogInWithEmailAndPassword.pending, (state, action) => {
	     state.isLoading = true
	  	});	
	  builder.addCase(empLogInWithEmailAndPassword.fulfilled, (state, action) => {
	     //console.log(action.payload.data.empLogInWithEmailAndPassword)
	     const { success, token, employee, errorMessage } = action.payload.data.empLogInWithEmailAndPassword
       
	     if (success){
			if(employee.hasAccess){
				state.employee = employee
				state.hasError = false
				state.errorMessage = ''
				localStorage.setItem('token', action.payload.data.empLogInWithEmailAndPassword.token);
			} else {
				state.hasError = true
				state.errorMessage = 'You do not have access to this application.'
			}
	     	
	     }else {
	     	state.hasError = true
	     	state.errorMessage = 'Login failed. Please ensure your email and password are correct.'
	     }
	     state.isAuthenticated = success
	     state.isLoading = false
	  });
	  builder.addCase(validateToken.pending, (state, action) => {
	     //state.isLoading = true
		 state.isAuthLoading = true
	  });	
	 
	  builder.addCase(validateToken.fulfilled, (state, action) => {
	  	const { valid } = action.payload.data.validateToken
	  	console.log("valid", valid)
	  	
	  	if(valid){
	  		state.isAuthenticated= true
	  	}else{
	  		state.isAuthenticated=false
	  	}	

	   // state.isLoading = false
		state.isAuthLoading = false
	  });
	  builder.addCase(validateEmployeeTokenAndReturnEmployee.pending, (state, action) => {
	     //state.isLoading = true
		 state.isAuthLoading = true
	  });
	  builder.addCase(validateEmployeeTokenAndReturnEmployee.fulfilled, (state, action) => {
	  	const { success, employee } = action.payload.data.validateEmployeeTokenAndReturnEmployee
	  	console.log("valid", success)
	  	
	  	if(success){
			if(employee.hasAccess){
				state.employee = employee
				state.hasError = false
				state.errorMessage = ''
		  		state.isAuthenticated= true
			}else{
				state.hasError = true
				state.errorMessage = 'You do not have access to this application.'
				state.isAuthenticated=false
			}	
	  	}else{
	  		state.isAuthenticated=false
	  	}	
		  state.isAuthLoading = false
	});

	}
})


export const { logout } = UserAuthSlice.actions;
export const userAuthReducer = UserAuthSlice.reducer;