//AppCategorySelectionsSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { allCategories } from '../../utils/allCategories'


export const AppCategorySelectionsSlice = createSlice({
  name: 'appcategory',
  initialState: {
    selectedItems: [],
    loading: false,
    error: null,
    allCategories: allCategories, //all possible caterogies of an app - for now store all here
    
  },
  reducers: {
    setSelectedItems: (state, action) => {
      state.selectedItems = action.payload;
    },
    removeSelectedItem: (state, action) => {
      state.selectedItems = state.selectedItems.filter(item => item !== action.payload);
    }
  },
  extraReducers: (builder) => {
    // builder
    //   .addCase(fetchNames.pending, (state) => {
    //     state.loading = true;
    //   })
    //   .addCase(fetchNames.fulfilled, (state, action) => {
    //     state.loading = false;
    //     state.allCategories = action.payload;
    //     state.error = null;
    //   })
    //   .addCase(fetchNames.rejected, (state, action) => {
    //     state.loading = false;
    //     state.error = action.payload;
    //   });
  }
});

export const { setSelectedItems, removeSelectedItem } = AppCategorySelectionsSlice.actions;

export const appCategorySelectionReducer = AppCategorySelectionsSlice.reducer;
