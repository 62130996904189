// IOSAppsResultGrid.js
import React from "react";
import { useDispatch } from "react-redux";
import { selectediOSApp, selectedApp } from "../../store";
import { Link } from "react-router-dom";

const IOSAppsResultGrid = ({ iosapps }) => {
  // console.log(iosapps);
  //console.log("IOSAppsResultGrid);
  const dispatch = useDispatch();

  //console.log("IOSAppsResultGrid",iosapps)

  const handleOnClick = (r) => {
    const iosappclicked = r;
    //console.log(iosappclicked)
    //dispatch(selectediOSApp(iosappclicked))
    dispatch(selectedApp(iosappclicked));
  };

  // Truncate function
  function truncate(str, num) {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  }

  // const iosapps = [
  //   {
  //     id: 1,
  //     name: "App1",
  //     initial_image_url: "https://meeako-bucket.s3.amazonaws.com/migrated/65664b77724e77be0230e965.jpg",
  //     logo_url: "https://via.placeholder.com/150",
  //   },

   
      
  // ]

  return (
    <div className="doubling stackable six column row">
    {iosapps.map((result, i) => (
      <div className="column" key={i} style={{ marginBottom: '4vh' }}> 
        <Link
          to={"/ios/apps/" + result.id + "/flows"}
          onClick={() => handleOnClick(result)}
          className="disabled"
        >
          <div> {/* Wrapper div if needed for additional styling */}
            <img
              className="ui image iosapps-images"
              src={result.initial_image_url}
            />

            <div className="ui header">
              <img className="ui mini rounded image" src={result.logo_url} />
              <div className="content">{truncate(result.name, 15)}</div>
            </div>
          </div>
        </Link>
      </div>
    ))}
  </div>
  );
};

export default IOSAppsResultGrid;
