// ImagePickerEditor.js
import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateiOSAppByID } from '../../../store'


const ImagePickerEditor = ({value, label, appType, appId, appName}) => {

  const [status, setStatus] = useState(value);
  const [isEditing, setIsEditing] = useState(false);
  const [originalData, setOriginalData] = useState(null);
   const [image, setImage] = useState(value); // URL or file object for the image
  const [preview, setPreview] = useState(null); // For the image preview
  const dispatch = useDispatch();

   useEffect(() => {
      // Update local state to match external value when entering edit mode
    setStatus(value);
    setImage(value);
      setOriginalData(value); // Save the original data for comparison
  }, [value]);

  const handleSave = (e) => {
    e.preventDefault()

    const iOsApp = {
      publishStatus: status
    };
    //dispatch(updateiOSAppByID({ appId, iOsApp }))
    setIsEditing(false); // Exit editing mode
  };

  const handleCancel = () => {
    setStatus(value); // Reset the value to the original
    setIsEditing(false); // Exit editing mode
    setPreview(false)
  };

  // Function to check if there are any changes
  const hasChanges = () => {
    return (
      image !== originalData
    );
  };

   const handleChange = (e) => {
    setStatus(e.target.value);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.substr(0, 5) === 'image') {
    
      
       // Use FileReader to read the file's data and use it as an image URL for preview
      const reader = new FileReader();
      reader.onload = (loadEvent) => {
      // loadEvent.target.result contains the base64 encoded image data
      setImage(loadEvent.target.result); // Update state with the base64 image data for preview
    };
    reader.readAsDataURL(file); // Read the file as Data URL (base64)
    setPreview(file)
    setImage(file);

    } else {
      setImage(null);
      setPreview(null)
    }
  };

//console.log("image", image)
//console.log("preview", preview)

if (isEditing) {
    return (
      <div className="ui form">
        <div className="field">
          <h3>
            {label}
            <button className="ui right floated button" onClick={handleCancel}>Cancel</button>
            <button disabled={!hasChanges()}  className="ui right floated button" onClick={handleSave}>Save</button>
          </h3>
             <input type="file" onChange={handleImageChange} />

             {
              preview && (
            <>
                  <div>IOS Landing grid
                    <div className="ui header">
                    <img className="ui mini rounded image" src={image}/>
                    </div>
                </div>
                <div>App Flows Page - Back button</div>
                  <h1 className="ui header">
                        <img className="ui large rounded image" src={image}/>
                        <div className="content">
                            {appName}      
                        </div>
                    </h1>
            </>  
            )}     
        </div>
      </div>
    );
  }


  return (
   <div>
          <h3>
            {label}
           <button className="ui right floated button" onClick={() => setIsEditing(true)}>Edit</button>
          </h3>
          <div>
            {value}

            {appType === 'iOS' && (
            <>
                  <div>IOS Landing grid
                    <div className="ui header">
                    <img className="ui mini rounded image" src={value}/>
                    </div>
                </div>
                <div>App Flows Page - Back button</div>
                  <h1 className="ui header">
                        <img className="ui large rounded image" src={value}/>
                        <div className="content">
                            {appName}      
                        </div>
                    </h1>
            </>  
            )}     
            

      
          </div>
  </div>
  );
};


export default ImagePickerEditor







