// AddNewAppPage.js
import React from 'react';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import SearchBarHeader from '../SearchBarHeader/SearchBarHeader'

import SimpleLoader from '../Loaders/SimpleLoader'
import ReviewImagePage from './ReviewImagePage'
import { v4 as uuidv4 } from 'uuid';
import { formatAppName } from '../../utils/formatAppName'; 


import {uploadIOSImages, uploadWebImages, createiOSApp, createWebApp } from '../../store'


const AddNewAppPage = () => {

 
  const dispatch = useDispatch();

  const [appName, setAppName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [description, setDescription] = useState("");
  const [publishStatus, setPublishStatus] = useState("draft");
  const [appType, setAppType] = useState("");
  const [appStoreURL, setAppStoreURL] = useState("");
  const [initialImageUrl, setInitialImageUrl] = useState("");
  const [logoUrl, setLogoUrl] = useState("");
  const [websiteURL, setWebsiteURL] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isTry, setIsTry] = useState(false);

  // State to keep track of original data
  const [originalData, setOriginalData] = useState({
                    appName: "",
                    description: "",
                    publishStatus: "draft",
                    appType: "",
                    logoUrl: "",
                    initialImageUrl: "",
                    websiteURL: "",
                    appStoreURL: ""
                  });

   // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true)
    console.log("handleSubmit")


          const formData = new FormData();

          const logoURLresponse = await fetch(logoUrl);
          if (!logoURLresponse.ok) throw new Error('Network response was not ok');

          const logoURLblob = await logoURLresponse.blob();
          const fileNameToUse1 = formatAppName(appName) + "_" + appType + "_logoUrl.png";

          const logoUrlfile = new File([logoURLblob], fileNameToUse1, { type: logoURLblob.type });
          console.log(logoUrlfile)

          const initialImageURLresponse = await fetch(initialImageUrl);
          if (!initialImageURLresponse.ok) throw new Error('Network response was not ok');

          const initialImageURLblob = await initialImageURLresponse.blob();
          const fileNameToUse2 = formatAppName(appName)+"_"+appType+"_initial_image_url.png";

          const initialImageUrlfile = new File([initialImageURLblob], fileNameToUse2, { type: initialImageURLblob.type });
          console.log(initialImageUrlfile)

          const logoUrl_UID = uuidv4()
          const initialImageURL_UID = uuidv4()

          formData.append(`images`, logoUrlfile);
          formData.append(`images[0].clientId`, logoUrl_UID); 

          formData.append(`images`, initialImageUrlfile);
          formData.append(`images[1].clientId`, initialImageURL_UID);

          let server_side_logo_url   
          let server_side_initial_image_url   

          //resizing and uploading logo and initial images
          try {
                let response;
                if (appType === "iOS") {
                  response = await dispatch(uploadIOSImages(formData)).unwrap();
                } else if (appType === "web") {
                   response = await dispatch(uploadWebImages(formData)).unwrap();
                }

                 response.forEach((responseItem) => {
                    console.log("responseItem", responseItem)
                     switch (responseItem.lid) {
                              case logoUrl_UID:
                                  //console.log("logoUrl_UID matched:", responseItem); 
                                  server_side_logo_url = responseItem.image_url
                                  break;
                              case initialImageURL_UID:
                                  //console.log("initialImageURL_UID matched:", responseItem); 
                                  server_side_initial_image_url = responseItem.image_url
                                  break;
                              default:
                                  // Optionally handle unmatched cases or do nothing
                                  break;
                          }
                });
          } catch (error) {
              console.log(`error uploading ${appName} ${appType} images`, error);
              setIsLoading(false)
          }

          //console.log("server_side_logo_url" ,server_side_logo_url)
          //console.log("server_side_initial_image_url", server_side_initial_image_url)

          //creating apps 
          try {
                let newapp;
                if (appType === "iOS") {
                    const iOsApp = {
                                  name: appName,
                                  description: description,
                                  publishStatus: publishStatus,
                                  appStoreUrl: appStoreURL,
                                  logo_url: server_side_logo_url,
                                  initial_image_url: server_side_initial_image_url
                                };
                   const response = await dispatch(createiOSApp({ iOsApp })).unwrap();
                    newapp = response.data.createiOSApp
                } else if (appType === "web") {
                    const webApp = {
                                  name: appName,
                                  description: description,
                                  publishStatus: publishStatus,
                                  url: websiteURL,
                                  logo_url: server_side_logo_url,
                                  initial_image_url: server_side_initial_image_url
                                };
                    const response = await dispatch(createWebApp({ webApp })).unwrap();
                    newapp = response.data.createWebApp
                }

                 
                 setSuccessMessage(`New ${newapp.__typename} ${newapp.name} app / ID# ${newapp.id} created`);
          } catch (error) {
              console.log(`error creating ${appName} ${appType} app`, error);
              setIsLoading(false)
          }

          setIsLoading(false)
  };


  

document.title = "Add New App ";


 const handlePublishStatusChange = (e) => {
    setPublishStatus(e.target.value);
  };

  const handleAppTypeChange = (e) => {
    setAppType(e.target.value);
  };


  const handlePositionChange = (e) => {
      const inputValue = e.target.value; // This is a string
      const numericValue = parseInt(inputValue); // Convert to number
      setPosition(numericValue)
  }


  const toggleTry = () => {
    setIsTry(!isTry); // Toggle the isTry state
  };

  // Function to check if there are any changes based on the appType
const hasChanges = () => {
  // Check common fields first
  const commonChanges = appName !== originalData.appName &&
                        appType !== originalData.appType &&
                        logoUrl !== originalData.logoUrl &&
                        initialImageUrl !== originalData.initialImageUrl;

          // Conditionally check URLs based on appType
          if (appType === "iOS") {
            return commonChanges && appStoreURL !== originalData.appStoreURL;
          } else if (appType === "web") {
            return commonChanges && websiteURL !== originalData.websiteURL;
          } else {
            // Optionally handle other types or default no URL changes
            return commonChanges;
          }
};

 const handleClear = (e) => {
      e.preventDefault();
      setAppName(originalData.appName)
      setDescription(originalData.description)
      setPublishStatus(originalData.publishStatus);
      setAppType(originalData.appType)
      setInitialImageUrl(originalData.initialImageUrl)
      setLogoUrl(originalData.logoUrl)
      setAppStoreURL(originalData.appStoreURL)
      setWebsiteURL(originalData.websiteURL)

  };

 const handleLogoImageChange = (e) => {

    const file = e.target.files[0];
    if (file && file.type.substr(0, 5) === 'image') {
    
      const imageUrl = URL.createObjectURL(file);
      setLogoUrl(imageUrl)
      //setLogoUrl(file)

    } 
  };


const handleInitialImageChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.substr(0, 5) === 'image') {
    
      const imageUrl = URL.createObjectURL(file);
      setInitialImageUrl(imageUrl)
      

    } 
  };
//console.log("initialImageUrl", initialImageUrl)
//console.log("appType", appType)

  return (
   <div>
   <SearchBarHeader/> 
   <div className="ui top-section">
   
   <Link to="/browse/web/apps">
            <button className="ui button circular " 
            //onClick={handleBackButton}
            >
            <i className="angle left icon"></i> 
              Back
           </button>
        </Link>   
        
      {  successMessage && (
            <div className="ui positive message">
              <i className="close icon"></i>
              <div className="header">
                Success
              </div>
              <p>{successMessage}</p>
            </div>
        )}     

  <div className="ui raised segment">

      <form className={isLoading ? "ui loading form" : "ui form"}>
          
  <h1 className="ui dividing header">New App</h1>
      <div className="two fields">
          <div className="field">
                    <h3>
                     Publish Status*
                    </h3>

                      <div className="inline disabled fields">
                      <div className="field">
                        <div className="ui radio checkbox">
                          <input type="radio" value="published" checked={publishStatus === 'published'} onChange={handlePublishStatusChange} />
                          <label>published</label>
                        </div>
                       </div>
                       <div className="field"> 
                          <div className="ui radio checkbox">
                            <input type="radio" value="draft" checked={publishStatus === 'draft'} onChange={handlePublishStatusChange} />
                            <label>draft</label>
                          </div>
                       </div>
                    </div>  
          </div>
          

          <div className="field">
                    <h3>
                     App Type*
                    </h3>

                      <div className="inline fields">
                      <div className="field">
                        <div className="ui radio checkbox">
                          <input type="radio" value="iOS" checked={appType === 'iOS'} onChange={handleAppTypeChange} />
                          <label>iOS</label>
                        </div>
                       </div>
                       <div className="field"> 
                          <div className="ui radio checkbox">
                            <input type="radio" value="web" checked={appType === 'web'} onChange={handleAppTypeChange} />
                            <label>Web</label>
                          </div>
                       </div>
                    </div>  
          </div>
       </div>   
          
<div className="two fields">
          <div className="field">
              <h3>
               App Name*
              </h3>
                <input type="text" value={appName} onChange={(e) => setAppName(e.target.value)} />
          </div>


          {  appType === 'iOS' && (
            <div className="field">
              <h3>
               App Store URL* 
              </h3>
              <input type="text" value={appStoreURL} onChange={(e) => setAppStoreURL(e.target.value)}/>
          </div>
        )} 


         {  appType === 'web' && (
            <div className="field">
              <h3>
               Website URL* 
              </h3>
              <input type="text" value={websiteURL} onChange={(e) => setWebsiteURL(e.target.value)}/>
          </div>
        )} 

        </div>

          <div className="field">
          <h3>
            Description
          </h3>

          {
            isTry ? 
              (
                <p dangerouslySetInnerHTML={{ __html: description }} /> 
              )
              :
              (
                   <textarea
                rows="4" 
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />

              )

          }

              <div className="ui toggle checkbox">
                <input type="checkbox"
                checked={isTry}
                onChange={toggleTry} 
                 />
                <label>In HTML</label>
              </div>
        </div>

        
              <h3>
               Images*
              </h3>
              <div className="two fields">
              <div className="field">Logo URL
              <input type="file" onChange={handleLogoImageChange} /> 
              </div>
              <div className="field">Initial Image URL 
              <input type="file" onChange={handleInitialImageChange} /> 
              </div>
              
                  
          </div>

        


          {logoUrl && initialImageUrl && (
                       <div> 
                       <p> Review IOS (new tab)</p>
                       <ReviewImagePage appName={appName} logo_url={logoUrl} initialImageUrl={initialImageUrl} appType={appType} description={description}/>
                       </div>
                  )}


        
        <div className="fields">
          <div className="field">
          <button disabled={!hasChanges()} className="ui right floated teal button" onClick={handleSubmit}>Create New App</button>
          </div>
          <div className="field">
          <button data-tooltip="Clear all the form fields" className="ui right floated button" onClick={handleClear}>Clear</button>
          </div>
        </div>
            


          
      </form>
      </div>
  </div>
</div>
  );
};


export default AddNewAppPage








