// PasswordReset.js
import React from 'react';



const PasswordReset = () => {
  return (
   <div className="ui segment">
    <p>This is the Password management section.</p>
  </div> 
  );
};


export default PasswordReset





