// FlowsGridRowiOSApp;.js
import React from 'react';
import FlowsGridImageRowiOSApp from './FlowsGridImageRowiOSApp'
import { Link } from 'react-router-dom'
import { isProduction } from '../../utils/environment';
import { useParams } from 'react-router-dom';
import {updateSelectedIOSFlow} from '../../store'
import { useDispatch } from 'react-redux';




const FlowsGridRowiOSApp = ({userFlowRows}) => {
  //console.log(userFlowRows)

const params = useParams();
const idfromURL = params.appid  
const dispatch = useDispatch();

const handleClickEditScreenButton = (flow) => {
   //console.log("handleClickEditScreenButton",flow)
   dispatch(updateSelectedIOSFlow(flow))
  };

  return (
   <div >
   {
      userFlowRows.map((flow, i) => {
        return (
                <div className="screens-flows-rows" key={i}>
                      <h3 className="ui header">
                                     {flow.name +" #"+ flow.position}
                                  {// show the screen count text only if the screen count is greator than 0
                                    flow.screens.count > 0 && 

                                      <div className="sub header">
                                        {flow.screens.count > 1 ? `${flow.screens.count} screens ` : `${flow.screens.count} screen `}
                                        {flow.userInteractionsCount === 1 ? ' | 1 User Interaction' : flow.userInteractionsCount > 1 ? ` | ${flow.userInteractionsCount} User Interactions` : null}
                                      </div>
                                    }
                                    
                                     <p>
                                     {"userflow.id: "+flow.id}
                                    <Link to={"/ios/apps/"+idfromURL+"/flows/"+flow.id+"/editflow"}
                                    onClick={()=> handleClickEditScreenButton(flow)}>
                                     <button className="ui right floated button">Edit Flow</button>
                                     </Link>
                                     <Link to={"/ios/apps/flows/"+flow.id+"/editscreens"}
                                     onClick={()=> handleClickEditScreenButton(flow)}>
                                      <button className="ui right floated button">Edit Screens</button>
                                     </Link>
                                     </p>
                                    
                                     
                                      
                                     
                       </h3> 
                        <p dangerouslySetInnerHTML={{ __html: flow.description }} />

                                    {// show the image row only if the screen count is greator than 0
                                    flow.screens.count > 0 && 
                                       <FlowsGridImageRowiOSApp screens = {flow.screens.screen} flowId={flow.id} flow={flow}/>
                                    }
                             
                            
                            
                      
              </div>
          )})
    }

   
  </div> 
  );
};


export default FlowsGridRowiOSApp;

