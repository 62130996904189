// ReviewImagePage.js
import React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import SearchBarHeader from '../SearchBarHeader/SearchBarHeader'



const ReviewImagePage = ({logo_url, initialImageUrl, appName, appType, description}) => {


//console.log( "ReviewImagePage logoUrl", logo_url)



  return (
  
  <div>     
        
       
       <Link to="">
            <button className="ui button circular " 
            //onClick={handleBackButton}
            >
            <i className="angle left icon"></i> 
              Back
           </button>
        </Link>  

  
        <h1 className="ui header">
            <img className="ui large rounded image" src={logo_url}/>
            <div className="content">
            {appName}
            </div>
        </h1>
                       
            <div className=" ui grid">

            { appType === 'web' && (<div className="doubling stackable four column row" >
                    <div className="column" style={{ marginBottom: '1.5em' }}>
                    <Link to={""} >
                       
                        <img className="ui image webapps-images" src={initialImageUrl}/>   
                       
                       <div className="ui unstackable items">
                               <div className=" item">
                                   <div className="ui mini image">
                                            
                                          <img  src={logo_url}/>   
                                   </div>
                                   <div className="content">
                                          <div className="header">{appName}</div>
                                          <div className="extra">
                                                {description}
                                          </div>

                                   </div>
                              </div>
                        </div>    
                    </Link> 
                    </div>  
                </div>
            )}

            { appType === 'iOS' && (<div className="doubling stackable six column row ">
                    <div className="column">
                    <Link to={""}  className="disabled">
                       
                        <img className="ui image iosapps-images" src={initialImageUrl}/>   
                        <div className="ui header">
                                    <img className="ui mini rounded image" 
                            src={logo_url}
                         />
                            <div className="content">
                                 {appName}                
                             </div>    
                    </div>
                          
                    </Link>
                    </div>  
                </div>
            )}

            </div>
                          
   </div>  
  );
};


export default ReviewImagePage

