//WebAppsSlice.js

import { createSlice } from '@reduxjs/toolkit'
import { fetchInitialWebApps, fetchWebAppById, fetchProductInsightForWebApp, fetchWebCompetitorsByAppId } from '../thunks/webAppsThunk'
import { fetchUserFlowById } from '../thunks/userFlowThunk'

const WebAppsSlice = createSlice({
	name:"webapps",
	initialState: {
		initialWebApps:[],
		hasMore: false,
		limit: 10,
		//selectedWebApp: {}, 
		productInsight: {}
	},
	reducers: {
		selectedWebApp: (state, action)=> {
			//..state.selectedWebApp = action.payload
		}
	},
	extraReducers(builder){
		builder.addCase(fetchInitialWebApps.fulfilled, (state, action) => {
        //console.log(action.payload.data.webapps)
      state.initialWebApps = action.payload.data.webapps //updating the webapps
    });
		builder.addCase(fetchWebAppById.fulfilled, (state, action) => {
        //console.log(action.payload.data.appById)
      // state.selectedWebApp = action.payload.data.appById //updating the selectedWebApp
       //state.productInsight = action.payload.data.appById.productInsight // setting productInsight
    });
    builder.addCase(fetchUserFlowById.fulfilled, (state, action) => {
       // console.log("WebAppsSlice  fetchUserFlowById", action.payload.data)
      // state.selectedWebApp = action.payload.data.userFlowId.app //updating the selectedWebApp
    });	
     builder.addCase(fetchProductInsightForWebApp.fulfilled, (state, action) => {
       // console.log("WebAppsSlice  fetchProductInsightForWebApp", action.payload.data.productInsightAppById)
       //state.productInsight = action.payload.data.productInsightAppById//updating the productInsight
	   state.productInsight = {
		...state.productInsight,
		...action.payload.data.productInsightAppById
	  }
    });
	builder.addCase(fetchWebCompetitorsByAppId.fulfilled, (state, action) => {
	   //console.log("fetchWebCompetitorsByAppId", action.payload.data.appById)
		const { competitors} = action.payload.data.appById
		state.productInsight = {
			...state.productInsight,
			competitors
		}
	});	

	}
})


export const { selectedWebApp } = WebAppsSlice.actions;
export const webappsReducer = WebAppsSlice.reducer;