 // WebAppsLandingPage.js
 import React from 'react';
 import SearchBar from '../SearchBar'
 import SidebarFilterPanel from '../SidebarFilterPanel'
 import ResultGrid from '../ResultGrid'
 import Slider from "react-slick";
 import MobileWebTile from '../MobileWebTile'
 import AppUserFlowHorizontalSlider from '../AppsUserFlowSlider/AppUserFlowHorizontalSlider'
 import WebAppsResultGrid from './WebAppsResultGrid'
 import SearchBarHeader from '../SearchBarHeader/SearchBarHeader'
 import BackToTopButton from '../BackToTopButton/BackToTopButton'
 import SimpleLoader from '../Loaders/SimpleLoader'
 

 //to call API when this component loads
 import { useEffect } from 'react'
 import { useSelector, useDispatch } from 'react-redux'
 import { fetchInitialWebApps } from '../../store'


 const WebAppsLandingPage = () => {

    const dispatch = useDispatch();
    //calls when compenent is loaded
    useEffect(()=> {
        //To make sure a page is scrolled to the top on component load 
    window.scrollTo(0, 0);
        //fetch apps only if webapps array is 0 i.e on inital load
        if(webapps.length === 0){
        console.log("FETCHING INITIAL WEB APPS")
        dispatch(fetchInitialWebApps())
        }
        
    },[]);


    const webapps = useSelector((state) => {
     //console.log("webapps", state.webapps.initialWebApps)
     //console.log("selectedWebApp", state.webapps.selectedWebApp)
      return state.webapps.initialWebApps
   })

   return (
<div>

        <SearchBarHeader/>
        <div className="ui top-section">
            <div className="ui text ">
              <h1 className="ui header">
                Browse Web Apps
                
              </h1> 
             

            </div>
        </div>  

        <MobileWebTile active="web"/>

       
       
       {/*<AppUserFlowHorizontalSlider/>*/}
       
        <div className="ui right-left-spacing">   

                <div className=" ui grid">
                 {/*<h4>Results</h4>*/}
                   {/*<ResultGrid/>*/} 
                { 
                    webapps.length === 0 ? <SimpleLoader/> :
                    <WebAppsResultGrid webapps={webapps}/>
                }
                </div>
           
        </div>
        <BackToTopButton/>

</div>  
    
   );
 };


 export default WebAppsLandingPage



             // "process.env.REACT_APP_GRAPHQL" { process.env.REACT_APP_GRAPHQL}
             // "process.env.NODE_ENV" { process.env.NODE_ENV}
             
             // "REACT_APP_ENVIRONMENT:  { process.env.REACT_APP_ENVIRONMENT}

 // <div>
 //        REACT_APP_WEBAPPS_PUBLISH_STATUS:  { process.env.REACT_APP_WEBAPPS_PUBLISH_STATUS}
 //        </div>
 //        <div>
 //        "REACT_APP_ENVIRONMENT:  { process.env.REACT_APP_ENVIRONMENT}
 //        </div>


