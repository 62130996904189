// WebAppsResultGrid.js
import React from 'react';
import { useDispatch } from 'react-redux';
import {selectedWebApp, selectedApp} from '../../store'
import { Link } from 'react-router-dom'




const WebAppsResultGrid = ({ webapps }) => {
 // console.log(webapps);
  //console.log("WebAppsResultGrid);
  const dispatch = useDispatch();
  

  //console.log("WebAppsResultGrid",webapps)

  // const webapps = [
  //   {
  //         id: 1,
  //         name: "App1",
  //         initial_image_url: "https://meeako-bucket.s3.amazonaws.com/nyt-ads-on-search-result-page_1280x800.jpg",
  //         logo_url: "https://via.placeholder.com/150",
  //      },
    
  // ]

  const handleOnClick = (r) => {   
    const webappclicked = r;
    //console.log(webappclicked);
    //console.log("WebAppsResultGrid");
    //dispatch(selectedWebApp(webappclicked))
    dispatch(selectedApp(webappclicked))
    };

    // Truncate function
    function truncate(str, num) {
      if (str.length <= num) {
        return str;
      }
      return str.slice(0, num) + '...';
    }

  return (
   <div className="doubling stackable four column row" >
     {webapps.map((result, i) => (

            <div className="column" key={i} style={{ marginBottom: '1.5em' }}>
                     <Link to={"/web/apps/"+result.id+"/flows"} onClick={()=> handleOnClick(result)}>
                       
                        <img className="ui image webapps-images" src={result.initial_image_url}/>   
                       
                       <div className="ui unstackable items">
                               <div className=" item">
                                   <div className="ui mini image">
                                            
                                          <img  src={result.logo_url}/>   
                                   </div>
                                   <div className="content">
                                          <div className="header">
                                          {truncate(result.name, 20)}
                                    </div>
                                          <div className="extra">
                                                {result.description}
                                          </div>

                                   </div>
                              </div>
                        </div>    
                    </Link>      
                </div>

          ))}
    </div>
  );
};


export default WebAppsResultGrid








