// FlowDetailsHeader.js
import React from 'react';
import { Link } from 'react-router-dom'
//for setting title tage and meta descriptions
import { Helmet } from "react-helmet"; 






const FlowDetailsHeader = ({webApp, flow}) => {

 // console.log(webApp)
 //console.log(flow.name)

 var flowName;

 if (flow.name.includes("/"))
 {
    const parts = flow.name.split("/");
    flowName = parts[1]
 } else 
 {
    flowName = flow.name
 }

 //console.log(flowName)


  return (
  //asdas

  <div>  
            <Helmet>
            <title>{"Admin | "+webApp.name+" Web"}</title>
            <meta name="description" content="a curated collection of the best web and mobile apps" />
            </Helmet>  
                  <div className="pusher">     
                          <div className="ui large  borderless fixed menu">
                            <Link to={"/web/apps/"+webApp.id+"/flows"} className="item">
                               <i className="x icon"></i>
                            </Link>
                                <div className="flow-details-header-center-items">
                                        
                                         <div className="item">
                                                <h3 className="ui header">
                                                <img className="ui  rounded image" 
                                                   src={webApp.logo_url}/>
                                                <div className="content">
                                                {webApp.name}   
                                                        <div className="sub header">{flowName}</div>  
                                                          
                                                </div>
                                                </h3>
                                          </div> 
                                  </div>               
                          </div>
                   </div> 
   </div>                 
  );
};


export default FlowDetailsHeader




// <Link to="/" className="item">
//             <div>Home</div>
//             </Link>


//<Link to="/browse/web/apps" className="item">
//<a className="ui inverted button">Sign Up</a>


{/*
            <a className="item">Pricing</a>
            <div className="right item">
              <a className="ui  button">Log in</a>  
            </div>
            */}

