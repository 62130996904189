// App.js
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { validateToken, logout, validateEmployeeTokenAndReturnEmployee } from './store'

import {BrowserRouter,MemoryRouter, Route, Routes } from 'react-router-dom';
import CleanLandingPage from './components/CleanLandingPage';
import WebAppsLandingPage from './components/WebAppsLandingPage/WebAppsLandingPage';
import IOSLandingPage from './components/IOSLandingPages/IOSLandingPage';
import WebAppsFlowsPage from './components/WebAppDetailPages/WebAppsFlowsPage';
import IOSAppsFlowsPage from './components/iOSAppDetailPages/IOSAppsFlowsPage';
import Header from './components/Header';
import Header2 from './components/Header2';
import SearchBarTopSection from './components/SearchBarTopSection';
import FlowsDetailsLandingPage from './components/WebFlowDetailsPages/FlowDetailsLandingPage'
import IOSFlowsDetailsLandingPage from './components/iOSFlowDetailsPages/IOSFlowDetailsLandingPage'
import NotFound404Page from './components/ErrorPages/NotFound404Page'
import SearchBarHeader from './components/SearchBarHeader/SearchBarHeader'
import WorkingOnItPage from './components/InfoPages/WorkingOnItPage'
import RequestContentPage from './components/RequestContentPages/RequestContentPage'
import EditAppPage from './components/EditApp/EditAppPage'
import AddNewFlowPage from './components/AddNewFlow/AddNewFlowPage'
import EditIOSFlowPage from './components/EditFlow/EditIOSFlowPage'
import EditWebFlowPage from './components/EditFlow/EditWebFlowPage'
import IOSEditScreens from './components/EditScreens/IOSEditScreens'
import WebEditScreens from './components/EditScreens/WebEditScreens'
import LoginWithEmailPassword from './components/LoginWithEmailPass/LoginWithEmailPassword'
import LoginWithFirebaseUI from './components/FirebaseAuthUI/LoginWithFirebaseUI'
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute'
import SimpleLoader from './components/Loaders/SimpleLoader'
import AddNewAppPage from './components/AddNewApp/AddNewAppPage'
import ProfilePage from './components/ProfilePages/ProfilePage'
import AdminPage from './components/AdminPages/AdminPage'
import FooterSection from './components/Footer/FooterSection'
import { SCOPES } from './utils/scopesConstants';



const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {

    console.log("APP LEVEL USEEFFECT")
    // fetch the token from localStorage
    const token = localStorage.getItem('token');
    //console.log("Token?", token)

    // If a token exists, dispatch an action to validate it
     if (token) {
      console.log("token exist")
      //dispatch(validateToken({token}));
      dispatch(validateEmployeeTokenAndReturnEmployee({token}));
     }else{
       dispatch(logout());
     }
  }, [dispatch]);


  const isAuthLoading = useSelector((state) => state.auth.isAuthLoading);
 

 if (isAuthLoading) {
  return (
    <SimpleLoader />
  )
}

  return (
    <div>
    <BrowserRouter>
      
      <Routes>
      <Route path="/login"  element={<LoginWithEmailPassword />}/> 
      <Route path="/" element={<ProtectedRoute><CleanLandingPage /></ProtectedRoute>} />
      <Route path="/browse/web/apps"  element={<ProtectedRoute><WebAppsLandingPage /></ProtectedRoute>}/> //currently the app starts here
      <Route path="/browse/ios/apps"  element={<ProtectedRoute><IOSLandingPage/></ProtectedRoute>}/>
      <Route path="/web/apps/:id/flows"  element={<ProtectedRoute><WebAppsFlowsPage/></ProtectedRoute>}/>
      <Route path="/ios/apps/:appid/flows"  element={<ProtectedRoute><IOSAppsFlowsPage/></ProtectedRoute>}/>
      <Route path="/web/apps/flows/:id"  element={<ProtectedRoute><FlowsDetailsLandingPage/></ProtectedRoute>}/>
      <Route path="/ios/apps/flows/:id"  element={<ProtectedRoute><IOSFlowsDetailsLandingPage/></ProtectedRoute>}/>
      <Route path="/apps/:appid/edit"  element={<ProtectedRoute requiredScopes={[SCOPES.ADMIN]}><EditAppPage/></ProtectedRoute>}/>
      <Route path="/ios/apps/flows/:flowid/editscreens"  element={<ProtectedRoute><IOSEditScreens/></ProtectedRoute>}/>
      <Route path="/web/apps/flows/:flowid/editscreens"  element={<ProtectedRoute><WebEditScreens/></ProtectedRoute>}/>
      <Route path="/ios/apps/:appid/flows/:flowid/editflow"  element={<ProtectedRoute><EditIOSFlowPage/></ProtectedRoute>}/>
      <Route path="/web/apps/:appid/flows/:flowid/editflow"  element={<ProtectedRoute><EditWebFlowPage/></ProtectedRoute>}/>
      <Route path="/apps/:appid/newflow"  element={<ProtectedRoute><AddNewFlowPage/></ProtectedRoute>}/>
      <Route path="/apps/newapp"  element={<ProtectedRoute><AddNewAppPage/></ProtectedRoute>}/>
      <Route path="/profile/*"  element={<ProtectedRoute><ProfilePage/></ProtectedRoute>}/>
      <Route path="/admin/*"  element={<ProtectedRoute requiredScopes={[SCOPES.ADMIN]}><AdminPage/></ProtectedRoute>}/>
     
      <Route path="/onit"  element={<WorkingOnItPage/>}/>
      <Route path="/404"  element={<NotFound404Page/>}/>
      <Route path="*"  element={<NotFound404Page/>}/>
      </Routes> 
    
    </BrowserRouter>

    </div>
  );
};

export default App;

//<Route path="/fblogin"  element={<LoginWithFirebaseUI />}/> 
// <Route path="/profile/password"  element={<ProtectedRoute><PasswordReset/></ProtectedRoute>}/>
