import React, { useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getAuth, EmailAuthProvider, GoogleAuthProvider } from 'firebase/auth';
import { auth as firebaseui } from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';

// Your Firebase configuration object
const firebaseConfig = {
   apiKey: "YOUR_API_KEY",
  authDomain: "YOUR_AUTH_DOMAIN",
  // other config properties
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app); // Get the Auth service for the default app

const FirebaseAuthUI = () => {
  useEffect(() => {
    // FirebaseUI config
    const uiConfig = {
      signInSuccessUrl: '/', // URL to redirect to after a successful sign-in
      signInOptions: [
        //EmailAuthProvider.PROVIDER_ID,
       // GoogleAuthProvider.PROVIDER_ID,
        // Add other providers you plan to use
      ],
      // Other configuration options...
    };

    // Initialize the FirebaseUI Widget using Firebase
    const ui = firebaseui.AuthUI.getInstance() || new firebaseui.AuthUI(auth);

    // The start method will wait until the DOM is loaded
    ui.start('#firebaseui-auth-container', uiConfig);

    // Cleanup the UI on unmount
    return () => ui.delete();
  }, []);

  return <div id="firebaseui-auth-container"></div>;
};

export default FirebaseAuthUI;
