// EditWebFlowPage.js
import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom';
import SearchBarHeader from '../SearchBarHeader/SearchBarHeader'
import AppNameField from './FormFields/AppNameField'
import HTMLTextAreaField from './FormFields/HTMLTextAreaField'
import PublishStatusEditor from './FormFields/PublishStatusEditor'
import PositionEditor from './FormFields/PositionEditor'
import SimpleLoader from '../Loaders/SimpleLoader'
import { fetchUserFlowById } from '../../store'


const EditWebFlowPage = () => {


  const params = useParams();
    const idfromURL = params.appid
    const flowid = params.flowid
    const dispatch = useDispatch();


     useEffect(() => {
      //To make sure a page is scrolled to the top on component load 
    window.scrollTo(0, 0);
    //console.log("fetchAllDetailsOfAppToEditById")
     dispatch(fetchUserFlowById(flowid))
  }, [flowid]);

   // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Construct payload and perform mutation
  };



  const selectedFlow = useSelector((state) => {
    //console.log(state.selectedApp.app.__typename)
        return state.userflows.selectedFlow
  })  

  const publishStatus = useSelector((state) => {
    //console.log(state.selectedApp.app.__typename)
        return state.userflows.selectedFlow.publishStatus
  })

   const description = useSelector((state) => {
    //console.log(state.selectedApp.app.__typename)
        return state.userflows.selectedFlow.description
  })

   const flowname = useSelector((state) => {
    //console.log(state.selectedApp.app.__typename)
        return state.userflows.selectedFlow.name
  }) 

    const position = useSelector((state) => {
    //console.log(state.selectedApp.app.__typename)
        return state.userflows.selectedFlow.position
  }) 



   const appName = useSelector((state) => {
    //console.log(state.selectedApp.app.__typename)
        return state.selectedApp.app.name
  }) 
  
  //console.log(flowid)

  const appType = useSelector((state) => {
    //console.log(state.selectedApp.app.__typename)
        return state.selectedApp.app.__typename
  }) 

  
  const isUserflowUpdating = useSelector((state) => {
    //console.log(state.selectedApp.app.__typename)
        return state.userflows.isUserflowUpdating
  })

document.title = "Edit Flow | "+appName + " "+appType;


//  console.log("shouldDisplay", shouldDisplay)
    if (isUserflowUpdating) {
      return(
        <SimpleLoader/>
        )
    } 
  return (
   <div>
   <SearchBarHeader/> 
   <div className="ui top-section">
   
   <Link to={appType == "iOS" ? "/ios/apps/"+idfromURL+"/flows" : "/web/apps/"+idfromURL+"/flows"}>
            <button className="ui button circular " 
            //onClick={handleBackButton}
            >
            <i className="angle left icon"></i> 
              Back
           </button>
        </Link> 

      

    <div className="ui raised segment">     
      
      <form className="ui form">
      <h1 className="ui dividing header">Edit {appName} {appType} / {flowname} flow</h1>
      
        <div className="field">
                <h3>Type: {appType}</h3>
          </div>
          <div className="ui divider"></div>
          <div className="field">
               <PublishStatusEditor value={publishStatus} appType={appType} flowId={flowid}/>
          </div>     
          <div className="ui divider"></div>
          <div className="field">
              <AppNameField value={flowname} appType={appType} flowId={flowid}/>   
          </div>
          <div className="ui divider"></div>

          <PositionEditor label="Position" value={position} appType={appType} flowId={flowid}/>  
          <div className="ui divider"></div>
          
          <div className="field">
                <HTMLTextAreaField value={description} label="Description" appType={appType} flowId={flowid} gql="description"/>  
          </div>
          
        
          <br/>
            
            
      </form>
      </div>
  </div>
  </div>
  );
};


export default EditWebFlowPage








