// AppMonetizationsField.js
import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AppMonetizationSearchBar from './AppMonetizationSearchBar'
import { updateAppMonetizationsiOSAppByID } from '../../../../store/thunks/iOSAppsThunk'
import { priceRangeList } from '../../../../utils/priceRangeList'
import { transactionFeePriceRangeList } from '../../../../utils/transactionFeePriceRangeList'


const AppMonetizationsField = ({ value = [], appType, appId, label }) => {

  const [currentCategories, setCurrentCategories] = useState(value);
  const [newTermsArr, setNewTermsArr] = useState([]);
  const [isEditing, setIsEditing] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setCurrentCategories(value);
  }, [value]);

  const handleSave = (e) => {
    e.preventDefault()
    const iOsApp = {
      monetizations: currentCategories.map(category => ({
        id: category.id,
        priceRange: category.priceRange
      }))
    };
    //console.log(iOsApp)
    dispatch(updateAppMonetizationsiOSAppByID({ appId, iOsApp }))
    setIsEditing(false); // Exit editing mode
  };

  const handleCancel = () => {
    setCurrentCategories(value);
    setIsEditing(false); // Exit editing mode
  };

  // Function to check if there are any changes
  const hasChanges = () => {
    // If the lengths differ, return true (changes detected)
    // if (currentCategories.length !== value.length) {
    //   return true;
    // }
    const a = JSON.stringify(value) !== JSON.stringify(currentCategories);
    //console.log("hasChanges", a)
    return a;
  };

  //handleAddCategory
  const handleSuggestionClicked = (category) => {
    //console.log("handleSuggestionClicked", category)
    // add priceRange to category
    category.priceRange = []
    //console.log("handleSuggestionClicked", category)
    if (!currentCategories.find(cat => cat.id === category.id)) {
      setCurrentCategories([...currentCategories, category]);
    }
  };

  const handleDeleteCategory = (id) => {
    setCurrentCategories(currentCategories.filter(cat => cat.id !== id));
  };

  const handleSelectChange = (event, cat) => {
    const options = event.target.options;
    const selectedValues = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedValues.push(options[i].value);
      }
    }
    //console.log("handleSelectChange - Selected Values:", selectedValues);

    // Create a new object with the updated priceRange
    const updatedCat = { ...cat, priceRange: selectedValues };
    //console.log("handleSelectChange - Cat:", updatedCat);

    // Update the currentCategories state with the updatedCat
    setCurrentCategories(prevCategories => {
      const index = prevCategories.findIndex(c => c.id === updatedCat.id);
      if (index !== -1) {
        const newCategories = [...prevCategories];
        newCategories[index] = updatedCat;
        return newCategories;
      }
      return prevCategories;
    });

    //console.log("Updated currentCategories:", currentCategories);

  };

  
  if (isEditing) {
    return (
      <div className="ui form">
        <div className="field">
          <h3>
            {label}
            <button className="ui right floated button" onClick={handleCancel}>Cancel</button>
            <button disabled={!hasChanges()} className="ui right floated button" onClick={handleSave}>Save</button>
          </h3>

          <AppMonetizationSearchBar handleSuggestionClicked={handleSuggestionClicked} currentCategories={currentCategories} />

          <p>
            {currentCategories.map(cat => (
              <span key={cat.id} className="ui vertical segment">
                <a key={cat.id} className="ui label">
                  {cat.name + "(" + cat.priceRange + ")"}
                  <i className="delete icon" onClick={() => handleDeleteCategory(cat.id)}></i>
                </a>

                <select multiple className="ui dropdown" style={{ height: '100px' }}
                  value={cat.priceRange}
                  onChange={(event) => handleSelectChange(event, cat)}
                >
                  {(cat.name === "Transaction Fees" ? transactionFeePriceRangeList : priceRangeList).map((priceRange, i) => (
                    <option key={i} value={priceRange}>{priceRange}</option>
                  ))}
                </select>
              </span>
            ))}
          </p>
        </div>

      </div>
    );
  }


  return (
    <div>
      <h3>
        {label}
        <button className="ui right floated button" onClick={() => setIsEditing(true)}>Edit</button>
      </h3>
      <p>
        {currentCategories.map(cat => cat.name + "(" + cat.priceRange + ")").join(', ')}
      </p>
    </div>
  );
};


export default AppMonetizationsField







