// FlowsGridImageRowiOSApp;.js
import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {updateSelectedIOSFlow} from '../../store'





const FlowsGridImageRowiOSApp = ({screens, flowId, flow}) => {
    const dispatch = useDispatch();

  //console.log(screens)
  //console.log(flowId)
  //console.log(flow)

const handleClickButton = () => {
    //trigger flow selection 
   //navigate(-1)
   //dispatch(updateSelectedIOSFlow(flowId))
   dispatch(updateSelectedIOSFlow(flow))
  };

  return (
  //asdas
   <div className="iosapp-flows-horizontal-scrollable-list-container">
    <Link to={"/ios/apps/flows/"+flowId}
    onClick={()=> handleClickButton(flowId)}
    >
    
   <ul className="ios-list">
  
   {
      screens.map((screen, i) => (

            <li key={i}>
                <img src={screen.image.image_url}/>
            </li>
            
          ))
    }
   </ul> 
    
   </Link>
  </div> 
  );
};


export default FlowsGridImageRowiOSApp;







