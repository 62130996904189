// MobileWebTile.js
import React from 'react';
import {useState} from 'react';
import { Link } from 'react-router-dom'

//for setting title tage and meta descriptions
import { Helmet } from "react-helmet"; 


const MobileWebTile = ({active}) => {
    //console.log("MobileWebTile active", active)

    //document.title = "Browse Web Apps | Flowster";
  

  //const [getActive, setActive] = useState("web");
  // const handleOnClick = (r) => {   
  //   const id = r.target.id;
  //      //console.log("ASDASD",id);
  //       setActive(id)   
  //   };

  return (
          <div>  
            <Helmet>
            <title>{active ==="web" ? "Admin | Browse Web Apps": "Admin | Browse iOS Apps"}</title>
            <meta name="description" content="a curated collection of the best web and mobile apps" />
            </Helmet>
            <div className="ui vertical stripe">    
                <div className="ui link horizontal list">
                     <Link to="/browse/ios/apps">
                            <button
                                className={active === "ios" ? " secondary ui  button  circular " : " secondary basic ui  button  circular"}
                            >iOS
                            </button>
                     </Link>
                     <Link to="/browse/web/apps">
                    <button 
                    className={active === "web" ? " secondary ui  button  circular " : " secondary basic  ui  button  circular"}
                     >Web</button>
                     </Link>
                </div>
                
            </div > 
       </div >         

  );
};


export default MobileWebTile




