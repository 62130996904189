import React from "react";

const MonetizationTextComponent = ({ data }) => {
    return (
        <div>
        <p dangerouslySetInnerHTML={{ __html: data }} />
        </div>
    );
    };


export default MonetizationTextComponent;