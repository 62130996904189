    // ScreenAndFlowsTile.js
import React from 'react';
import { Link } from 'react-router-dom'
//import ScreensGridWebApp from './ScreensGridWebApp'
//import FlowsGridWebApp from './FlowsGridWebApp'
import FlowsGridiOSApp from './FlowsGridiOSApp'
import { useLocation } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'


 

const ScreenAndFlowsTile = () => {

  const location = useLocation();
  const params = useParams();

  
   //getting parameter
  const appidfromURL = params.appid

 // Get the full URL of the current route.
  const fullUrl = location.pathname;

  var currentUrl = ''

  if (fullUrl === "/ios/apps/"+appidfromURL+"/flows") {
  // The current route is the `/ios/apps/flows/:id` route.
    //console.log(fullUrl)
    currentUrl='flow'
    }
    else if(fullUrl === "/ios/apps/"+appidfromURL+"/screens") {
        currentUrl='screen'
    }

  //console.log(currentUrl) 
  
  
  return (

            <div className="ui vertical stripe">    
                <div className="ui link horizontal list">
                    
                    {/*
                    <Link 
                        className={currentUrl === "screen" ? "active item" : "disabled item" }
                         
                    >
                        <h2 
                        id="screen"
                        className={currentUrl === "screen" ? "underlined" : null }    
                        >Screens</h2>
                     </Link>
                        
                    */}
                    
                     <Link
                     className={currentUrl === "flow" ? "active item" : "item" }
                     >
                        <h2 
                        className={currentUrl === "flow" ? "underlined" : null }
                        id="flow">
                            User Flows
                        </h2>
                     </Link>
                    
                      
                </div>
                <div className="ui divider"></div>
                <div>
                 {/*currentUrl === "screen" ?  <ScreensGridWebApp/> : null*/}   
                 {currentUrl === "flow" ?    <FlowsGridiOSApp/> : null}    
                </div>

                
               
    </div >    

  );
};


export default ScreenAndFlowsTile





