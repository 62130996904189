// HeaderSearchSuggestionList.js
import React from 'react';
import { useDispatch } from 'react-redux';
import {selectSearchSuggestion, fetchWebAppByName} from '../../store'
import { useNavigate } from 'react-router-dom';




const HeaderSearchSuggestionList = ({suggestions, typeofSearch}) => {
 // console.log(suggestions);
  //console.log("HeaderSearchSuggestionList - typeofSearch", typeofSearch);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //console.log("HeaderSearchSuggestionList",suggestions)

   
  const suggestionClicked = (r) => {   
    const selectedSuggestion = r;
   // console.log(selectedSuggestion);
    //   console.log("HeaderSearchSuggestionList", selectedSuggestion.name);
    //navigate('web/apps/1/flows');
    if(selectedSuggestion.href){
      //dispatch(fetchWebAppByName(selectedSuggestion.name))  
      navigate(selectedSuggestion.href, { replace: false});
    }else{
     // dispatch(fetchWebAppByName(selectedSuggestion.name))
      navigate("/onit")
    }
    dispatch(selectSearchSuggestion(selectedSuggestion.name))
    dispatch(fetchWebAppByName(selectedSuggestion))  


    };

  return (
  <div className="search-result-list">
     {suggestions.map((result, i) => (
            <div className="item" onMouseDown={()=>suggestionClicked(result)} key={i}>
               <div className="header">{result.__typename} </div>
            {result.name}
            </div>
          ))}
    </div>
  );
};


export default HeaderSearchSuggestionList









