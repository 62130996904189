// UIField.js
import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import UISearchBar from './UISearchBar'
import { updateEventTypes as updateIOSEventTypes } from '../../../store/slices/iOSUserFlowSlice'
import { updateEventTypes as updateWebEventTypes } from '../../../store/slices/UserFlowSlice'


const UIField = ({value=[], appType, appId, label, index}) => {

  const [currentEventTypes, setCurrentEventTypes] = useState(value);
  // State to keep track of original data
  const [originalData, setOriginalData] = useState(value);

  //console.log(value)
  const dispatch = useDispatch();

   useEffect(() => {
      setCurrentEventTypes(value);
  }, [value]);

  const areArraysEqualOld = (array1, array2) => {
    if (array1.length !== array2.length) {
        return false;
    }

    const compareObject = (obj1, obj2) => obj1.id === obj2.id && obj1.name === obj2.name;

    const sortedArray1 = array1.sort((a, b) => a.id.localeCompare(b.id));
    const sortedArray2 = array2.sort((a, b) => a.id.localeCompare(b.id));

    return sortedArray1.every((item, index) => compareObject(item, sortedArray2[index]));
};

const areArraysEqual = (array1, array2) => {
    if (array1.length !== array2.length) {
        return false;
    }

    const compareObject = (obj1, obj2) => obj1.id === obj2.id && obj1.name === obj2.name;

    // Make copies of the arrays to sort, to avoid mutating the original arrays
    const sortedArray1 = [...array1].sort((a, b) => a.id.localeCompare(b.id));
    const sortedArray2 = [...array2].sort((a, b) => a.id.localeCompare(b.id));

    return sortedArray1.every((item, index) => compareObject(item, sortedArray2[index]));
};


  //handleAddCategory
  const handleSuggestionClicked = (category) => {
    //console.log("handleSuggestionClicked", category)
     
    //LOGIC TO NOT HAVE DUPLICATE EVENTS
    //  if (!currentEventTypes.find(cat => cat.id === category.id)) {
    //   let updatedEventArr = [...currentEventTypes, category]
    //   //console.log("updatedEventArr",updatedEventArr)
    //   // Check if event types have changed before the state update
    //   let changesDetected = !areArraysEqual(originalData, updatedEventArr)  
    //   //console.log("changesDetected",changesDetected)
    //   // Conditionally dispatch based on appType
    //     if (appType === "iOS") {
    //          // console.log("Dispatching update for iOS");
    //           dispatch(updateIOSEventTypes({id: index, updatedEventTypes: updatedEventArr, hasEventTypesChanged: changesDetected}))
    //     } else if (appType === "android") {
    //         //console.log("Dispatching update for Android");
    //         //dispatch(updateAndroidEventTypes({ screenId: id, screen: updates }));
    //     } 
    // }

    //LOGIC TO HAVE DUPLICATE EVENTS
    let updatedEventArr = [...currentEventTypes, category]
    let changesDetected = !areArraysEqual(originalData, updatedEventArr) 
   
    if (appType === "iOS") {
             // console.log("Dispatching update for iOS");
              dispatch(updateIOSEventTypes({id: index, updatedEventTypes: updatedEventArr, hasEventTypesChanged: changesDetected}))
        } else if (appType === "Web") {
            //console.log("Dispatching update for Android");
            dispatch(updateWebEventTypes({id: index, updatedEventTypes: updatedEventArr, hasEventTypesChanged: changesDetected}))
        }  

  };

   const handleDeleteEvent = (indx) => {
    //console.log("handleDeleteEvent indx", indx)
    const givenIndex = indx
    let updatedEventArrAfterRemoving = currentEventTypes.filter((eventType, index) => index !== givenIndex);

    //let updatedEventArrAfterRemoving = currentEventTypes.filter(eventType => eventType.id !== id);
    let changesDetected = !areArraysEqual(originalData, updatedEventArrAfterRemoving)
    //console.log("changesDetected",changesDetected)
    if (appType === "iOS") {
       dispatch(updateIOSEventTypes({id: index, updatedEventTypes: updatedEventArrAfterRemoving, hasEventTypesChanged: changesDetected}))
    } else if (appType === "Web") {
            //console.log("Dispatching update for Android");
            dispatch(updateWebEventTypes({id: index, updatedEventTypes: updatedEventArrAfterRemoving, hasEventTypesChanged: changesDetected}))
        } 
  };

 //console.log("currentEventTypes",currentEventTypes)

    return (
        <div className="field">
          <h3>
            {label}
          </h3>
          <p>
            {currentEventTypes.map((cat, index)  => (
              <a key={`${cat.id}-${index}`} className="ui black label">
                {cat.name}
                <i className="delete icon" onClick={() => handleDeleteEvent(index)}></i>
              </a>
            ))}
          </p>
          <UISearchBar handleSuggestionClicked={handleSuggestionClicked} currentEventTypes={currentEventTypes} appType={appType}/> 
        </div>
        
    );



 
};


export default UIField







