// AppSearchTermsField.js
import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateSearchTermsByAppId } from '../../../store'


const AppSearchTermsField = ({value, appType, appId, label}) => {

  const oldSearchTerms = value
   //const [currentSearchTerms, setCurrentSearchTerms] = useState("");
   const [currentSearchTerms, setCurrentSearchTerms] = useState(oldSearchTerms);
   const [newTermsArr, setNewTermsArr] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  
  const dispatch = useDispatch();

   useEffect(() => {
      //setCurrentSearchTerms("");
      setCurrentSearchTerms(oldSearchTerms);
  }, [value]);

  const handleSave = (e) => {
    e.preventDefault()
    //console.log("newTermsArr",newTermsArr )
    dispatch(updateSearchTermsByAppId({ appId, searchTerms: newTermsArr }))
    
   // setCurrentSearchTerms(""); // Reset the value to the original  
    setIsEditing(false); // Exit editing mode
  };

  const handleCancel = () => {
    //setCurrentSearchTerms(""); // Reset the value to the original
    setCurrentSearchTerms(oldSearchTerms);
    setIsEditing(false); // Exit editing mode
  };

  // Function to check if there are any changes
  const hasChanges = () => {
    return (
      //currentSearchTerms !== "" //basically not empty
      true
    );
  };

  const handleChange = (e) => {
    const newValue = e.target.value;
    // Convert the comma-separated string back into an array of strings and trim whitespace
    const newArray = newValue.split(',').map(item => item.trim());
    //console.log(newArray)
    setNewTermsArr(newArray)
    setCurrentSearchTerms(newValue);
    
  };


if (isEditing) {
    return (
      <div className="ui form">
        <div className="field">
          <h3>
            {label}
            <button className="ui right floated button" onClick={handleCancel}>Cancel</button>
            <button disabled={!hasChanges()}  className="ui right floated button" onClick={handleSave}>Save</button>
          </h3>
          <p>
            {oldSearchTerms ? oldSearchTerms.join(', ') : ""}
          </p>
          <input type="text" value={currentSearchTerms} placeholder="term1, term2, term3..." onChange={handleChange} />
        </div>
        
      </div>
    );
  }


  return (
   <div>
          <h3>
            {label}
           <button className="ui right floated button" onClick={() => setIsEditing(true)}>Edit</button>
          </h3>
          <p>
            {oldSearchTerms ? oldSearchTerms.join(', ') : ""}
          </p>
  </div>
  );
};


export default AppSearchTermsField







