// UserflowFilterSlider.js
import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { fetchAllUserFlowNamesByAppId, toggleFilter } from '../../store'
import { useParams } from 'react-router-dom';
import SimpleLoader from '../Loaders/SimpleLoader'
import { isProduction } from '../../utils/environment';
import { changeSearchTerm } from '../../store/slices/UserflowFilterSlice'


const UserflowFilterSlider = ({ isOpen, setIsOpen }) => {
const dispatch = useDispatch();



//getting parameter
    const params = useParams();
    const idfromURL = params.id
   //console.log("UserflowFilterSlider idfromURL", idfromURL)


const userflows = useSelector((state) => {
          return state.userflowsfilter.filteredUserFlows
       }) 
//console.log("userflows", userflows)

const searchTerm = useSelector((state) => {
      return state.userflowsfilter.searchTerm
   })


const filters = useSelector(state => state.userflowsfilter.filters);
//console.log("filters", filters)


const isAnyFlowChecked = useSelector(state => state.userflowsfilter.isAnyFlowChecked);
//console.log("isAnyFlowChecked", isAnyFlowChecked)

 const appId = useSelector((state) => {
          return state.userflowsfilter.appId
       })  
//console.log("appId", appId)

 const isLoading = useSelector((state) => {
          return state.userflowsfilter.isLoading
       })  
//console.log("isLoading", isLoading)

//  const webApp = useSelector((state) => {
//   //console.log(state.webapps.selectedWebApp)
//   return state.webapps.selectedWebApp
// })

 const webApp = useSelector((state) => {
  //console.log(state.selectedApp.app)
  return state.selectedApp.app
})
//console.log("webApp", webApp)

//const appId = webApp.id

useEffect(() => {

    if(appId !== idfromURL){
     // console.log("FETCHING USER FLOWS NAMES BY WEB APP ID")
      dispatch(fetchAllUserFlowNamesByAppId(idfromURL))
   } 
    if(!isProduction){
     dispatch(fetchAllUserFlowNamesByAppId(idfromURL))   
    }
  }, [idfromURL]);

 

const handleCloseSlider = (r) => {   
        const id = r.target.id;
         setIsOpen(false)
    };  

const handleCheckboxChange = (userFlowId) => {
        dispatch(toggleFilter(userFlowId));
    };  

const handleSearchTermChange = (event) => { 
    const newTerm = event.target.value;
      dispatch(changeSearchTerm(newTerm))
    };      

return (
    <div className="ui vertical right visible sidebar menu ">
                  <div>
                      <a className="item">
                          <i className=" close icon"
                          onClick={(e) => {
                                        e.stopPropagation(); // This stops the click event from propagating to the button
                                        handleCloseSlider(e);
                                    }}
                          ></i>
                          <p>{webApp.name}'s Flows</p>
                      </a>
                  </div> 
                     <div className="ui divider"></div>

                     <div id="search" className="ui search ">
                     <div className="ui icon input">
                       <input className=" prompt search-bar-for-flows-dropdown" 
                       type="text" 
                       style = {{color: "black"}} 
                       placeholder={"Search "+webApp.name+ " flows..."}  
                       value={searchTerm}
                       onChange={handleSearchTermChange}
                       //onBlur={handleBlur}
                       />
                          <i className="search icon"></i>
                      
                    </div>
                    </div>
                    <div className="ui divider"></div>

                     {
                        isLoading? 
                        <SimpleLoader/> : 
                          userflows.map(userflow => (
                            
                            <div className="item" key={userflow.id}>
                               <div className="ui checkbox">
                                  <input 
                                  type="checkbox" 
                                  name="example"
                                  checked={filters[userflow.id] || false}
                                  onChange={() => handleCheckboxChange(userflow.id)}

                                  />
                                  <label>{userflow.name+" #"+userflow.position}</label>
                                </div>
                             </div>
                            
                        ))
                        

                     }
                  
  </div> 
  );
};


export default UserflowFilterSlider






