//iOSUserFlowSlice.js

import { createSlice } from '@reduxjs/toolkit'
import { fetchiOSUserFlowByAppId, fetchIOSUserFlowById, updateUserflowByID, addScreensObjsToUserflow , updateUserFlowScreens} from '../thunks/userFlowThunk'
import { updateIOSScreen } from '../thunks/updateScreenThunk'
const KEYS = require('../../config/keys')
import { v4 as uuidv4 } from 'uuid';

const iOSUserFlowSlice = createSlice({
	name:"iosuserflow",
	initialState: {
		hasMore: false,
		appId: '',
		count: '',
		limit: 3,
		skip: 0,
		userFlowsByAppId: [],
		selectedFlow: {}, //{userFlowByID: from api}
		isUserflowUpdating: false,
		pendingScreenUpdates: []
	},
	reducers: {
		updateSelectedIOSFlow: (state, action) => {
			state.selectedFlow = action.payload
		},
		setIsUserFlowUpdating: (state, action) => {
			state.isUserflowUpdating = action.payload
		},
		resetIOSUserFlowsByAppId: (state, action) => {
			state.userFlowsByAppId = [];
			state.hasMore = false
			state.skip = 0
		},
		clearPendingScreenUpdates: (state, action) => {
			//console.log("clearPendingScreenUpdates action", action)
			state.pendingScreenUpdates = [];
		},
		// For adding new screens in a userflow
		insertAtPosition: (state, action) => {
			//console.log("insertAtPosition action", action)
			const { index, prefixImagefileNametoUseWhenUploading } = action.payload;
      		
      		// Add the new item with a isNew flag
      		console.log(index)
      		const newScreen = {
      				  lid: uuidv4(), // Local unique ID generated using uuid  	
					  name: '',
					  description: '',
					  position: index,
					  event_types:[],
					  prefixImagefileNametoUseWhenUploading: prefixImagefileNametoUseWhenUploading,
					  image: {
					    id: '',
					    image_url: KEYS.ADD_NEW_IMAGE_PLACEHOLDER_PATH
					  },
					  isNew: true // Flag to indicate this is a new item
					};
      		state.selectedFlow.screens.screen.splice(index, 0, newScreen);
      		
    	},
    	//NOTE:just remove new screens (not exisiting)
    	removeAtPosition: (state, action) => {
			//console.log("removeAtPosition action", action)
      		const index  = action.payload;
      		state.selectedFlow.screens.screen.splice(index, 1);
    	},

    	addNewScreens: (state, action) => {
			//console.log("addNewScreens action", action)
      		const newScreens  = action.payload;

      			// Append the new screens to the existing ones
			  // The spread operator (...) is used to expand the existing screens array
			  // and the newScreens array into a new array
			  state.selectedFlow.screens.screen = [
			    ...state.selectedFlow.screens.screen,
			    ...newScreens
			  ];

			   // Update the count to reflect the total number of screens
  				state.selectedFlow.screens.count = state.selectedFlow.screens.screen.length;
      		// state.selectedFlow.screens = {
      		// 								screen: newScreens,
      		// 								count: newScreens.length
      		// 							}	
    	},
    	updateImageFromInputFeild: (state, action) => {
			//console.log("updateImageFromInputFeild action", action)
			const { index, filename, imageUrl, fileSize, formattedName  } = action.payload;
			
			const screenObj = state.selectedFlow.screens.screen[index];
			if(screenObj.isNew){
    			screenObj.fileSize = fileSize
    			screenObj.formattedName = formattedName
    			screenObj.filename = filename
    			screenObj.shouldUploadImage= true, 
    			screenObj.image = {
    					id: '',
    					image_url: imageUrl
    				}
    		}
      			
    	},
    	updateImageURLFromTextFeild: (state, action) => {
			//console.log("updateImageURLFromTextFeild action", action)
			const { index, newURL } = action.payload;
			
			const screenObj = state.selectedFlow.screens.screen[index];
			if(screenObj.isNew){
    			screenObj.shouldUploadImage= false, 
    			screenObj.image = {
    					id: '',
    					image_url: newURL
    				}
    		}
      			
    	},
    	updateScreenName: (state, action) => {
    		//console.log("updateScreenName action", action)
    		const { id, newName, hasNameChanged } = action.payload;
    		const screenObj = state.selectedFlow.screens.screen[id];
    		if(screenObj.isNew){
    			//console.log("new screen")
    			screenObj.name = newName
    		} else if (screenObj.id){
    			//console.log("existing screen")	
    			const id = screenObj.id
    			screenObj.name = newName
    			// Find existing pending update for the screen
   				const updateIndex = state.pendingScreenUpdates.findIndex(update => update.id === id);
    			const existingUpdate = updateIndex !== -1 ? state.pendingScreenUpdates[updateIndex] : null;

    			 if (hasNameChanged) {
					        // If name has changed, update the pendingScreenUpdates
					        if (existingUpdate) {
					            // Update existing entry
					            existingUpdate.name = newName;
					        } else {
					            // Add new entry
					            state.pendingScreenUpdates.push({ id, name: newName });
					            //screenObj.isUpdated = true
					        }
					    } else if (existingUpdate && existingUpdate.name) {
					        // If name hasn't changed, remove the name property from the pending updates if it exists
					        delete existingUpdate.name;

					        // If the update object is now empty, remove it from the array
					        if (Object.keys(existingUpdate).length <= 1) { // Assuming 'id' is the only other key
					            state.pendingScreenUpdates.splice(updateIndex, 1);
					            //screenObj.isUpdated = false
					        }
					    }
			} 

    	},
    	updateDescription: (state, action) => {
    		//console.log("updateDescription action", action)
    		const { id, newDescription, hasDescriptionChanged } = action.payload;
    		const screenObj = state.selectedFlow.screens.screen[id];
    		if(screenObj.isNew){
    			screenObj.description = newDescription
    		} else if (screenObj.id){
    			//console.log("existing screen")	
    			const id = screenObj.id
    			screenObj.description = newDescription
    			// Find existing pending update for the screen
   				const updateIndex = state.pendingScreenUpdates.findIndex(update => update.id === id);
    			const existingUpdate = updateIndex !== -1 ? state.pendingScreenUpdates[updateIndex] : null;

    			 if (hasDescriptionChanged) {
					        // If description has changed, update the pendingScreenUpdates
					        if (existingUpdate) {
					            // Update existing entry
					            existingUpdate.description = newDescription;
					        } else {
					            // Add new entry
					            state.pendingScreenUpdates.push({ id, description: newDescription });
					            //screenObj.isUpdated = true
					        }
					    } else if (existingUpdate && existingUpdate.description) {
					        // If description hasn't changed, remove the description property from the pending updates if it exists
					        delete existingUpdate.description;

					        // If the update object is now empty, remove it from the array
					        if (Object.keys(existingUpdate).length <= 1) { // Assuming 'id' is the only other key
					            state.pendingScreenUpdates.splice(updateIndex, 1);
					            //screenObj.isUpdated = false
					        }
					    }
			} 

    	},

    	updatePosition: (state, action) => {
    		//console.log("updatePosition action", action)
    		const { id, newPosition, hasPositionChanged } = action.payload;
    		const screenObj = state.selectedFlow.screens.screen[id];
    		if(screenObj.isNew){
    			screenObj.position = newPosition
    		} else if (screenObj.id){
    			//console.log("existing screen")	
    			const id = screenObj.id
    			screenObj.position = newPosition
    			// Find existing pending update for the screen
   				const updateIndex = state.pendingScreenUpdates.findIndex(update => update.id === id);
    			const existingUpdate = updateIndex !== -1 ? state.pendingScreenUpdates[updateIndex] : null;

    			 if (hasPositionChanged) {
					        // If position has changed, update the pendingScreenUpdates
					        if (existingUpdate) {
					            // Update existing entry
					            existingUpdate.position = newPosition;
					        } else {
					            // Add new entry
					            state.pendingScreenUpdates.push({ id, position: newPosition });
					            //screenObj.isUpdated = true
					        }
					    } else if (existingUpdate && existingUpdate.position) {
					        // If position hasn't changed, remove the position property from the pending updates if it exists
					        delete existingUpdate.position;

					        // If the update object is now empty, remove it from the array
					        if (Object.keys(existingUpdate).length <= 1) { // Assuming 'id' is the only other key
					            state.pendingScreenUpdates.splice(updateIndex, 1);
					            //screenObj.isUpdated = false
					        }
					    }
			} 

    	},

    	updateEventTypes: (state, action) => {
			   // console.log("updateEventTypes action", action)
			    const { id, updatedEventTypes, hasEventTypesChanged } = action.payload;
			    const screenObj = state.selectedFlow.screens.screen[id];

			    if (screenObj.isNew) {
			        // For new screens, just set the event types directly
			        screenObj.event_types = updatedEventTypes;
			    } else if (screenObj.id) {
			        //console.log("existing screen")
			        const id = screenObj.id;
			        screenObj.event_types = updatedEventTypes;

			        // Manage pendingScreenUpdates
	        		const updateIndex = state.pendingScreenUpdates.findIndex(update => update.id === id);
	        		const existingUpdate = updateIndex !== -1 ? state.pendingScreenUpdates[updateIndex] : null;

	        		if (hasEventTypesChanged){
	        			// If event has changed, update the pendingScreenUpdates
	        			if (existingUpdate) {
			                // Update existing entry
			                existingUpdate.event_types = updatedEventTypes;
			            } else {
			                // Add new entry
			                state.pendingScreenUpdates.push({ id, event_types: updatedEventTypes });
			                //screenObj.isUpdated = true
			            }

	        	} else if (existingUpdate && existingUpdate.event_types) {
			            // If event_types haven't changed, remove the event_types property from the pending updates if it exists
			            delete existingUpdate.event_types;

			            // If the update object is now empty, remove it from the array
			            if (Object.keys(existingUpdate).length <= 1) { // Assuming 'id' is the only other key
			                state.pendingScreenUpdates.splice(updateIndex, 1);
			                //screenObj.isUpdated = false
			            }
        		}
			       
			    }
			},





	},
	extraReducers(builder){
		builder.addCase(fetchiOSUserFlowByAppId.fulfilled, (state, action) => {
     	//console.log("slice",action.payload.data.userFlowByAppId.userflow)
		state.skip = action.meta.arg.skip,
		state.hasMore = action.payload.data.userFlowByAppId.hasMore,
		state.appId = action.meta.arg.idfromURL,
		state.count = action.payload.data.userFlowByAppId.count,
		state.userFlowsByAppId = state.userFlowsByAppId.concat(action.payload.data.userFlowByAppId.userflow)
    });
	
	builder.addCase(fetchIOSUserFlowById.fulfilled, (state, action) => {
     //console.log("fetchIOSUserFlowById", action.payload.data.userFlowId)
     state.selectedFlow = action.payload.data.userFlowId
    });
    
    builder.addCase(updateUserflowByID.pending, (state, action) => {
     state.isUserflowUpdating = true
    });	
	
	builder.addCase(updateUserflowByID.fulfilled, (state, action) => {
     //console.log("updateUserflowByID", action.payload.data.updateUserflowByID)
     
     const { app, ...updatedUserFlowWithoutApp } = action.payload.data.updateUserflowByID
     const index = state.userFlowsByAppId.findIndex((flow) => flow.id === updatedUserFlowWithoutApp.id);

		     if (index !== -1) {
		     	// If the user flow is found, update its properties
		     	state.userFlowsByAppId[index] = {
		     		 	...state.userFlowsByAppId[index], // Spread the existing properties
          				...updatedUserFlowWithoutApp, // Overwrite with updated properties
		     	}
		     }
     	state.selectedFlow = updatedUserFlowWithoutApp
     	state.isUserflowUpdating = false
    });	

	builder.addCase(updateIOSScreen.pending, (state, action) => {
     state.isUserflowUpdating = true
    });

    builder.addCase(updateIOSScreen.fulfilled, (state, action) => {
     
		     //console.log("updateIOSScreen", action.payload.data.updateScreen)
		     const updatedScreen = action.payload.data.updateScreen

		     const updateIndex = state.pendingScreenUpdates.findIndex(update => update.id === updatedScreen.id);	
		     const index = state.selectedFlow.screens.screen.findIndex((screen) => screen.id === updatedScreen.id);
				     
				     if (index !== -1) {
				     	// If the screen is found, update its properties
				     	state.selectedFlow.screens.screen[index] = {
				     		...state.selectedFlow.screens.screen[index],
				     		...updatedScreen
				     	}
				     }
			     	
			     	if (updateIndex !== -1) {
					     	// If the screen is found, delete
					     	 state.pendingScreenUpdates.splice(updateIndex, 1);
					     }
					

				// Find the index of the flow in userFlowsByAppId that matches the selectedFlow's id
				//and update the flow 	     
      			const flowindex = state.userFlowsByAppId.findIndex(flow => flow.id === state.selectedFlow.id);     
      			if (flowindex !== -1) {
				        // If found, update that flow in the array
				        state.userFlowsByAppId[flowindex] = state.selectedFlow
				      } 
				 state.isUserflowUpdating = false	     
    });

    builder.addCase(addScreensObjsToUserflow.pending, (state, action) => {
     state.isUserflowUpdating = true
    });


    builder.addCase(addScreensObjsToUserflow.fulfilled, (state, action) => {
    	//console.log("newscreens", action.payload.data.addScreensObjsToUserflow)
    	const { id, screens } = action.payload.data.addScreensObjsToUserflow;

    	if (state.selectedFlow.id === id){
    		state.selectedFlow.screens = screens

    		// Find the index of the flow in userFlowsByAppId that matches the selectedFlow's id
				//and update the flow 	     
      			const flowindex = state.userFlowsByAppId.findIndex(flow => flow.id === state.selectedFlow.id);     
      			if (flowindex !== -1) {
				        // If found, update that flow in the array
				        state.userFlowsByAppId[flowindex] = state.selectedFlow
				      } 
    	}
     
     	state.isUserflowUpdating = false
    });
	builder.addCase(updateUserFlowScreens.pending, (state, action) => {
	 state.isUserflowUpdating = true
	});
	builder.addCase(updateUserFlowScreens.fulfilled, (state, action) => {	
		//console.log("updateUserFlowScreens", action.payload.data.updateUserFlowScreens)
		const { id, userInteractionsCount, screens } = action.payload.data.updateUserFlowScreens;
		if (state.selectedFlow.id === id){
			state.selectedFlow.screens = screens

			// Find the index of the flow in userFlowsByAppId that matches the selectedFlow's id
				//and update the flow 	     
				  const flowindex = state.userFlowsByAppId.findIndex(flow => flow.id === state.selectedFlow.id);     
				  if (flowindex !== -1) {
						// If found, update that flow in the array
						state.userFlowsByAppId[flowindex] = {
							...state.userFlowsByAppId[flowindex],
							userInteractionsCount: userInteractionsCount				        
							
						}
					  } 
			state.pendingScreenUpdates = []
			state.isUserflowUpdating = false		  
		}
	}); 
		

  }
})


export const { updateSelectedIOSFlow, resetIOSUserFlowsByAppId, insertAtPosition, 
			removeAtPosition, addNewScreens, updateScreenName, updateImageFromInputFeild, 
			updateDescription, updateImageURLFromTextFeild, clearPendingScreenUpdates,
			updatePosition, setIsUserFlowUpdating, updateEventTypes } = iOSUserFlowSlice.actions;
export const iOSFlowReducer = iOSUserFlowSlice.reducer;