// AddNewFlowPage.js
import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom';
import SearchBarHeader from '../SearchBarHeader/SearchBarHeader'

import SimpleLoader from '../Loaders/SimpleLoader'

import { fetchAllDetailsOfAppToEditById, createUserflowWithoutScreens } from '../../store'


const AddNewFlowPage = () => {

  const params = useParams();
  const idfromURL = params.appid
  const dispatch = useDispatch();

  const [flowname, setFlowName] = useState("");
  const [description, setDescription] = useState("");
  const [publishStatus, setPublishStatus] = useState("draft");
  const [position, setPosition] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isTry, setIsTry] = useState(false);

  // State to keep track of original data
  const [originalData, setOriginalData] = useState({
                    flowname: "",
                    description: "",
                    publishStatus: "draft",
                    position: ""
                  });

   // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("handleSubmit")
    // Construct payload and perform mutation

      const userflow = {
        name: flowname,
        appId: idfromURL,
        description: description,
        position: position,
        publishStatus:publishStatus
      };
      //console.log(userflow)
      dispatch(createUserflowWithoutScreens({userflow})).unwrap().then((response) => {
        //console.log("new form",response.data.createUserflowWithoutScreens)
        const newflow = response.data.createUserflowWithoutScreens
                setSuccessMessage(`New user flow ${newflow.name} ID# ${newflow.id} created`);
        setFlowName(originalData.flowname)
        setDescription(originalData.description)
        setPosition(originalData.position)
        setPublishStatus(originalData.publishStatus);
       }).catch((error) => {
      // Handle any errors here
      // You might want to show an error message
    });
  };


  useEffect(() => {
    //console.log("fetchAllDetailsOfAppToEditById")
     dispatch(fetchAllDetailsOfAppToEditById({ appId: idfromURL }))
  }, [idfromURL]);


  const appType = useSelector((state) => {
    //console.log(state.selectedApp.app.__typename)
        return state.selectedApp.app.__typename
  }) 

  const appName = useSelector((state) => {
    //console.log(state.selectedApp.app.__typename)
        return state.selectedApp.app.name
  }) 

  const isUpdating = useSelector((state) => {
    //console.log(state.selectedApp.app.__typename)
        return state.selectedApp.isUpdating
  })

document.title = "New Flow | "+appName + " "+appType;


 const handlePublishStatusChange = (e) => {
    setPublishStatus(e.target.value);
  };


  const handlePositionChange = (e) => {
      const inputValue = e.target.value; // This is a string
      const numericValue = parseInt(inputValue); // Convert to number
      setPosition(numericValue)
  }


  const toggleTry = () => {
    setIsTry(!isTry); // Toggle the isTry state
  };

  // Function to check if there are any changes
  const hasChanges = () => {
    return (
      description !== originalData.description ||
      flowname !== originalData.flowname ||
      publishStatus !== originalData.publishStatus ||
      position !== originalData.position
    );
  };

 const handleClear = (e) => {
      e.preventDefault();
      setFlowName(originalData.flowname)
      setDescription(originalData.description)
      setPosition(originalData.position)
      setPublishStatus(originalData.publishStatus);
  };


//console.log(originalData)


//  console.log("shouldDisplay", shouldDisplay)
   
  return (
   <div>
   <SearchBarHeader/> 
   <div className="ui top-section">
   
   <Link to={appType == "iOS" ? "/ios/apps/"+idfromURL+"/flows" : "/web/apps/"+idfromURL+"/flows"}>
            <button className="ui button circular " 
            //onClick={handleBackButton}
            >
            <i className="angle left icon"></i> 
              Back
           </button>
        </Link>   
        <h1>Add new flow under {appName} {appType} app</h1>  
      {  successMessage && (
            <div className="ui positive message">
              <i className="close icon"></i>
              <div className="header">
                Success
              </div>
              <p>{successMessage}</p>
            </div>
        )}     

      <form className={isUpdating ? "ui loading form" : "ui form"}>
          <div className="field">
              <h2>
               Flow Name
              </h2>
                <input type="text" value={flowname} onChange={(e) => setFlowName(e.target.value)} />
          </div>
          <div className="ui divider"></div>

          <div className="field">
                    <h2>
                     Publish Status
                    </h2>

                      <div className="inline fields">
                      <div className="field">
                        <div className="ui radio checkbox">
                          <input type="radio" value="published" checked={publishStatus === 'published'} onChange={handlePublishStatusChange} />
                          <label>published</label>
                        </div>
                       </div>
                       <div className="field"> 
                          <div className="ui radio checkbox">
                            <input type="radio" value="draft" checked={publishStatus === 'draft'} onChange={handlePublishStatusChange} />
                            <label>draft</label>
                          </div>
                       </div>
                    </div>  
          </div>
          <div className="ui divider"></div>

                <div className="field">
                  <h2>
                   Position 
                  </h2>
                    <input type="number" placeholder="numbers only" value={position} onChange={handlePositionChange} />
                </div>
          <div className="ui divider"></div> 

          <div className="field">
          <h2>
            Description
          </h2>

          {
            isTry ? 
              (
                <p dangerouslySetInnerHTML={{ __html: description }} /> 
              )
              :
              (
                   <textarea
                rows="4" 
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />

              )

          }

              <div className="ui toggle checkbox">
                <input type="checkbox"
                checked={isTry}
                onChange={toggleTry} 
                 />
                <label>In HTML</label>
              </div>
        </div>

        <div className="ui divider"></div> 
        <div className="field">
          <button disabled={!hasChanges()} className="ui teal right floated button" onClick={handleSubmit}>Create New Flow</button>
          <button data-tooltip="Clear all the form fields" className="ui right floated button" onClick={handleClear}>Clear</button>
        </div>
            
          
      </form>
  </div>
  </div>
  );
};


export default AddNewFlowPage








