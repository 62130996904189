const priceRangeList = [
   "",
    "Free",
   "$0 - $9.99",
   "$10 - $49.99",
   "$50 - $99.99",
   "$100 - $499.99", 
   "$500 - $999.99",
   "$1000 - $4999",
  ]
  
  
  module.exports = { priceRangeList };