// AppCategorySearchSuggestionList.js
import React from 'react';




const AppCategorySearchSuggestionList = ({suggestions, handleSuggestionClicked,setSearchTerm}) => {


   
  const suggestionClicked = (r) => {   
    const selectedSuggestion = r;
    handleSuggestionClicked(selectedSuggestion);
    setSearchTerm("")
};

  return (
  <div className="search-result-list">
     {suggestions.map((result, i) => (
            <div className="item" onMouseDown={()=>suggestionClicked(result)} key={i}>
               <div className="header">{result.__typename} </div>
            {result.name}
            </div>
          ))}
    </div>
  );
};


export default AppCategorySearchSuggestionList









