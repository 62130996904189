// SearchSuggestionList.js
import React from 'react';
import { useDispatch } from 'react-redux';
import {selectSearchSuggestion} from '../store'




const SearchSuggestionList = ({suggestions, typeofSearch}) => {
 // console.log(suggestions);
  //console.log("SearchSuggestionList - typeofSearch", typeofSearch);
  const dispatch = useDispatch();

  //console.log("SearchSuggestionList",suggestions)

   
  const suggestionClicked = (r) => {   
    const selectedSuggestion = r;
    //console.log(selectedSuggestion);
    console.log("SearchSuggestionList - typeofSearch", typeofSearch);
    dispatch(selectSearchSuggestion(selectedSuggestion))
    };

  return (
  <div className="search-result-list">
     {suggestions.map((result, i) => (
            <div className="item" onMouseDown={()=>suggestionClicked(result.name)} key={i}>
               <div className="header">{result.__typename} </div>
            {result.name}
            </div>
          ))}
    </div>
  );
};


export default SearchSuggestionList



// <div className="item" onMouseDown={()=>suggestionClicked(result.name)} key={i}>
//               <div className="header">App </div>
//             {result.name} 
//             </div>








