import { createAsyncThunk } from '@reduxjs/toolkit';
const KEYS = require('../../config/keys')


const searchAppMonetizations = createAsyncThunk('searchAppMonetizations/fetch', async(searchTerm ) => {
 // console.log("searchAppMonetizations thunk", searchTerm)
 
  
  // return fetch(KEYS.GRAPHQL_URL, {
  //     method: 'POST',
  //     headers: { "Content-Type": "application/json" },
  //     body: JSON.stringify({
  //         variables: {
  //         searchTerm
  //       },
  //         query: `
  //         query SearchAppCategories($searchTerm: String) {
  //         searchAppMonetizations(searchTerm: $searchTerm) {
  //           name
  //           id
  //         }
  //       }
  //       `
  //     })
  // }).then(res => res.json())


  return monetizations.filter(monetization => 
        monetization.name.toLowerCase().includes(searchTerm.toLowerCase())
    );


})

export { searchAppMonetizations }


const monetizations = [
    {
        "id": "668eec291b3f85ac9aa2b3f7",
        "name": "Subscription"
      },
      {
        "id": "668eec8d1b3f85ac9aa2b3f8",
        "name": "Freemium"
      },
      {
        "id": "668eec9c1b3f85ac9aa2b3f9",
        "name": "One-Time Purchase"
      },
      {
        "id": "668eeca61b3f85ac9aa2b3fa",
        "name": "Advertising"
      },
      {
        "id": "668eecb11b3f85ac9aa2b3fb",
        "name": "Pay-Per-Use"
      },
      {
        "id": "668eecbb1b3f85ac9aa2b3fc",
        "name": "Licensing"
      },
      {
        "id": "668eecc81b3f85ac9aa2b3fd",
        "name": "Transaction Fees"
      },
      {
        "id": "668eecd31b3f85ac9aa2b3fe",
        "name": "Affiliate Marketing"
      },
      {
        "id": "668eecdc1b3f85ac9aa2b3ff",
        "name": "Sponsorship"
      },
      {
        "id": "668eece81b3f85ac9aa2b400",
        "name": "Data Monetization"
      },
      {
        "id": "668eecfd1b3f85ac9aa2b402",
        "name": "Crowdfunding"
      },
      {
        "id": "668eed6d1b3f85ac9aa2b403",
        "name": "In-App Purchases"
      }
      ]
