
import React from 'react';

const ProductTextComponent = ({ data }) => {
    //console.log("ProductTextComponent prod", data)
  return (
    <div >
      <p dangerouslySetInnerHTML={{ __html: data }} />
    </div>
  );
};

export default ProductTextComponent;