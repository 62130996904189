import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { SCOPES } from '../../utils/scopesConstants';
import { hasAnyScope } from '../../utils/scopes';

const ProtectedRoute = ({ children, requiredScopes = [] }) => {
   const location = useLocation();
  // Use useSelector to access isAuthenticated from the auth slice
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  console.log("isAuthenticated", isAuthenticated)
  
  const employee = useSelector((state) => state.auth.employee);

  if (!isAuthenticated) {
    // Redirect to login if the user is not authenticated
    //return <Navigate to="/login" replace />;
    // Redirect to login and pass current location in state
    return <Navigate to="/login" state={{ from: location }} replace/>;
  } 

  if (requiredScopes.length > 0 && !hasAnyScope(employee.scope, requiredScopes)) {
    // Redirect to unauthorized page if the user does not have the required scopes
    return <Navigate to="/404" replace />;
  }

  // Render children components if the user is authenticated
  return children;
};

export default ProtectedRoute;
