// WorkingOnItPage.js
import React from 'react';
import SearchBarHeader from '../SearchBarHeader/SearchBarHeader'


const WorkingOnItPage = () => {
  return (
  //asdas
    <div>
    <SearchBarHeader/>
   <div className="info-page"> 
   <div className="ui vertical masthead center aligned segment">
     <h1 className="ui header centered" >On it!</h1>
       
       <iframe src="https://giphy.com/embed/UFGj6EYw5JhMQ" width="480" height="273" frameBorder="0" className="giphy-embed" allowFullScreen></iframe><p><a href="https://giphy.com/gifs/UFGj6EYw5JhMQ">via GIPHY</a></p>
  </div> 
  </div>
  </div>
  );
};


export default WorkingOnItPage







