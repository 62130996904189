// EditAppPage.js
import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom';
import SearchBarHeader from '../SearchBarHeader/SearchBarHeader'
import AppNameField from './FormFields/AppNameField'
import HTMLTextAreaField from './FormFields/HTMLTextAreaField'
import PublishStatusEditor from './FormFields/PublishStatusEditor'
import ShouldDisplayEditor from './FormFields/ShouldDisplayEditor'
import SimpleLoader from '../Loaders/SimpleLoader'
import ImagePickerEditor from './FormFields/ImagePickerEditor'
import AppDescriptionHTMLTextAreaField from './FormFields/AppDescriptionHTMLTextAreaField'
import AppSearchTermsField from './FormFields/AppSearchTermsField'
import AppCategoriesField from './FormFields/AppCategorySearchAndSelect/AppCategoriesField'
import { fetchAllDetailsOfAppToEditById } from '../../store'
import AppCompetitorsField from './FormFields/AppCompetitorsSearchAndSelect/AppCompetitorsField'
import AppMonetizationsField from './FormFields/AppMonetizationSearchAndSelect/AppMonetizationsField'

const EditAppPage = () => {


  const params = useParams();
    const idfromURL = params.appid
    const dispatch = useDispatch();


     useEffect(() => {
      //To make sure a page is scrolled to the top on component load 
    window.scrollTo(0, 0);
    //console.log("fetchAllDetailsOfAppToEditById")
     dispatch(fetchAllDetailsOfAppToEditById({ appId: idfromURL }))
  }, []);

   // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Construct payload and perform mutation
  };

  const productText = useSelector((state) => {
    //console.log(state.selectedApp.productInsight)
        return state.selectedApp.productInsight.productText
  }) 

  const valueText = useSelector((state) => {
    //console.log(state.selectedApp.productInsight)
        return state.selectedApp.productInsight.valueText
  }) 

  const userText = useSelector((state) => {
    //console.log(state.selectedApp.productInsight)
        return state.selectedApp.productInsight.userSegmentText
  })

  const shouldDisplay = useSelector((state) => {
    //console.log(state.selectedApp.productInsight)
        return state.selectedApp.productInsight.shouldDisplay
  }) 

  const monetizationText = useSelector((state) => {
    //console.log(state.selectedApp.productInsight)
        return state.selectedApp.productInsight.monetizationText
  }) 

  const competitorText = useSelector((state) => {
    //console.log(state.selectedApp.productInsight)
        return state.selectedApp.productInsight.competitorText
  })  

  const userSegmentText = useSelector((state) => {
    //console.log(state.selectedApp.productInsight)
        return state.selectedApp.productInsight.userSegmentText
  }) 

  const missionText = useSelector((state) => {
    //console.log(state.selectedApp.productInsight)
        return state.selectedApp.productInsight.missionText
  }) 
 


  const appType = useSelector((state) => {
    //console.log(state.selectedApp.app.__typename)
        return state.selectedApp.app.__typename
  }) 

  const appName = useSelector((state) => {
    //console.log(state.selectedApp.app.__typename)
        return state.selectedApp.app.name
  }) 

  const appDescription = useSelector((state) => {
    //console.log(state.selectedApp.app.__typename)
        return state.selectedApp.app.description
  }) 

  const appSearchTerms = useSelector((state) => {
    //console.log(state.selectedApp.app.__typename)
        return state.selectedApp.app.searchTerms
  }) 

  const appCateogries = useSelector((state) => {
    //console.log(state.selectedApp.app.__typename)
        return state.selectedApp.app.categories
  }) 

  const appMonetizations = useSelector((state) => {
    //console.log(state.selectedApp.app.__typename)
        return state.selectedApp.app.monetizations
  })

  const appCompetitors = useSelector((state) => {
    //console.log(state.selectedApp.app.__typename)
        return state.selectedApp.app.competitors
  })

  const publishStatus = useSelector((state) => {
    //console.log(state.selectedApp.app.__typename)
        return state.selectedApp.app.publishStatus
  })

  const logo_url = useSelector((state) => {
    //console.log(state.selectedApp.app.__typename)
        return state.selectedApp.app.logo_url
  })

  const isUpdating = useSelector((state) => {
    //console.log(state.selectedApp.app.__typename)
        return state.selectedApp.isUpdating
  })

document.title = "Update | "+appName + " "+appType;


//console.log("appMonetizations", appMonetizations)
  
  return (
   <div>
   <SearchBarHeader/> 
   <div className="ui top-section">
   
   <Link to={appType == "iOS" ? "/ios/apps/"+idfromURL+"/flows" : "/web/apps/"+idfromURL+"/flows"}>
            <button className="ui button circular " 
            //onClick={handleBackButton}
            >
            <i className="angle left icon"></i> 
              Back
           </button>
        </Link> 

        

    <div className="ui raised segment">  

      <form className={isUpdating ? "ui loading form" : "ui form"}>

      <h1 className="ui dividing header">Edit {appName} {appType}</h1>
        <div className="field">
                <h3>Type: {appType}</h3>
          </div>
          <div className="ui divider"></div>
          <div className="field">
               <PublishStatusEditor value={publishStatus} appId={idfromURL}/>
          </div>     
          <div className="ui divider"></div>
          <div className="field">
              <AppNameField value={appName} appType={appType} appId={idfromURL}/>   
          </div>
          <div className="ui divider"></div>
          <div className="field">
              <AppDescriptionHTMLTextAreaField value={appDescription} appName= {appName} label="Description" appType={appType} appId={idfromURL}/>   
            </div>
          <div className="ui divider"></div>
          <div className="field">
              <AppSearchTermsField value={appSearchTerms} appName= {appName} label="Add Search Terms (overrides existing)" appType={appType} appId={idfromURL}/>   
            </div>
          <div className="ui divider"></div>
          <div className="field">
              <AppCategoriesField value={appCateogries} appName= {appName} label="Add Categories" appType={appType} appId={idfromURL}/>   
            </div>
          <div className="ui divider"></div>
          <div className="field">
              <AppMonetizationsField value={appMonetizations} appName= {appName} label="Add Monetizations" appType={appType} appId={idfromURL}/>
          </div>
          <div className="ui divider"></div>
          <div className="field">
              <AppCompetitorsField value={appCompetitors} appName= {appName} label="Add Competitors" appType={appType} appId={idfromURL}/>   
            </div>
          <div className="ui divider"></div>

          <div className="field">
              <ImagePickerEditor value={logo_url} appName= {appName} label="Logo Image" appType={appType} appId={idfromURL}/>   
            </div>
          <div className="ui divider"></div>

          

          <div className="field">
                <h1>Product Insight</h1>
          </div>

          <div className="field">
              <ShouldDisplayEditor value={shouldDisplay} appId={idfromURL}/>
          </div>    
          <div className="ui divider"></div>
          <div className="field">
                <HTMLTextAreaField value={productText} label="Product Text" appType={appType} appId={idfromURL} gql="productText"/>  
          </div>
          <div className="ui divider"></div>
          <div className="field">
                <HTMLTextAreaField value={valueText} label="Value Text" appType={appType} appId={idfromURL} gql="valueText"/>  
          </div>
          <div className="ui divider"></div>
          <div className="field">
                <HTMLTextAreaField value={userSegmentText} label="User Segment Text (Different type of users)" appType={appType} appId={idfromURL} gql="userSegmentText"/>  
          </div>
          <div className="ui divider"></div>
          <div className="field">
                <HTMLTextAreaField value={monetizationText} label="Monetization Text" appType={appType} appId={idfromURL} gql="monetizationText"/>  
          </div>
          <div className="ui divider"></div>
          <div className="field">
                <HTMLTextAreaField value={competitorText} label="Competitor Text" appType={appType} appId={idfromURL} gql="competitorText"/>  
          </div>
          <div className="ui divider"></div>
         
          <div className="field">
                <HTMLTextAreaField value={missionText} label="Mission Text" appType={appType} appId={idfromURL} gql="missionText"/>  
          </div>
          <div className="ui divider"></div>
          <br/>
            
            
      </form>
      </div>
  </div>
  </div>
  );
};


export default EditAppPage








