import { createAsyncThunk } from '@reduxjs/toolkit';
const KEYS = require('../../config/keys')

const fetchGreetingFromUploadImageServer = createAsyncThunk('greetingFromUploadImageServer/fetch', async(term) =>{
	//console.log("fetchGreetingFromUploadImageServer")
  return fetch(KEYS.IMAGE_UPLOAD_SERVER_URL + "ping", {
	//const response = fetch('http://localhost:4000/', {
    method: 'GET',
    headers: { 
      "Content-Type": "application/json",
      "X-API-Key": KEYS.IMAGE_UPLOAD_SERVER_URL_API_KEY // This is where you include the API key 
    },
  }).then(res => res.json())
})

const uploadIOSImages = createAsyncThunk('uploadIOSImages/upload', async(formData) =>{
  //console.log("uploadIOSImages", formData)
  const fullUrl = KEYS.IMAGE_UPLOAD_SERVER_URL + "uploadIOSImages"
  //console.log(fullUrl)
  return fetch(fullUrl, {
     method: 'POST',
     headers: {
            "X-API-Key": KEYS.IMAGE_UPLOAD_SERVER_URL_API_KEY // This is where you include the API key
        },
    body: formData,
  }).then(res => res.json())
})

const uploadWebImages = createAsyncThunk('uploadWebImages/upload', async(formData) =>{
  //console.log("uploadWebImages", formData)
  const fullUrl = KEYS.IMAGE_UPLOAD_SERVER_URL + "uploadWebImages"
  //console.log(fullUrl)
  return fetch(fullUrl, {
     method: 'POST',
     headers: {
            "X-API-Key": KEYS.IMAGE_UPLOAD_SERVER_URL_API_KEY // This is where you include the API key
        },
    body: formData,
  }).then(res => res.json())
})

export {fetchGreetingFromUploadImageServer, uploadIOSImages, uploadWebImages}
