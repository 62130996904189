// TopMenuForProfile.js
import React from 'react';
import { Link } from 'react-router-dom';
import { useState } from 'react';

const TopMenuForProfile = () => {

  const [activeButton, setActiveButton] = useState("password");

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  return (
    <div className="ui" style={{ paddingTop: '2em' }}>
      <div className="product-insight-horizontal-scrollable-list-container">
        <div className="ui link horizontal list">

        <Link to="my-apps">
            <button
              onClick={() => handleButtonClick('my-apps')}
              className={activeButton === "my-apps" ? " secondary ui  button  circular " : " secondary basic  ui  button  circular"}
            >My Apps</button>
          </Link>


          <Link to="password">
            <button
              onClick={() => handleButtonClick('password')}
              className={activeButton === "password" ? " secondary ui  button  circular " : " secondary basic  ui  button  circular"}
            >Password</button>
          </Link>

         

        </div>
      </div>


    </div >

  );
};


export default TopMenuForProfile





