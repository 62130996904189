// store/index.js
//Redux Store
import {configureStore } from "@reduxjs/toolkit";
import { setupListeners } from '@reduxjs/toolkit/query'
import { searchApi } from './apis/searchApi'
import {searchReducer, changeSearchTerm, selectSearchSuggestion, updateSearchedApp} from './slices/SearchSlice';
import { webappsReducer, selectedWebApp } from './slices/WebAppsSlice'
import { iOSappsReducer, selectediOSApp } from './slices/iOSAppsSlice'
import { selectedAppReducer, selectedApp } from './slices/SelectedAppSlice'
import { useFlowReducer, updateSelectedFlow, resetUserFlowsByAppId } from './slices/UserFlowSlice'
import { iOSFlowReducer, updateSelectedIOSFlow, resetIOSUserFlowsByAppId} from './slices/iOSUserFlowSlice'
import { userflowfilterReducer, toggleFilter, uncheckAllFilters} from './slices/UserflowFilterSlice'
import { userAuthReducer, logout } from './slices/UserAuthSlice'
import { setSelectedItems, removeSelectedItem , appCategorySelectionReducer } from './slices/AppCategorySelectionsSlice'




const store = configureStore({
    devTools: process.env.NODE_ENV !== 'production', //to disbale redux devtools in  prod
    reducer: {
        search: searchReducer,
        auth: userAuthReducer,
       // [searchApi.reducerPath]: searchApi.reducer,
        selectedApp: selectedAppReducer,
        webapps: webappsReducer,
        appCategories:appCategorySelectionReducer,
        userflows: useFlowReducer,
        iosapps: iOSappsReducer,
        iosuserflows:iOSFlowReducer,
        userflowsfilter:userflowfilterReducer,
    },

// Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(searchApi.middleware),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)

//Exporting actions
export  {store, toggleFilter, uncheckAllFilters, changeSearchTerm, selectSearchSuggestion, selectedWebApp, updateSelectedFlow, resetUserFlowsByAppId, selectediOSApp, updateSelectedIOSFlow, resetIOSUserFlowsByAppId, selectedApp, logout, setSelectedItems, removeSelectedItem };

//Exporting thunks
export { updateIOSScreen, updateWebScreen } from './thunks/updateScreenThunk'
export { searchAppCategories } from './thunks/appCatergoryThunk'
export { searchUserInteractions } from './thunks/userInteractionsThunk.js'
export { empLogInWithEmailAndPassword, validateToken,  validateEmployeeTokenAndReturnEmployee} from './thunks/authThunk'
export { useSearchSuggestionsQuery } from './apis/searchApi'
export { fetchGreetingFromUploadImageServer, uploadIOSImages, uploadWebImages } from './thunks/uploadImagesSlice'
export { fetchSearchSuggestions, fetchGreetingFromGraphQL } from './thunks/searchThunk' //importing an exporting search thunk

export { fetchInitialWebApps, fetchWebAppById, fetchWebAppByName, fetchProductInsightForWebApp, 
          updateWebAppById, updateProductInsightByAppId, 
          fetchAllDetailsOfAppToEditById, createWebApp, fetchWebCompetitorsByAppId } 
          from './thunks/webAppsThunk' //importing an exporting web apps thunk

export { fetchInitialiOSApps, fetchiOSAppById, fetchProductInsightForiOSApp, 
        updateiOSAppByID, updateSearchTermsByAppId, createiOSApp, updateAppCategoriesiOSAppByID
        , fetchIOSCompetitorsByAppId, updateAppCompetitorsiOSAppByID} 
        from './thunks/iOSAppsThunk' //importing an exporting ios apps thunk

export { fetchAllUserFlowNamesByAppId, fetchUserFlowByAppId, fetchiOSUserFlowByAppId, 
        fetchUserFlowById, fetchIOSUserFlowById, createUserflowWithoutScreens, 
        updateUserflowByID, addScreensObjsToUserflow, updateUserFlowScreens } from './thunks/userFlowThunk' //importing an exporting user flow thunk






