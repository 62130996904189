
import React from 'react';

const UserSegmentTextComponent = ({ data }) => {
    //console.log("UserSegmentTextComponent prod", data)
  return (
    <div >
      <p dangerouslySetInnerHTML={{ __html: data }} />
    </div>
  );
};

export default UserSegmentTextComponent;