import { createAsyncThunk } from '@reduxjs/toolkit';
const KEYS = require('../../config/keys')

const empLogInWithEmailAndPassword = createAsyncThunk('user/empLogInWithEmailAndPassword', async({ email, password }) => {
 // console.log("empLogInWithEmailAndPassword thunk")
 // console.log(email)
 // console.log(password)
  
  return fetch(KEYS.GRAPHQL_URL, {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
          variables: {
          password,
          email
        },
          query: `
          mutation empLogInWithEmailAndPassword($email: String!, $password: String!) {
            empLogInWithEmailAndPassword(email: $email, password: $password) {
                success
                token
                errorMessage
                employee {
                  id
                  firstName
                  email
                  scope
                  hasAccess
                }
          }
        }
        `
      })
  }).then(res => res.json())
})


const validateToken = createAsyncThunk('validateToken/fetch', async({ token }) => {
  console.log("validateToken thunk")
  //console.log(token)
 
  
  return fetch(KEYS.GRAPHQL_URL, {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
          variables: {
          token
        },
          query: `
          query ($token: String!) {
          validateToken(token: $token){
            valid
            payload {
              fid
            }
          }
        }
        `
      })
  }).then(res => res.json())
})

const validateEmployeeTokenAndReturnEmployee = createAsyncThunk('validateEmployeeTokenAndReturnEmployee/fetch', async({ token }) => {
  //console.log("validateToken thunk")
  //console.log(token)
 
  
  return fetch(KEYS.GRAPHQL_URL, {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
          variables: {
          token
        },
          query: `
          query ($token: String!) {
            validateEmployeeTokenAndReturnEmployee(token: $token) {
              success
              employee {
                id
                firstName
                email
                scope
                hasAccess
              }
            }
        }
        `
      })
  }).then(res => res.json())
})

export { empLogInWithEmailAndPassword, validateToken, validateEmployeeTokenAndReturnEmployee }
