//CcompetitorTextComponent.js

import React from 'react';

const CompetitorTextComponent = ({ data }) => {
    //console.log("CompetitorTextComponent prod", data)
  return (
    <div >
      <p dangerouslySetInnerHTML={{ __html: data }} />
    </div>
  );
}   

export default CompetitorTextComponent;