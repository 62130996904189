// ShouldDisplayEditor.js
import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateProductInsightByAppId } from '../../../store'


const ShouldDisplayEditor = ({value, appType, appId}) => {

   const [shouldDisplay, setShouldDisplay] = useState(value);
  const [isEditing, setIsEditing] = useState(false);
  const [originalData, setOriginalData] = useState(null);
  const dispatch = useDispatch();

   useEffect(() => {
      // Update local state to match external value when entering edit mode
     setShouldDisplay(value);
      setOriginalData(value); // Save the original data for comparison
  }, [value]);

  const handleSave = (e) => {
    e.preventDefault()

    const newInsight = {
      shouldDisplay: shouldDisplay
    };
    dispatch(updateProductInsightByAppId({ appId, newInsight }))
    setIsEditing(false); // Exit editing mode
  };

  const handleCancel = () => {
    setShouldDisplay(value); // Reset the value to the original
    setIsEditing(false); // Exit editing mode
  };

  // Function to check if there are any changes
  const hasChanges = () => {
    return (
      shouldDisplay !== originalData
    );
  };

   const handleChange = (e) => {
    //console.log(e.target.value === 'true')
      setShouldDisplay(e.target.value === 'true');
  };


//  console.log("shouldDisplay", shouldDisplay)

if (isEditing) {
    return (
      <div className="ui form">
        <div className="field">
          <h3>
            Should Display?
            <button className="ui right floated button" onClick={handleCancel}>Cancel</button>
            <button disabled={!hasChanges()}  className="ui right floated button" onClick={handleSave}>Save</button>
          </h3>
          <div className="inline fields">
                <div className="field">
                  <div className="ui radio checkbox">
                    <input type="radio" value="true" checked={shouldDisplay === true} onChange={handleChange} />
                    <label>true</label>
                  </div>
                 </div>
                 <div className="field"> 
                    <div className="ui radio checkbox">
                      <input type="radio" value="false" checked={shouldDisplay === false} onChange={handleChange} />
                      <label>false</label>
                    </div>
                 </div>
                  
           </div>   
        </div>
      </div>
    );
  }


  return (
   <div>
          <h3>
            Should Display?
           <button className="ui right floated button" onClick={() => setIsEditing(true)}>Edit</button>
          </h3>
          <p>
          
           {value !== undefined ? value.toString() : 'Value is not available'}
          </p>
  </div>
  );
};


export default ShouldDisplayEditor







