  // AppMonetizationSearchBar.js
  import React from 'react';
  import { useSelector, useDispatch } from 'react-redux'
    import {useState} from 'react';
  import AppMonetizationSearchSuggestionList from './AppMonetizationSearchSuggestionList'
 import { searchAppMonetizations } from '../../../../store/thunks/appMonetizationThunk'
  
  
  const AppMonetizationSearchBar = ({handleSuggestionClicked, currentCategories}) => {
    //to keep track of local state of suggestion list
     const [isSuggestionListOpen, setSuggestionListOpen] = useState(false);
     const [searchSuggestions, setSearchSuggestions] = useState([])
     const [searchTerm, setSearchTerm] = useState("")
    const dispatch = useDispatch();
  
  
     
    const handleSearchTermChange = async (event) => { 
      const newTerm = event.target.value;
        setSuggestionListOpen(true);  
        setSearchTerm(newTerm)
         // Dispatch the thunk and get the result
        const result = await dispatch(searchAppMonetizations(newTerm));
        //console.log(result.payload.data.searchAppCategories)
        
        //From PROD API
          // if (result && result.payload) {
          //     // Extract IDs of categories already present in currentCategories
          //     const currentCategoryIds = new Set(currentCategories.map(cat => cat.id));
  
          //     // Filter out categories that are already in currentCategories
          //     const filteredSuggestions = result.payload.data.searchAppCategories.filter(cat => !currentCategoryIds.has(cat.id));
              
          //     // Set the search suggestions
          //     setSearchSuggestions(filteredSuggestions);
          // }
  
        //From local
        //console.log(result.payload)
        if (result && result.payload) {
              // Extract IDs of categories already present in currentCategories
              const currentCategoryIds = new Set(currentCategories.map(cat => cat.id));
  
              // Filter out categories that are already in currentCategories
              const filteredSuggestions = result.payload.filter(cat => !currentCategoryIds.has(cat.id));
              
              // Set the search suggestions
              setSearchSuggestions(filteredSuggestions);
          }
      };
  
      const handleBlur = (event) => {
      //console.log("blur")
      setSuggestionListOpen(false);
      //console.log(isSuggestionListOpen)
    };
  
    const handleOnClick = ()=> {
        console.log("handleonClick")
    }
  
  
    return (
    <div>     
      <div id="search" className="ui search search-bar-container">
           <div className="ui icon input">
             <input className=" prompt search-bar" 
             type="text" 
             style = {{color: "black"}} 
             placeholder="Search Monetizations..."  
             value={searchTerm}
             //value={event.target.value}
             onChange={handleSearchTermChange}
             onBlur={handleBlur}
             />
                <i className="search icon"></i>
            
          </div>
         
           {isSuggestionListOpen  && searchSuggestions.length > 0 ? <AppMonetizationSearchSuggestionList handleSuggestionClicked={handleSuggestionClicked} setSearchTerm={setSearchTerm} suggestions = {searchSuggestions}/>: null}
     
        </div> 
     </div>  
    );
  };
  
  
  export default AppMonetizationSearchBar
  
  
  
  
  
  
  
  
  