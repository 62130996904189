//keys.js
//To ensure we are calling correct graphQL env
if(process.env.REACT_APP_ENVIRONMENT === 'production'){
	//In PROD
	module.exports = require('./prod')
}else if(process.env.REACT_APP_ENVIRONMENT === 'LOCAL'){
	
	//Connect RemoteDB
	//Connect to LOCAL graphQL
    //console.log("In LOCAL")
	module.exports = require('./local')
}else if(process.env.REACT_APP_ENVIRONMENT === 'DEV'){
	
	//Connect to PROD graphQL
   // console.log("In DEV")
	module.exports = require('./dev')
}