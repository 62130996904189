// AppCategoriesField.js
import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AppCategorySearchBar from './AppCategorySearchBar'
import { updateAppCategoriesiOSAppByID } from '../../../../store'


const AppCategoriesField = ({value=[], appType, appId, label}) => {

    const [currentCategories, setCurrentCategories] = useState(value);
   const [newTermsArr, setNewTermsArr] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  
  const dispatch = useDispatch();

   useEffect(() => {
      setCurrentCategories(value);
  }, [value]);

  const handleSave = (e) => {
    e.preventDefault()
    const iOsApp = {
      categories: currentCategories.map(category => category.id)
    };
    //console.log(iOsApp)
    dispatch(updateAppCategoriesiOSAppByID({ appId, iOsApp }))
    setIsEditing(false); // Exit editing mode
  };

  const handleCancel = () => {
    setCurrentCategories(value);
    setIsEditing(false); // Exit editing mode
  };

  // Function to check if there are any changes
  const hasChanges = () => {
    // If the lengths differ, return true (changes detected)
    if (currentCategories.length !== value.length) {
      return true;
    }
  };

  //handleAddCategory
  const handleSuggestionClicked = (category) => {
    //console.log("handleSuggestionClicked", category)
     if (!currentCategories.find(cat => cat.id === category.id)) {
      setCurrentCategories([...currentCategories, category]);
    }
  };

   const handleDeleteCategory = (id) => {
    setCurrentCategories(currentCategories.filter(cat => cat.id !== id));
  };




if (isEditing) {
    return (
      <div className="ui form">
        <div className="field">
          <h3>
            {label}
            <button className="ui right floated button" onClick={handleCancel}>Cancel</button>
            <button disabled={!hasChanges()}  className="ui right floated button" onClick={handleSave}>Save</button>
          </h3>
          <p>
            {currentCategories.map(cat => (
              <a key={cat.id} className="ui label">
                {cat.name}
                <i className="delete icon" onClick={() => handleDeleteCategory(cat.id)}></i>
              </a>
            ))}
          </p>
          <AppCategorySearchBar handleSuggestionClicked={handleSuggestionClicked} currentCategories={currentCategories}/> 
        </div>
        
      </div>
    );
  }


  return (
   <div>
          <h3>
            {label}
           <button className="ui right floated button" onClick={() => setIsEditing(true)}>Edit</button>
          </h3>
          <p>
             {currentCategories.map(cat => cat.name).join(', ')}
          </p>
  </div>
  );
};


export default AppCategoriesField







