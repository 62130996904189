    // ScreenAndFlowsTile.js
import React from 'react';
import {useState} from 'react';
import { Link } from 'react-router-dom'
import ScreensGridWebApp from './ScreensGridWebApp'
import FlowsGridWebApp from './FlowsGridWebApp'
import { useLocation } from "react-router-dom";
import { useParams } from 'react-router-dom';

 

const ScreenAndFlowsTile = () => {

  const location = useLocation();
  const params = useParams();
  
   //getting parameter
  const idfromURL = params.id

 // Get the full URL of the current route.
  const fullUrl = location.pathname;

  var currentUrl = ''

  if (fullUrl === "/web/apps/"+idfromURL+"/flows") {
  // The current route is the `/web/apps/flows/:id` route.
    //console.log(fullUrl)
    currentUrl='flow'
    }
    else if(fullUrl === "/web/apps/"+idfromURL+"/screens") {
        currentUrl='screen'
    }

  // console.log(currentUrl) 
  
  return (

            <div className="ui vertical stripe">    
                <div className="ui link horizontal list">
                    
                    {/*
                    <Link 
                        className={currentUrl === "screen" ? "active item" : "disabled item" }
                         
                    >
                        <h2 
                        id="screen"
                        className={currentUrl === "screen" ? "underlined" : null }    
                        >Screens</h2>
                     </Link>
                    */}
                    




                     <Link
                     className={currentUrl === "flow" ? "active item" : "item" }
                     
                     >
                        <h2 
                        className={currentUrl === "flow" ? "underlined" : null }
                        id="flow">
                            User Flows
                        </h2>
                     </Link>
                       
                </div>
                <div className="ui divider"></div>
                <div>
                 {currentUrl === "screen" ?  <ScreensGridWebApp/> : null}   
                 {currentUrl === "flow" ?    <FlowsGridWebApp/> : null}   
                   
                </div>
    </div >    

  );
};


export default ScreenAndFlowsTile


//<Link to= {"/web/apps/"+idfromURL+"/screens"}
//<Link to= {"/web/apps/"+idfromURL+"/flows"}




