// FlowsGridRowWebApp;.js
import React from 'react';
import FlowsGridImageRowWebApp from './FlowsGridImageRowWebApp'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {updateSelectedFlow} from '../../store'





const FlowsGridRowWebApp = ({userFlowRows}) => {
  //console.log(userFlowRows)

  const params = useParams();
  const idfromURL = params.id  
  const dispatch = useDispatch();

  const handleClickEditScreenButton = (flow) => {
   //console.log("handleClickEditScreenButton",flow)
   dispatch(updateSelectedFlow(flow))
  };


  return (
  //asdas
   <div >
   {
      userFlowRows.map((flow, i) => {
        // if(flow.screens.count === 0){
        //    // return <div>{flow.screens.count}</div> 
        //     return null
        // }
        return (
                <div className="screens-flows-rows" key={i}>
                      <h3 className="ui header">
                                     {flow.name +" #"+ flow.position}
                                {// show the screen count text only if the screen count is greator than 0
                                  flow.screens.count > 0 && 
                                      <div className="sub header">
                                        {flow.screens.count > 1 ? `${flow.screens.count} screens` : `${flow.screens.count} screen`}
                                         {flow.userInteractionsCount === 1 ? ' | 1 User Interaction' : flow.userInteractionsCount > 1 ? ` | ${flow.userInteractionsCount} User Interactions` : null}
                                      </div>
                                }
                                 <p>
                                 {"userflow.id: "+flow.id}
                                 <Link to={"/web/apps/"+idfromURL+"/flows/"+flow.id+"/editflow"}
                                      onClick={()=> handleClickEditScreenButton(flow)}>
                                       <button className="ui right floated button">Edit Flow</button>
                                  </Link>
                                  <Link to={"/web/apps/flows/"+flow.id+"/editscreens"}
                                     onClick={()=> handleClickEditScreenButton(flow)}>
                                      <button className="ui right floated button">Edit Screens</button>
                                     </Link>
                                 </p>
                       </h3> 
                        
                        <p dangerouslySetInnerHTML={{ __html: flow.description }} />
                         
                                {// show the image row only if the screen count is greator than 0
                                    flow.screens.count > 0 && 
                                       <FlowsGridImageRowWebApp screens = {flow.screens.screen} flowId={flow.id} flow={flow}/>
                                  }   
                            
                            
                      
              </div>
          )})
    }

   
  </div> 
  );
};


export default FlowsGridRowWebApp;


//<div className="sub header">{flow.screens.count > 1 ? flow.screens.count+ " screens" : flow.screens.count+ " screen" }</div>
//<FlowsGridImageRowWebApp screens = {flow.screens.screen} flowId={flow.id}/>


