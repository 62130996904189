import { createAsyncThunk } from '@reduxjs/toolkit';
const KEYS = require('../../config/keys')


const searchUserInteractions = createAsyncThunk('searchUserInteractions/fetch', async({ searchTerm, platform }) => {
 // console.log("searchUserInteractions thunk", searchTerm)
 
  
  // return fetch(KEYS.GRAPHQL_URL, {
  //     method: 'POST',
  //     headers: { "Content-Type": "application/json" },
  //     body: JSON.stringify({
  //         variables: {
  //         searchTerm
  //       },
  //         query: `
  //         query SearchUserInteractions($searchTerm: String) {
  //         searchUserInteractions(searchTerm: $searchTerm) {
  //           name
  //           id
  //         }
  //       }
  //       `
  //     })
  // }).then(res => res.json())


  return userInteractions.filter(interaction => 
        interaction.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
      interaction.platform.toLowerCase() === platform.toLowerCase()
    ).map(interaction => ({ name: interaction.name, id: interaction.id }));


})

export { searchUserInteractions }



const userInteractions = [
        {
          "id": "66380fa8364999269417f805",
          "name": "Tap",
          "platform": "mobile"
        },
        {
          "id": "6638111a364999269417f806",
          "name": "Type",
          "platform": "mobile"
        },
        {
          "id": "66381145364999269417f807",
          "name": "Vertical Scroll",
          "platform": "mobile"
        },
        {
          "id": "6638118e364999269417f808",
          "name": "Horizontal Scroll",
          "platform": "mobile"
        },
        {
          "id": "663811e6364999269417f809",
          "name": "Swipe Left",
          "platform": "mobile"
        },
        {
          "id": "66381220364999269417f80a",
          "name": "Swipe Right",
          "platform": "mobile"
        },
        {
          "id": "663812d0364999269417f80b",
          "name": "Swipe Up",
          "platform": "mobile"
        },
        {
          "id": "663812e2364999269417f80c",
          "name": "Swipe Down",
          "platform": "mobile"
        },
        {
          "id": "66381302364999269417f80d",
          "name": "Pinch",
          "platform": "mobile"
        },
        {
          "id": "6638131f364999269417f80e",
          "name": "Drag",
          "platform": "mobile"
        },
        {
          "id": "66381332364999269417f80f",
          "name": "Long Press",
          "platform": "mobile"
        },
        {
          "id": "66381356364999269417f810",
          "name": "Voice Command",
          "platform": "mobile"
        },
        {
          "id": "663813d3364999269417f811",
          "name": "Shake",
          "platform": "mobile"
        },
        {
          "id": "663813e1364999269417f812",
          "name": "Tilt",
          "platform": "mobile"
        },
        {
          "id": "663813f0364999269417f813",
          "name": "Rotate",
          "platform": "mobile"
        },
        {
          "id": "664145b9f5316ad5ccd1dce5",
          "name": "Click",
          "platform": "web"
        },
        {
          "id": "6641460cf5316ad5ccd1dce6",
          "name": "Double Click",
          "platform": "web"
        },
        {
          "id": "66414625f5316ad5ccd1dce7",
          "name": "Right Click",
          "platform": "web"
        },
        {
          "id": "6641463af5316ad5ccd1dce8",
          "name": "Hover",
          "platform": "web"
        },
        {
          "id": "664146dcf5316ad5ccd1dce9",
          "name": "Vertical Scroll",
          "platform": "web"
        },
        {
          "id": "664146f1f5316ad5ccd1dcea",
          "name": "Horizontal Scroll",
          "platform": "web"
        },
        {
          "id": "66414745f5316ad5ccd1dceb",
          "name": "Drag and Drop",
          "platform": "web"
        },
        {
          "id": "66414766f5316ad5ccd1dcec",
          "name": "Zoom In",
          "platform": "web"
        },
        {
          "id": "6641477ef5316ad5ccd1dced",
          "name": "Zoom Out",
          "platform": "web"
        },
        {
          "id": "664147f5f5316ad5ccd1dcee",
          "name": "Tab",
          "platform": "web"
        },
        {
          "id": "66416593817a3ea08dd9083f",
          "name": "Type",
          "platform": "web"
        },
        {
          "id": "6678a10fad4d10e3c1fd19e5",
          "name": "Double Click to Pay",
          "platform": "mobile"
        },
        {
          "id": "6678a2fead4d10e3c1fd19e6",
          "name": "Double Click to Confirm",
          "platform": "mobile"
        },
        {
          "id": "66856d45307c9dbe7c45b4b4",
          "name": "Scan",
          "platform": "mobile"
        }


      ]

