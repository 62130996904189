// WebEditScreensHeader.js
import React from 'react';
import { Link } from 'react-router-dom'
//for setting title tage and meta descriptions
import { Helmet } from "react-helmet"; 
import { useDispatch } from 'react-redux'
//import directly from slice
import { clearPendingScreenUpdates } from '../../store/slices/UserFlowSlice'
import { updateUserFlowScreens } from '../../store/thunks/userFlowThunk'






const WebEditScreensHeader = ({app, flow, addNewScreensHandler, pendingScreenUpdates}) => {
const dispatch = useDispatch();

 //console.log(app)
 //console.log(flow)
 //console.log(flow.flow.name)  //old no longer in use; test and delete

var newScreens = []
var screenCount = flow.screens.screen.length

if (screenCount > 0){
        newScreens = flow.screens.screen.filter(screen => screen.isNew )
}
//console.log(newScreens);
 var flowName;

 if (flow.name.includes("/"))
 {
    const parts = flow.name.split("/");
    flowName = parts[1]
 } else 
 {
    flowName = flow.name
 }

 //console.log(flowName)

 const handleAddNewScreensClick = () => {
    //console.log("handleAddNewScreensClick")
    addNewScreensHandler()
 }

 const handleBackButtonClick = () => {
    dispatch(clearPendingScreenUpdates())
 }

 const handleUpdatePendingScreens = () => {  
      //console.log("handleUpdatePendingScreens")
      dispatch(updateUserFlowScreens({flowId: flow.id, screens: pendingScreenUpdates}))
 }    

  // Calculate buttonText just before the return statement of the render method
  const buttonText = newScreens.length > 0 && pendingScreenUpdates.length > 0
  ? `Save (${newScreens.length} new; ${pendingScreenUpdates.length} updates)`
  : newScreens.length > 0
  ? `Save (${newScreens.length} new)`
  : pendingScreenUpdates.length > 0
  ? `Save (${pendingScreenUpdates.length} updates)`
  : ''; // Fallback text, in case there are no new screens or updates
  
   //create a new handler for save button click
   //inside the handler, if we have both newScreens and pendingScreenUpdates, then
   //first add new screens, then update pending screens
   //if we only have newScreens, then just add new screens
   //if we only have pendingScreenUpdates, then just update pending screens
  
   const handleSaveButtonClick = async () => {
      //console.log("handleSaveButtonClick")
      //console.log("newScreens",newScreens)
      //console.log("pendingScreenUpdates",pendingScreenUpdates)
      if (newScreens.length > 0 && pendingScreenUpdates.length > 0){
          //first add new screens
          await addNewScreensHandler();
          //then update pending screens
          await dispatch(updateUserFlowScreens({flowId: flow.id, screens: pendingScreenUpdates}));
      } else if (newScreens.length > 0){
          //just add new screens
          await addNewScreensHandler();
      } else if (pendingScreenUpdates.length > 0){
          //just update pending screens
          await dispatch(updateUserFlowScreens({flowId: flow.id, screens: pendingScreenUpdates}));
      }
  }
   


  return (
  //asdas

  <div>  
            <Helmet>
            <title>{"Edit Screens | "+app.name+" iOS"}</title>
            <meta name="description" content="a curated collection of the best web and mobile apps" />
            </Helmet>  
                  <div className="pusher">     
                          <div className="ui large  borderless fixed menu">
                            <Link to={"/web/apps/"+app.id+"/flows"} 
                            onClick={handleBackButtonClick}
                            className="item">
                               <i className="x icon"></i>
                            </Link>
                                
                                <div className="flow-details-header-center-items">
                                        
                                         <div className="item">
                                                <h3 className="ui header">
                                                <img className="ui  rounded image" 
                                                   src={app.logo_url}/>
                                                <div className="content">
                                                {app.name}   
                                                        <div className="sub header">{flowName} ({screenCount} screens)</div> 

                                                          
                                                </div>
                                                </h3>
                                          </div> 
                                  </div>  
                                  <div className="right menu">
                                         {/* <div className="item">
                                          {newScreens.length > 0 ? <button className="ui mini right teal floated button" onClick={handleAddNewScreensClick}>Add New Screens ({newScreens.length})</button> : null}
                                          </div>
                                          <div className="item">
                                         </div>
                                         <div className="item">
                                         {pendingScreenUpdates.length > 0 ? <button className="ui mini right teal floated button" onClick={handleUpdatePendingScreens}>Save Pending Updates({pendingScreenUpdates.length})</button> : null}
                                          </div> */}
                                           <div className="item">
                                          {pendingScreenUpdates.length > 0 || newScreens.length > 0 ? <button className="ui mini right teal floated button" onClick={handleSaveButtonClick}>
                                            {buttonText}
                                             </button> : null}
                                             </div>
                                  </div>             
                          </div>
                   </div> 
   </div>                 
  );
};


export default WebEditScreensHeader


