// SearchBarHeader.js
import React from 'react';
import { Link } from 'react-router-dom'
import HeaderSearchBar from './HeaderSearchBar'
import {useState} from 'react';
import { useSelector } from 'react-redux';
import { logout } from '../../store'
import { useDispatch } from 'react-redux';
import { websiteName } from '../../utils/environment';
import { hasAnyScope } from '../../utils/scopes';
import { SCOPES } from '../../utils/scopesConstants';

const SearchBarHeader = ({displaySearchBar = true }) => {

const dispatch = useDispatch();

const [getMenuOpen, setMenuOpen] = useState(false);
const employee = useSelector((state) => state.auth.employee);

//console.log("is menu open", getMenuOpen)


const handleLogout = () => {
    //console.log("handleLogout");
    dispatch(logout());
  };

const handleOnClick = (r) => {   
    const id = r.target.id;
       //console.log("ASDASD",id);
        setMenuOpen(!getMenuOpen)   
    };

  return (
  //asdas
  <div className="pusher">     
    <div className="ui  ">
        <div className="ui ">
          <div className="ui borderless fixed menu">
            <Link to="/" className="item">
                {websiteName +" Admin"}
            </Link>    

            <div className="header-search-bar item">
             {displaySearchBar ? <HeaderSearchBar/> : null}
            </div>
            <div className="toc-sidebar-icon item">
                  <a className="toc item"
                  onClick={handleOnClick}
                  >
                    <i className="sidebar icon"></i>
                  </a>
            </div>      
            <div 
            className={getMenuOpen ? "ui vertical right visible sidebar menu" : "ui vertical right sidebar menu" }
            >
                  
                     <div>
                      <a className="item">   
                      <i className="close icon"
                      onClick={(e) => {
                                        e.stopPropagation(); // This stops the click event from propagating to the button
                                        handleOnClick(e);
                                    }}

                      ></i>
                       </a>
                   </div>    
                  
                <div className="ui divider"></div>

                <Link to="/browse/web/apps" className="item"
                onClick={handleOnClick}
                >
                <i className="th icon"></i>
                    <div className="">Browse Apps</div>
                </Link>
                <div className="ui divider"></div>

                {
                  hasAnyScope(employee.scope,[SCOPES.ADMIN]) &&
                  <>
                  <Link to="/apps/newapp" className="item"
                  onClick={handleOnClick}
                  >
                  <i className="plus icon"></i>
                      <div className="">Add New App</div>
                  </Link>
                  <div className="ui divider"></div>
                  </>
                }


                <Link to="/profile" className="item"
                onClick={handleOnClick}
                >
                <i className="user icon"></i>
                    Profile
                </Link>
                <div className="ui divider"></div>

                  {  
                    hasAnyScope(employee.scope,[SCOPES.ADMIN]) &&
                    <>
                    <Link to="/admin" className="item"
                    onClick={handleOnClick}
                    >
                    <i className="lock icon"></i>
                        <div className="">Admin</div>
                    </Link>
                    <div className="ui divider"></div>
                    </>
                    }


                <Link to="" className="item"
                onClick={handleOnClick}
                >
                <i className="sign-out icon"></i>
                    <div className="" onClick={handleLogout}>Logout <b>{employee.email}</b></div>
                </Link>
                <div className="ui divider"></div>

              
                
              </div>
          </div>
        </div> 
    </div>  
   </div>  
  );
};


export default SearchBarHeader




// <div className="apps-menu-item">
//             <Link to="/browse/web/apps" className="item">
//               <div className="">Apps</div>
//             </Link>
//             </div>
