 // CleanLandingPage.js
 import React from 'react';
 import SearchBar from './SearchBar'
 import ResultGrid from './ResultGrid'
 import { useNavigate } from "react-router-dom";
 import { useEffect, useState } from 'react'
 import SearchBarHeader from './SearchBarHeader/SearchBarHeader'
 import Slider from "react-slick";
 import FooterSection from './Footer/FooterSection'

 const CleanLandingPage = () => {
  const navigate = useNavigate();
  
  useEffect(()=> {
        //temporary fix to automatically navigate to webapps page
    //until I design a better landing page
       navigate('/browse/ios/apps');  
        
    },[]);

  const handleClick = () => {
    navigate('/browse/web/apps');
  };

   const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      adaptiveHeight: true,
      autoplay: true,
     // speed: 2000,
      autoplaySpeed: 5000,
     // cssEase: "linear",
      fade: true,
      pauseOnHover: true
    };


   return (
 <div className="full-height-container">      
      <div className="content-container">
   <SearchBarHeader/> 
   <div className="clean-landing-page"> 
    <div className="ui vertical masthead center aligned segment">
          <div className="ui fluid centered big image landingpage-meeako-logo">
             <img src="assests/Meeako-06.png" />
          </div>
        <Slider {...settings}>
        <div>
             <p className="landingpage-tags">Discover your competitive advantage by exploring the feature sets and user flows from leading industry players. Leverage these insights to identify opportunities and refine your solutions, ensuring you stay ahead of the market curve</p>
         </div>
         <div>
             <p className="landingpage-tags">Master the expansive landscape of applications your target users engage with. Expand your understanding to innovate and elevate your offerings, clearly differentiating your products from the competition</p>
         </div>
          <div>
          <p className="landingpage-tags">Elevate your UX design by accessing our extensive collection of user flows from industry-leading apps. Seamlessly integrate this innovation into your projects to deliver an unmatched user experience at every interaction</p>
             
          </div>
          <div>
           <p className="landingpage-tags">Strategize with confidence as a Product Manager. Sharpen your product intuition and strategic acumen with our curated user flows from top-tier apps, equipping you with the insights needed to lead your product to market dominance</p>
          </div>
          <div>
           <p className="landingpage-tags">Fuel your creativity with inspiration from the best mobile and web apps. Explore a realm of possibilities and revolutionize your approach to crafting exceptional digital experiences</p>
          </div>
        </Slider>
         <br/>
         <br/>
        <button 
           className="ui icon secondary circular button right labeled icon"
          onClick={handleClick}
          >
          <i className="arrow right icon"></i>
          Start exploring
      </button>

    </div> 

  </div>
   
</div> 
        {/*<FooterSection/>*/}
 </div>       
   );
 };


 export default CleanLandingPage


// <h1 className="ui header centered" >404</h1>






