// IOSFlowDetailsHeader.js
import React from 'react';
import { Link } from 'react-router-dom'
//for setting title tage and meta descriptions
import { Helmet } from "react-helmet"; 






const IOSFlowDetailsHeader = ({iOSApp, flow}) => {

 //console.log(iOSApp)
 //console.log(flow)
 //console.log(flow.flow.name)  //old no longer in use; test and delete

 var flowName;

 if (flow.name.includes("/"))
 {
    const parts = flow.name.split("/");
    flowName = parts[1]
 } else 
 {
    flowName = flow.name
 }

 //console.log(flowName)


  return (
  //asdas

  <div>  
            <Helmet>
            <title>{"Admin | "+iOSApp.name+" iOS"}</title>
            <meta name="description" content="a curated collection of the best web and mobile apps" />
            </Helmet>  
                  <div className="pusher">     
                          <div className="ui large  borderless fixed menu">
                            <Link to={"/ios/apps/"+iOSApp.id+"/flows"} className="item">
                               <i className="x icon"></i>
                            </Link>
                                <div className="flow-details-header-center-items">
                                        
                                         <div className="item">
                                                <h3 className="ui header">
                                                <img className="ui  rounded image" 
                                                   src={iOSApp.logo_url}/>
                                                <div className="content">
                                                {iOSApp.name}   
                                                        <div className="sub header">{flowName}</div>  
                                                          
                                                </div>
                                                </h3>
                                          </div> 
                                  </div>               
                          </div>
                   </div> 
   </div>                 
  );
};


export default IOSFlowDetailsHeader


