// BackToTopButton.js
import React, { useState, useEffect } from 'react';


const BackToTopButton = () => {


 const [scrolled, setScrolled] = useState(400);
 const handleScroll = () => {
  if (document.querySelector('.btt')) {
    const buttonElement = document.querySelector('.btt');

    if (window.pageYOffset > scrolled) {
      buttonElement.style.display = 'block';
    } else {
      buttonElement.style.display = 'none';
    }
  }
};
  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    return false;
  };

  useEffect(() => {
    document.querySelector('.btt').style.display = 'none'; //hides the button on page load
    window.addEventListener('scroll', handleScroll);
  }, []);

  return (
  //asdas
  <div>
      <button 
      className="ui icon secondary circular button btt"
      onClick={handleClick}
      >
        <i className="arrow up icon"></i>
      </button>
    </div> 
  );
};


export default BackToTopButton




