// IOSLandingPage.js
import React from 'react';
import MobileWebTile from '../MobileWebTile'
import IOSAppsResultGrid from './IOSAppsResultGrid'
import SearchBarHeader from '../SearchBarHeader/SearchBarHeader'

//to call API when this component loads
 import { useEffect } from 'react'
 import { fetchInitialiOSApps } from '../../store'
 import { useSelector, useDispatch } from 'react-redux'



const IOSLandingPage = () => {
  //const iosapps = ['1','2', '3', '4', '5', '6']
  //const iosapps = []
  const dispatch = useDispatch();
  const iosapps = useSelector((state) => {
     //console.log("webapps", state.webapps.initialWebApps)
     //console.log("selectedWebApp", state.webapps.selectedWebApp)
      return state.iosapps.initialiOSApps
   })

 //calls when compenent is loaded
    useEffect(()=> {
        //fetch apps only if webapps array is 0 i.e on inital load
        if(iosapps.length === 0){
        console.log("FETCHING INITIAL IOS APPS")
        dispatch(fetchInitialiOSApps())
        }
        
    },[]);

  return (
  //asdasdasd
  <div>     
    <SearchBarHeader/>
       <div className="ui top-section">
            <div className="ui text ">
              <h1 className="ui header">
                Browse iOS Apps
              </h1> 
            </div>
        </div>  

        <MobileWebTile active="ios"/>

         <div className="ui right-left-spacing">   

                <div className=" ui grid">
                 {/*<h4>Results</h4>*/}
                   {/*<ResultGrid/>*/} 
                 <IOSAppsResultGrid iosapps={iosapps}/>
                </div>
           
        </div>

   </div>  
  );
};


export default IOSLandingPage








