// IOSAppsFlowsPage.js
import React from "react";
import { useNavigate } from "react-router-dom";
import ScreenAndFlowsTile from "./ScreenAndFlowsTile";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import SimpleLoader from "../Loaders/SimpleLoader";
import InlineLoader from "../Loaders/InlineLoader";
import SearchBarHeader from "../SearchBarHeader/SearchBarHeader";
import { useParams } from "react-router-dom";

import IOSProductInsightHorizontalSection from "./IOSProductInsightSections/IOSProductInsightHorizontalSection";
import { fetchiOSAppById } from "../../store";
import { SCOPES } from "../../utils/scopesConstants";
import { hasAnyScope } from "../../utils/scopes";

const IOSAppsFlowsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const iOSApp = useSelector((state) => {
  //   //console.log(state.iosapps.selectediOSApp)
  //   return state.iosapps.selectediOSApp
  // })

  const employee = useSelector((state) => state.auth.employee);
  
  const iOSApp = useSelector((state) => {
    //console.log(state.selectedApp.app)
    return state.selectedApp.app;
  });
  //console.log("IOSAppsFlowsPage iOSApp", iOSApp.id)

  //getting parameter
  const params = useParams();
  const idfromURL = params.appid;
  // console.log("IOSAppsFlowsPage idfromURL", idfromURL)

  document.title = "Admin | " + iOSApp.name + " iOS";

  //added to ensure that page scrolls to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);

    if (iOSApp.id !== idfromURL) {
      //console.log("IOSAppsFlowsPage not the same app")
      dispatch(fetchiOSAppById(idfromURL));
    }
  }, [idfromURL]);

  return (
    //asdasdasd
    <div>
      <SearchBarHeader />
      <div className="ui top-section">
        <Link to="/browse/ios/apps">
          <button
            className="ui button circular "
          //onClick={handleBackButton}
          >
            <i className="angle left icon"></i>
            Back
          </button>
        </Link>

        {Object.keys(iOSApp).length === 0 ? (
          <h1 className="ui header">
            <InlineLoader />
          </h1>
        ) : (
          <>
            <h1 className="ui header">
              <img className="ui large rounded image" src={iOSApp.logo_url} />
              <div className="content">
                {iOSApp.name}

              {
                hasAnyScope(employee.scope,[SCOPES.ADMIN]) &&
                <Link to={"/apps/" + idfromURL + "/edit"}>
                  <button className="ui right floated button">Edit</button>
                </Link>
              }

              </div>
            </h1>

            {iOSApp.categories.length > 0
              ? iOSApp.categories.map((category) => (
                <div
                  key={category.id}
                 // className="ui black mini circular basic button"
                 className="ui label tiny"
                  style={{ pointerEvents: "none" }}
                >
                  {category.name}
                </div>
              ))
              : null}
          </>
        )}
      </div>
      <IOSProductInsightHorizontalSection  />
      <ScreenAndFlowsTile />
    </div>
  );
};

export default IOSAppsFlowsPage;
