  // HeaderSearchBar.js
import React from 'react';
import { useSelector, useDispatch } from 'react-redux'
  import {useState} from 'react';
import HeaderSearchSuggestionList from './HeaderSearchSuggestionList'
import {
  changeSearchTerm, 
  useSearchSuggestionsQuery,
  fetchSearchSuggestions, // search thunk
  fetchGreetingFromGraphQL //// search thunk,
  } from '../../store'


//const Suggestions = [1,2,3,4,5,6,76,8,9,90,21];


const HeaderSearchBar = () => {
  //to keep track of local state of suggestion list
   const [isSuggestionListOpen, setSuggestionListOpen] = useState(false);
  const dispatch = useDispatch();

  
   const searchTerm = useSelector((state) => {
     // console.log("HeaderSearchBar", state.search.searchSuggestions)
      return state.search.searchTerm
   })


  const searchSuggestions = useSelector((state) => {
      //console.log(state)
      return state.search.searchSuggestions
   })
  //console.log(searchSuggestions)

   
  const handleSearchTermChange = (event) => { 
    const newTerm = event.target.value;
    
      setSuggestionListOpen(true);  
      dispatch(changeSearchTerm(newTerm))
      //dispatch(fetchGreetingFromGraphQL(newTerm))
      dispatch(fetchSearchSuggestions(newTerm))
   // console.log("searchTerm length", newTerm.length); 
    };

    const handleBlur = (event) => {
    //console.log("blur")
    setSuggestionListOpen(false);
    //console.log(isSuggestionListOpen)
  };

  const handleOnClick = ()=> {
      console.log("handleonClick")
  }
    // const { data, error, isLoading } = useSearchSuggestionsQuery('')
    // console.log(error, data, isLoading)

  return (
  <div>     
    <div id="search" className="ui search search-bar-container">
         <div className="ui icon input">
           <input className=" prompt search-bar" 
           type="text" 
           style = {{color: "black"}} 
           placeholder="Search Apps..."  
           value={searchTerm}
           //value={event.target.value}
           onChange={handleSearchTermChange}
           onBlur={handleBlur}
           />
              <i className="search icon"></i>
          
        </div>
       
         {isSuggestionListOpen  && searchSuggestions.length > 0 ? <HeaderSearchSuggestionList suggestions = {searchSuggestions}/>: null}
   
      </div> 
   </div>  
  );
};


export default HeaderSearchBar








