// Employees.js
import React from 'react';
import { Link } from 'react-router-dom';



const Employees = () => {

  const handleClick = () => {
    console.log("handleClick")
  }

  return (
   <div className="ui segment">
   <table class="ui very basic table">

  
  <thead >
  <tr> 

      <th>
      <div class="ui search">
        <input class="prompt" type="text" placeholder="Search..."/>
      </div>
      </th> 
      <th colspan="6">
      
          <Link to="new">
            <div className="ui right floated small primary labeled icon button">
                    <i className="user icon"></i> Add Employee
                  </div>
           </Link>         
        </th>
         


  </tr>
    <tr>
      <th>Name</th>
      <th>Status</th>
      <th>Notes</th>
      <th>Action</th>

    </tr>
  </thead>
  <tbody>
    <tr>
      <td>John</td>
      <td>Approved</td>
      <td>None</td>
      <td>
        <button class="ui icon circular button" onClick={handleClick}>
        <i class="angle right icon"></i>
        </button>
      </td>
    </tr>
    <tr>
      <td>Jamie</td>
      <td>Approved</td>
      <td>Requires call</td>
      <td>
        <button class="ui icon circular button" onClick={handleClick}>
        <i class="angle right icon"></i>
        </button>
      </td>
    </tr>
    <tr>
      <td>Jill</td>
      <td>Denied</td>
      <td>None</td>
      <td>
        <button class="ui icon circular button" onClick={handleClick}>
        <i class="angle right icon"></i>
        </button>
      </td>
    </tr>
  </tbody>
  
</table>
  </div> 
  );
};


export default Employees





