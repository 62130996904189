//selectedAppSlice.js

import { createSlice } from '@reduxjs/toolkit'
import { fetchiOSAppById, 
         fetchProductInsightForiOSApp, 
         updateiOSAppByID, 
         updateSearchTermsByAppId,
         updateAppCategoriesiOSAppByID,
         updateAppCompetitorsiOSAppByID,
         updateAppMonetizationsiOSAppByID
      } from '../thunks/iOSAppsThunk'

import { fetchIOSUserFlowById } from '../thunks/userFlowThunk'

import { fetchWebAppById, 
         fetchProductInsightForWebApp, 
         updateWebAppById, 
         updateProductInsightByAppId, 
         fetchAllDetailsOfAppToEditById,
         updateAppCategoriesWebAppById
      } from '../thunks/webAppsThunk'

import { fetchUserFlowById } from '../thunks/userFlowThunk'

const selectedAppSlice = createSlice({
	name:"app",
	initialState: {
		app:{},
		productInsight: {},
      isLoading: false,
		isUpdating: false,
		
	},
	reducers: {
		selectedApp: (state, action)=> {
			state.app = action.payload
		}
	},
	extraReducers(builder){
      builder.addCase(fetchiOSAppById.pending, (state) => {
               state.isLoading = true;
      });
		builder.addCase(fetchiOSAppById.fulfilled, (state, action) => {
               //console.log(action.payload.data.appById)
               state.app = action.payload.data.appById //updating the selectedApp
               state.isLoading = false;
       
      });
      builder.addCase(fetchIOSUserFlowById.pending, (state) => {
               state.isLoading = true;
      });
       builder.addCase(fetchIOSUserFlowById.fulfilled, (state, action) => {
               // console.log("selectedAppSlice  fetchIOSUserFlowById", action.payload.data)
               state.app = action.payload.data.userFlowId.app //updating the selectedApp
               state.isLoading = false;
       });
      builder.addCase(fetchWebAppById.pending, (state) => {
               state.isLoading = true;
      });
      builder.addCase(fetchWebAppById.fulfilled, (state, action) => {
         //console.log(action.payload.data.appById)
         state.app = action.payload.data.appById //updating the selectedApp
         state.isLoading = false;
      });
      builder.addCase(fetchUserFlowById.pending, (state) => {
               state.isLoading = true;
      });
      builder.addCase(fetchUserFlowById.fulfilled, (state, action) => {
               // console.log(" selectedAppSlice fetchUserFlowById", action.payload.data)
               state.app = action.payload.data.userFlowId.app //updating the selectedApp
               state.isLoading = false;
      });
      builder.addCase(fetchProductInsightForiOSApp.pending, (state) => {
               state.isLoading = true;
      });
    builder.addCase(fetchProductInsightForiOSApp.fulfilled, (state, action) => {
               state.productInsight = action.payload.data.productInsightAppById //updating the selectedApp
               state.isLoading = false;
    });
      builder.addCase(fetchProductInsightForWebApp.pending, (state) => {
               state.isLoading = true;
      });
    builder.addCase(fetchProductInsightForWebApp.fulfilled, (state, action) => {
               state.productInsight = action.payload.data.productInsightAppById //updating the selectedApp
               state.isLoading = false;
    });
    builder.addCase(fetchAllDetailsOfAppToEditById.pending, (state) => {
               state.isLoading = true;
      });
    builder.addCase(fetchAllDetailsOfAppToEditById.fulfilled, (state, action) => {
      // console.log("selectedAppSlice fetchAllDetailsOfAppToEditById", action.payload.data.appById)
       const app = action.payload.data.appById
       
       	// Make a shallow copy of the app object
			const appWithoutProductInsight = { ...app };

			// Delete the productInsight property from the copy
			delete appWithoutProductInsight.productInsight

			state.app = appWithoutProductInsight
       	state.productInsight = app.productInsight
         state.isLoading = false;
    });
    builder.addCase(updateWebAppById.fulfilled, (state, action) => {
    	 //console.log(" selectedAppSlice updateWebAppById", action.payload.data.updateWebAppByID)
    	  const response = action.payload.data.updateWebAppByID

    	  // Use Object.entries and destructure the first entry
			const [[fieldName, fieldValue]] = Object.entries(response);
    	  //console.log(" selectedAppSlice fieldName", fieldName)
    	  //console.log(" selectedAppSlice value", fieldValue)
       state.app[fieldName] = fieldValue;
       state.isUpdating = false
    });
    builder.addCase(updateWebAppById.pending, (state) => {
              state.isUpdating = true;
    });
    builder.addCase(updateiOSAppByID.fulfilled, (state, action) => {
    	 //console.log(" selectedAppSlice updateiOSAppByID", action.payload.data.updateiOSAppByID)
    	  const response = action.payload.data.updateiOSAppByID

    	  // Use Object.entries and destructure the first entry
			const [[fieldName, fieldValue]] = Object.entries(response);
    	  //console.log(" selectedAppSlice fieldName", fieldName)
    	  //console.log(" selectedAppSlice value", fieldValue)
       state.app[fieldName] = fieldValue;
       state.isUpdating = false
    });
    builder.addCase(updateiOSAppByID.pending, (state) => {
              state.isUpdating = true
    });
    builder.addCase(updateProductInsightByAppId.fulfilled, (state, action) => {
    	 //console.log(" selectedAppSlice updateProductInsightByAppId", action.payload.data.updateProductInsightByAppId)
    	  const response = action.payload.data.updateProductInsightByAppId

    	  // Use Object.entries and destructure the first entry
			const [[fieldName, fieldValue]] = Object.entries(response);
    	  //console.log(" selectedAppSlice fieldName", fieldName)
    	  //console.log(" selectedAppSlice value", fieldValue)
       state.productInsight[fieldName] = fieldValue;
       state.isUpdating = false
    });
    builder.addCase(updateProductInsightByAppId.pending, (state) => {
              state.isUpdating = true;
    });
    builder.addCase(updateSearchTermsByAppId.pending, (state) => {
              state.isUpdating = true;
    });
    builder.addCase(updateSearchTermsByAppId.fulfilled, (state, action) => {
            //console.log(" updateSearchTermsByAppId", action.payload.data.updateSearchTermsByAppId)
            const { __typename, ...updatedSearchTerms } = action.payload.data.updateSearchTermsByAppId
            state.app = {
               ...state.app,
               ...updatedSearchTerms
            }
            state.isUpdating = false;
    });
    builder.addCase(updateAppCategoriesiOSAppByID.pending, (state) => {
              state.isUpdating = true;
    });
    builder.addCase(updateAppCategoriesiOSAppByID.fulfilled, (state, action) => {
            // console.log("updateAppCategoriesiOSAppByID", action.payload.data.updateiOSAppByID)
            const { id, categories } = action.payload.data.updateiOSAppByID

            if (state.app.id === id){
              // Merge the new categories with the existing ones in the app state
               state.app.categories = categories;
            }
              
            state.isUpdating = false;
    });
    builder.addCase(updateAppCompetitorsiOSAppByID.pending, (state) => {
              state.isUpdating = true;
    });
    builder.addCase(updateAppCompetitorsiOSAppByID.fulfilled, (state, action) => {
            // console.log("updateAppCompetitorsiOSAppByID", action.payload.data.updateiOSAppByID)
            const { id, competitors } = action.payload.data.updateiOSAppByID

            if (state.app.id === id){
              // Merge the new categories with the existing ones in the app state
               state.app.competitors = competitors;
            }
              
            state.isUpdating = false;
    });
   builder.addCase(updateAppMonetizationsiOSAppByID.pending, (state) => {
               state.isUpdating = true;
   });
   builder.addCase(updateAppMonetizationsiOSAppByID.fulfilled, (state, action) => {
            // console.log("updateAppMonetizationsiOSAppByID", action.payload.data.updateiOSAppByID)
            const { id, monetizations } = action.payload.data.updateiOSAppByID

            if (state.app.id === id){
              // Merge the new categories with the existing ones in the app state
               state.app.monetizations = monetizations;
            }
              
            state.isUpdating = false;
      });

	}
})


export const { selectedApp } = selectedAppSlice.actions;
export const selectedAppReducer = selectedAppSlice.reducer;