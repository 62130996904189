// PublishStatusEditor.js
import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateiOSAppByID } from '../../../store'


const PublishStatusEditor = ({value, appType, appId}) => {

  const [status, setStatus] = useState(value);
  const [isEditing, setIsEditing] = useState(false);
  const [originalData, setOriginalData] = useState(null);
  const dispatch = useDispatch();

   useEffect(() => {
      // Update local state to match external value when entering edit mode
    setStatus(value);
      setOriginalData(value); // Save the original data for comparison
  }, [value]);

  const handleSave = (e) => {
    e.preventDefault()

    const iOsApp = {
      publishStatus: status
    };
    dispatch(updateiOSAppByID({ appId, iOsApp }))
    setIsEditing(false); // Exit editing mode
  };

  const handleCancel = () => {
    setStatus(value); // Reset the value to the original
    setIsEditing(false); // Exit editing mode
  };

  // Function to check if there are any changes
  const hasChanges = () => {
    return (
      status !== originalData
    );
  };

   const handleChange = (e) => {
    setStatus(e.target.value);
  };



if (isEditing) {
    return (
      <div className="ui form">
        <div className="field">
          <h3>
            Publish Status
            <button className="ui right floated button" onClick={handleCancel}>Cancel</button>
            <button disabled={!hasChanges()}  className="ui right floated button" onClick={handleSave}>Save</button>
          </h3>
          <div className="inline fields">
                <div className="field">
                  <div className="ui radio checkbox">
                    <input type="radio" value="published" checked={status === 'published'} onChange={handleChange} />
                    <label>published</label>
                  </div>
                 </div>
                 {/* <div className="field"> 
                    <div className="ui radio checkbox">
                      <input type="radio" value="unpublished" checked={status === 'unpublished'} onChange={handleChange} />
                      <label>unpublished</label>
                    </div>
                 </div> */}
                 <div className="field"> 
                    <div className="ui radio checkbox">
                      <input type="radio" value="draft" checked={status === 'draft'} onChange={handleChange} />
                    <label>draft</label>
                  </div>  
          </div>   
           </div>   
        </div>
      </div>
    );
  }


  return (
   <div>
          <h3>
            Publish Status 
           <button className="ui right floated button" onClick={() => setIsEditing(true)}>Edit</button>
          </h3>
          <p>
            {value}
          </p>
  </div>
  );
};


export default PublishStatusEditor







