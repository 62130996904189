// AdminPage.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import SearchBarHeader from '../SearchBarHeader/SearchBarHeader'
import TopMenuForAdmin from './TopMenuForAdmin'
import PasswordReset from './PasswordReset'
import Employees from './Employees/Employees'



const AdminPage = () => {
  return (
  <div >     
    <SearchBarHeader displaySearchBar = {false}/>
        <div className="ui top-section">
            <div className="ui text ">
              <h1 className="ui header">
                Admin
              </h1> 
        </div>  
        <TopMenuForAdmin/>
        <Routes>
        <Route path="password" element={<PasswordReset />} />
        <Route path="employees" element={<Employees />} />
        <Route path="employees/new" element={<PasswordReset />} />
      
        {/* Define more routes as necessary */}
      </Routes>
         </div>   

</div>  
  );
};


export default AdminPage





