
import { formatAppName } from './formatAppName';

// Define the function that formats the flow name
export const formatFlowName = (selectedFlowName, appName, appType)  => {
    //console.log("formatFlowName")
    let flowName;
    // Format appName
    const formattedAppName = formatAppName(appName);

    // Check if the name includes a "/", and split if it does
    if (selectedFlowName.includes("/")) {
        const parts = selectedFlowName.split("/");
        flowName = parts[1]; // Assume the desired part is after the first "/"
    } else {
        flowName = selectedFlowName;
    }

    // Perform the string transformations
    const lowerCaseAttribute = flowName.trim().toLowerCase(); // Convert to lowercase
    const attribute = lowerCaseAttribute.replace(/^'+|'+$/g, ''); // Trim outer single quotes
    const noQuotes = attribute.replace(/'/g, ''); // Remove all single quotes
    const snakeCaseAttribute = noQuotes.replace(/\s+/g, '_'); // Replace spaces with underscores


    

     // Prepend appName and appType with underscores to the snakeCaseAttribute
    const formattedName = `${formattedAppName.toLowerCase()}_${appType.toLowerCase()}_${snakeCaseAttribute}`;

    return formattedName;
};

