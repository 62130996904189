import { createAsyncThunk } from '@reduxjs/toolkit';
const KEYS = require('../../config/keys')

const updateIOSScreen = createAsyncThunk('updateIOSScreen/update', async({ screenId, screen }) => {
  //console.log("updateIOSScreen thunk")
  //console.log(screenId)
  //console.log(screen)
  

  return fetch(KEYS.GRAPHQL_URL, {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
          variables: {
          screen,
          screenId
        },
          query: `
          mutation UpdateScreen($screenId: ID!, $screen: updateScreenInput!) {
            updateScreen(screenId: $screenId, screen: $screen) {
              name
              description
              id
              position
              event_types {
                id
                name
              }
              image {
                id
                image_url
              }
          }
        }
        `
      })
  }).then(res => res.json())
})


const updateWebScreen = createAsyncThunk('updateWebScreen/update', async({ screenId, screen }) => {
  //console.log("updateWebScreen thunk")
  //console.log(screenId)
  //console.log(screen)
  

  return fetch(KEYS.GRAPHQL_URL, {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
          variables: {
          screen,
          screenId
        },
          query: `
          mutation UpdateScreen($screenId: ID!, $screen: updateScreenInput!) {
            updateScreen(screenId: $screenId, screen: $screen) {
              name
              description
              id
              position
              event_types {
                id
                name
              }
              image {
                id
                image_url
              }
          }
        }
        `
      })
  }).then(res => res.json())
})



export {updateIOSScreen, updateWebScreen}
