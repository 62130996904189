//iOSAppsSlice.js

import { createSlice } from '@reduxjs/toolkit'
import { fetchInitialiOSApps, fetchiOSAppById, fetchProductInsightForiOSApp, fetchIOSCompetitorsByAppId } from '../thunks/iOSAppsThunk'
import { fetchIOSUserFlowById } from '../thunks/userFlowThunk'

const iOSAppsSlice = createSlice({
	name:"iosapps",
	initialState: {
		initialiOSApps:[],
		isLoading: false,
		hasMore: false,
		limit: 10,
		selectediOSApp: {}, 
		productInsight: {}
	},
	reducers: {
		selectediOSApp: (state, action)=> {
			state.selectediOSApp = action.payload
		}
	},
	extraReducers(builder){
		builder.addCase(fetchInitialiOSApps.pending, (state, action) => {
		  state.isLoading = true
		});				
		builder.addCase(fetchInitialiOSApps.fulfilled, (state, action) => {
       	//console.log(action.payload.data.iosapps)
      	state.initialiOSApps = action.payload.data.iosapps //updating the webapps
			state.isLoading = false	
    	});
		builder.addCase(fetchiOSAppById.pending, (state, action) => {
		  state.isLoading = true
		});		
		builder.addCase(fetchiOSAppById.fulfilled, (state, action) => {
        //console.log(action.payload.data.appById)
       	state.selectediOSApp = action.payload.data.appById //updating the selectediOSApp
       	//state.productInsight = action.payload.data.appById.productInsight // setting productInsight
		   state.productInsight = {
			...state.productInsight,
			...action.payload.data.appById.productInsight
		  }
		state.isLoading = false	
   		 });
		 builder.addCase(fetchIOSUserFlowById.pending, (state, action) => {
		  state.isLoading = true
		});		
		builder.addCase(fetchIOSUserFlowById.fulfilled, (state, action) => {
		// console.log("iOSAppsSlice  fetchIOSUserFlowById", action.payload.data)
		state.selectediOSApp = action.payload.data.userFlowId.app //updating the selectedWebApp
		state.isLoading = false	
		});
		builder.addCase(fetchProductInsightForiOSApp.pending, (state, action) => {
		  state.isLoading = true
		});
		builder.addCase(fetchProductInsightForiOSApp.fulfilled, (state, action) => {
		// console.log("iOSAppsSlice  fetchProductInsightForiOSApp", action.payload.data)
		//state.productInsight = action.payload.data.productInsightAppById//updating the productInsight
		state.productInsight = {
			...state.productInsight,
			...action.payload.data.productInsightAppById
		  }
		state.isLoading = false
		});	
		builder.addCase(fetchIOSCompetitorsByAppId.fulfilled, (state, action) => {
		  //console.log("fetchIOSCompetitorsByAppId", action.payload.data.appById)
		const {competitors} = action.payload.data.appById
		state.productInsight = {
			...state.productInsight,
			competitors
		}
		});
	}
})


export const { selectediOSApp } = iOSAppsSlice.actions;
export const iOSappsReducer = iOSAppsSlice.reducer;