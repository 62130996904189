
import React from 'react';

const ValueTextComponent = ({ data }) => {
    //console.log("ValueTextComponent prod", data)
  return (
    <div >
      <p dangerouslySetInnerHTML={{ __html: data }} />
    </div>
  );
};

export default ValueTextComponent;