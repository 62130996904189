// ScreensGridWebApp.js
import React from 'react';





const ScreensGridWebApp = () => {
  return (

  <div className="screens-flows-container">     
  <h3 className="ui header">144 screens</h3>
   </div>  
  );
};


export default ScreensGridWebApp




