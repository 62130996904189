  // UISearchBar.js
import React from 'react';
import { useSelector, useDispatch } from 'react-redux'
  import {useState} from 'react';
import UISearchSuggestionList from './UISearchSuggestionList'
import {searchUserInteractions } from '../../../store'



const UISearchBar = ({handleSuggestionClicked, currentEventTypes, appType}) => {
  //to keep track of local state of suggestion list
   const [isSuggestionListOpen, setSuggestionListOpen] = useState(false);
   const [searchSuggestions, setSearchSuggestions] = useState([])
   const [searchTerm, setSearchTerm] = useState("")
  const dispatch = useDispatch();


   
  const handleSearchTermChange = async (event) => { 
    const newTerm = event.target.value;
      setSuggestionListOpen(true);  
      setSearchTerm(newTerm)

      let platform;

      if (appType === 'iOS') {
        platform = 'mobile';
      } else if (appType === 'Web') {
        platform = 'web';
      }

       // Dispatch the thunk and get the result
      const result = await dispatch(searchUserInteractions({ searchTerm: newTerm, platform }));
      //console.log(result.payload.data.searchUserInteractions)
      
      //From PROD API
        // if (result && result.payload) {
        //     // Extract IDs of categories already present in currentEventTypes
        //     const currentCategoryIds = new Set(currentEventTypes.map(cat => cat.id));

        //     // Filter out categories that are already in currentEventTypes
        //     const filteredSuggestions = result.payload.data.searchUserInteractions.filter(cat => !currentCategoryIds.has(cat.id));
            
        //     // Set the search suggestions
        //     setSearchSuggestions(filteredSuggestions);
        // }

      //From local
      //console.log(result.payload)
      if (result && result.payload) {
            // Extract IDs of categories already present in currentEventTypes
            //const currentCategoryIds = new Set(currentEventTypes.map(cat => cat.id));

            // Filter out categories that are already in currentEventTypes
            //const filteredSuggestions = result.payload.filter(cat => !currentCategoryIds.has(cat.id));
            
            //no need to filter as on a screen there can be multiple events of same type
            const nonFilteredResult = result.payload
            // Set the search suggestions
            //setSearchSuggestions(filteredSuggestions);
            setSearchSuggestions(nonFilteredResult);
        }
    };

    const handleBlur = (event) => {
    //console.log("blur")
    setSuggestionListOpen(false);
    //console.log(isSuggestionListOpen)
  };

  const handleOnClick = ()=> {
      console.log("handleonClick")
  }

  //console.log(appType)

  return (
  <div>     
    <div id="search" className="ui search search-bar-container">
         <div className="ui icon input">
           <input className=" prompt search-bar" 
           type="text" 
           style = {{color: "black"}} 
           placeholder={"Search " + appType + " events..."}
           value={searchTerm}
           //value={event.target.value}
           onChange={handleSearchTermChange}
           onBlur={handleBlur}
           />
              <i className="search icon"></i>
          
        </div>
       
         {isSuggestionListOpen  && searchSuggestions.length > 0 ? <UISearchSuggestionList handleSuggestionClicked={handleSuggestionClicked} setSearchTerm={setSearchTerm} suggestions = {searchSuggestions}/>: null}
   
      </div> 
   </div>  
  );
};


export default UISearchBar








